import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "../base/ApplicationContext";
import { Text } from "react-native";
import tailwind from "twrnc";
import { StyledPicker } from "../base/StyledPicker";
import {
  DIFFICULTY_ADVANCED,
  DIFFICULTY_ALL,
  DIFFICULTY_BEGINNER,
  DIFFICULTY_INTERMEDIATE,
} from "../base/Constants";

const Difficulty = ({ difficulty, setDifficulty }) => {
  const { theme } = useContext(ThemeContext);
  return (
    <>
      <Text style={tailwind`text-[${theme.textColor}] font-semibold mb-1`}>
        Difficulty
      </Text>
      <StyledPicker
        selectedValue={difficulty}
        onValueChange={(itemValue) => setDifficulty(itemValue)}
        items={[
          {
            label: "All",
            value: DIFFICULTY_ALL,
          },
          {
            label: "Beginner",
            value: DIFFICULTY_BEGINNER,
          },
          {
            label: "Intermediate",
            value: DIFFICULTY_INTERMEDIATE,
          },
          {
            label: "Advanced",
            value: DIFFICULTY_ADVANCED,
          },
        ]}
      />
    </>
  );
};

Difficulty.propTypes = {
  difficulty: PropTypes.number.isRequired,
  setDifficulty: PropTypes.func.isRequired,
};

export { Difficulty as default };
