import React, { useContext } from "react";
import PropTypes from "prop-types";
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";
import { ActivityIndicator, Text } from "react-native";
import tailwind from "twrnc";
import { API_URL } from "../base/Constants";
import { StyledPicker } from "../base/StyledPicker";
import axios from "axios";
import { CurrentUserContext, ThemeContext } from "../base/ApplicationContext";

const State = ({ state, setState }) => {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <StatePanel state={state} setState={setState} />
    </QueryClientProvider>
  );
};

const StatePanel = ({ state, setState }) => {
  const { currentUser } = useContext(CurrentUserContext);
  const { theme } = useContext(ThemeContext);

  const { isLoading, error, data } = useQuery(["states"], () =>
    axios
      .get(API_URL + "states", {
        staleTime: 0,
        headers: { authorization: currentUser.token },
      })
      .then((res) => {
        return res.data;
      })
  );

  if (isLoading) return <ActivityIndicator />;
  if (error) return <Text>An error has occurred: ${error.message}</Text>;

  return (
    <>
      <Text style={tailwind`text-[${theme.textColor}] font-semibold mb-1`}>
        State
      </Text>
      <StyledPicker
        items={data.map((stateItem) => {
          return {
            label: stateItem.name,
            value: stateItem.id,
          };
        })}
        selectedValue={state}
        onValueChange={(itemValue) => {
          setState(Number(itemValue));
        }}
      />
    </>
  );
};

State.propTypes = {
  state: PropTypes.number,
  setState: PropTypes.func.isRequired,
};

StatePanel.propTypes = {
  state: PropTypes.number,
  setState: PropTypes.func.isRequired,
};

export { State as default };
