import React from "react";
import PropTypes from "prop-types";
import { View } from "react-native";
import tailwind from "twrnc";
import { StyledInput } from "../base/StyledInput";
import {
  analyzeConfirmPassword,
  analyzeEmailAddress,
  analyzeFirstName,
  analyzeLastName,
  analyzePassword,
} from "../base/Validation";

const PersonalDetailsForm = ({
  setEmailAddress,
  emailAddressStatus,
  password,
  setPassword,
  setConfirmPassword,
  passwordStatus,
  confirmPasswordStatus,
  setFirstName,
  firstNameStatus,
  setLastName,
  lastNameStatus,
}) => {
  return (
    <>
      <View style={tailwind`mb-6 px-2 self-center w-full`}>
        <View style={tailwind`flex-row flex-wrap`}>
          <View style={tailwind`mb-2`}>
            <StyledInput
              label={"First Name"}
              placeholder={"Enter your first name"}
              autoFocus={true}
              onChangeText={(text) => {
                setFirstName(text);
                analyzeFirstName(text, firstNameStatus);
              }}
              fieldStatus={firstNameStatus}
            />
          </View>
          <View style={tailwind`mb-2`}>
            <StyledInput
              label={"Last Name"}
              placeholder={"Enter your last name"}
              onChangeText={(text) => {
                setLastName(text);
                analyzeLastName(text, lastNameStatus);
              }}
              fieldStatus={lastNameStatus}
            />
          </View>
        </View>
        <View style={tailwind`mb-2`}>
          <StyledInput
            label="Email Address"
            placeholder={"someone@somewhere.net"}
            autoComplete="email"
            keyboardType="email"
            onChangeText={(text) => {
              setEmailAddress(text);
              analyzeEmailAddress(text, emailAddressStatus);
            }}
            fieldStatus={emailAddressStatus}
            width={"250px"}
          />
        </View>
        <View style={tailwind`flex-row flex-wrap`}>
          <View style={tailwind`mb-2`}>
            <StyledInput
              label={"Password"}
              placeholder="password"
              keyboardType="text"
              autoComplete="password"
              secure={true}
              onChangeText={(text) => {
                setPassword(text);
                analyzePassword(text, passwordStatus);
              }}
              fieldStatus={passwordStatus}
            />
          </View>
          <View style={tailwind`mb-2`}>
            <StyledInput
              label={"Confirm Password"}
              placeholder="confirm password"
              keyboardType="text"
              autoComplete="password"
              secure={true}
              onChangeText={(text) => {
                setConfirmPassword(text);
                analyzeConfirmPassword(text, password, confirmPasswordStatus);
              }}
              fieldStatus={confirmPasswordStatus}
            />
          </View>
        </View>
      </View>
    </>
  );
};

PersonalDetailsForm.propTypes = {
  setEmailAddress: PropTypes.func.isRequired,
  emailAddressStatus: PropTypes.object.isRequired,
  password: PropTypes.string.isRequired,
  setPassword: PropTypes.func.isRequired,
  setConfirmPassword: PropTypes.func.isRequired,
  passwordStatus: PropTypes.object.isRequired,
  confirmPasswordStatus: PropTypes.object.isRequired,
  setFirstName: PropTypes.func.isRequired,
  firstNameStatus: PropTypes.object.isRequired,
  setLastName: PropTypes.func.isRequired,
  lastNameStatus: PropTypes.object.isRequired,
};

export default PersonalDetailsForm;
