import React, { useContext } from "react";
import PropTypes from "prop-types";
import {
  useFonts,
  Roboto_700Bold,
  Roboto_400Regular,
} from "@expo-google-fonts/roboto";
import { Inter_400Regular } from "@expo-google-fonts/inter";
import { Platform, SafeAreaView, ScrollView, Text, View } from "react-native";
import tailwind from "twrnc";
import {
  DIFFICULTY_ADVANCED,
  DIFFICULTY_ALL,
  DIFFICULTY_BEGINNER,
  DIFFICULTY_INTERMEDIATE,
  EVENT_TYPE_BOOKABLE,
  EVENT_TYPE_BROADCAST,
  EVENT_TYPE_PRIVATE,
} from "../base/Constants";
import Button from "../base/Button";
import dayjs from "dayjs";
import EventAttendee from "./EventAttendee";
import EditEventScreen from "../../screens/EditEventScreen";
import { QueryClient } from "@tanstack/react-query";
import { useNavigation } from "@react-navigation/native";
import {
  BottomSheetContext,
  CurrentUserContext,
  NavigationSyncContext,
  ThemeContext,
} from "../base/ApplicationContext";
import Tag from "../base/Tag";
import MiniTrainerProfilePanel from "../profile-screen/MiniTrainerProfilePanel";

const ViewEvent = ({ event, queryClient }) => {
  const { currentUser } = useContext(CurrentUserContext);
  const { theme } = useContext(ThemeContext);
  const { setBottomSheetContent, setBottomSheetVisible } =
    useContext(BottomSheetContext);
  const { setFocusedScreen } = useContext(NavigationSyncContext);
  const startDate = event.startDate;
  const time = dayjs(event.startDate).format("HH:mm");
  const typeId = event.type.id;
  const isPublic = event.isPublic;
  const duration = event.duration;
  const shortDescription = event.shortDescription;
  const longDescription = event.longDescription;
  const difficultyId = event.difficulty.id;
  const difficultyName = event.difficulty.name;
  const participants = event.participants;
  const hostId = event.userId;
  const tags = event.scheduleTags;
  const navigation = useNavigation();

  let difficultyStyle;
  switch (difficultyId) {
    case DIFFICULTY_ADVANCED:
      difficultyStyle = tailwind`bg-[${theme.difficultyAdvancedColor}] rounded-full p-[1px] px-4 ml-2`;
      break;
    case DIFFICULTY_INTERMEDIATE:
      difficultyStyle = tailwind`bg-[${theme.difficultyIntermediateColor}] rounded-full p-[1px] px-4 ml-2`;
      break;
    case DIFFICULTY_BEGINNER:
      difficultyStyle = tailwind`bg-[${theme.difficultyBeginnerColor}] rounded-full p-[1px] px-4 ml-2`;
      break;
    case DIFFICULTY_ALL:
    default:
      difficultyStyle = tailwind`border-l-[20px] border-r-[20px] border-l-[${theme.difficultyBeginnerColor}] border-r-[${theme.difficultyAdvancedColor}] bg-[${theme.difficultyIntermediateColor}] rounded-full p-[1px] px-2 ml-2`;
      break;
  }

  let typeName, headerColour;
  switch (typeId) {
    case EVENT_TYPE_BOOKABLE:
      typeName = "Open Personal Training";
      headerColour = theme.calendarEventBookableColor;
      break;
    case EVENT_TYPE_PRIVATE:
      typeName = "Personal Training";
      headerColour = theme.calendarEventPrivateColor;
      break;
    case EVENT_TYPE_BROADCAST:
      typeName = "Live Stream";
      headerColour = theme.calendarEventBroadcastColor;
      break;
    default:
      typeName = "";
      headerColour = theme.calendarEventOtherColor;
      break;
  }

  let eventParticipants = [];
  participants.forEach((participant) => {
    const newParticipant = {
      id: participant.user.id,
      profileUrl: participant.user.profileUrl,
      name: participant.user.firstName,
    };
    if (currentUser.id !== participant.user.id) {
      eventParticipants.push(newParticipant);
    }
  });

  let [fontsLoaded] = useFonts({
    Roboto_700Bold,
    Roboto_400Regular,
    Inter_400Regular,
  });

  if (!fontsLoaded) {
    return null;
  }

  return (
    <SafeAreaView style={tailwind`bg-[${theme.backgroundColor}]`}>
      <View style={tailwind`pb-10`}>
        <View
          style={tailwind`${
            headerColour &&
            "border-t-[" + headerColour + "] border-t-[4px] p-1 min-h-[15px]"
          }`}
        />
        <View style={tailwind`flex-row m-2`}>
          <View
            style={
              (typeId === EVENT_TYPE_BROADCAST ||
                typeId === EVENT_TYPE_PRIVATE ||
                typeId === EVENT_TYPE_BOOKABLE) &&
              hostId !== currentUser.id
                ? tailwind`w-1/2 pr-4`
                : tailwind`w-full pr-4`
            }
          >
            <Text
              style={[
                tailwind`text-[${theme.viewEventHeadingTextColor}] font-semibold self-center pt-2 text-[20px]`,
                { fontFamily: "Roboto_700Bold" },
              ]}
            >
              {typeName}
            </Text>
            <View style={tailwind`flex-row self-center`}>
              <Text
                style={[
                  tailwind`text-[${theme.viewEventHeadingTextColor}] font-semibold`,
                  { fontFamily: "Roboto_400Regular" },
                ]}
              >
                {dayjs(startDate).format("ddd MMM DD")}
              </Text>
              <Text
                style={[
                  tailwind`text-[${theme.viewEventHeadingTextColor}] mx-2`,
                  { fontFamily: "Roboto_400Regular" },
                ]}
              >
                at
              </Text>
              <Text
                style={[
                  tailwind`text-[${theme.viewEventHeadingTextColor}] font-semibold`,
                  { fontFamily: "Roboto_400Regular" },
                ]}
              >
                {time}
              </Text>
            </View>
            {typeId === EVENT_TYPE_BROADCAST && hostId === currentUser.id ? (
              <View style={tailwind`absolute right-4 top-4`}>
                <Text
                  style={tailwind`font-semibold text-[${
                    isPublic ? theme.greenColor : theme.redColor
                  }]`}
                >
                  {isPublic ? "Published" : "Not Published"}
                </Text>
              </View>
            ) : null}
            <View style={tailwind`flex-row`}>
              <Text
                style={[
                  tailwind`text-[${theme.viewEventHeadingTextColor}] text-[12px] font-semibold ml-1 self-start grow`,
                  { fontFamily: "Roboto_400Regular" },
                ]}
              >
                {duration} MIN
              </Text>
              {typeId === EVENT_TYPE_BROADCAST ? (
                <View style={difficultyStyle}>
                  <Text
                    style={[
                      tailwind`text-[${theme.difficultyTextColour}] font-semibold text-[12px]`,
                      { fontFamily: "Roboto_400Regular" },
                    ]}
                  >
                    {difficultyName.toUpperCase()}
                  </Text>
                </View>
              ) : null}
            </View>
            <View style={tailwind`my-4`}>
              <Text
                style={[
                  tailwind`self-center text-[${theme.textColor}] font-semibold`,
                  { fontFamily: "Roboto_700Bold" },
                ]}
              >
                {shortDescription}
              </Text>
            </View>
            <View style={tailwind`mb-4`}>
              <Text
                style={[
                  tailwind`self-center text-[${theme.textColor}] font-semibold mr-2`,
                  { fontFamily: "Inter_400Regular" },
                ]}
              >
                {longDescription}
              </Text>
            </View>
            {typeId === EVENT_TYPE_PRIVATE ? (
              <View style={tailwind` p-1 mb-2`}>
                <Text
                  style={[
                    tailwind`self-center text-[${theme.textColor}] font-semibold`,
                    { fontFamily: "Roboto_700Bold" },
                  ]}
                >
                  Attending
                </Text>
                <ScrollView style={tailwind`max-h-[200px]`}>
                  <View style={tailwind`flex-row flex-wrap`}>
                    {eventParticipants.map((attendee) => {
                      return (
                        <View key={attendee.id} style={tailwind`m-2`}>
                          <EventAttendee attendee={attendee} />
                          <Text
                            style={[
                              tailwind`self-center text-[${theme.textColor}]`,
                              { fontFamily: "Inter_400Regular" },
                            ]}
                          >
                            {attendee.name}
                          </Text>
                        </View>
                      );
                    })}
                  </View>
                </ScrollView>
              </View>
            ) : null}
            {typeId === EVENT_TYPE_BROADCAST ? (
              <View style={tailwind`flex-row flex-wrap`}>
                {tags.map((tag) => {
                  return (
                    <Tag
                      key={tag.id}
                      label={tag.tag.tagName}
                      colorKey={tag.tag.tagColorKey}
                    />
                  );
                })}
              </View>
            ) : null}
          </View>
          {(typeId === EVENT_TYPE_BROADCAST ||
            typeId === EVENT_TYPE_PRIVATE ||
            typeId === EVENT_TYPE_BOOKABLE) &&
          hostId !== currentUser.id ? (
            <View style={tailwind`mb-2 w-1/2 pl-4`}>
              <MiniTrainerProfilePanel id={hostId} />
            </View>
          ) : null}
        </View>
        <View style={tailwind`self-center flex-row`}>
          {hostId === currentUser.id ? (
            <>
              <Button
                title="Edit"
                onPress={() => {
                  setBottomSheetContent(
                    <EditEventScreen
                      route={{
                        params: { event: event, queryClient: queryClient },
                      }}
                    />
                  );
                }}
              />
              {/* {typeId === EVENT_TYPE_BROADCAST && Platform.OS === "web" ? (
                <Button
                  title="Live Stream"
                  onPress={() => {
                    setBottomSheetVisible(false);
                    setFocusedScreen("Broadcast3Screen");
                    navigation.navigate("Broadcast3Screen", { event: event });
                  }}
                />
              ) : null} */}
            </>
          ) : typeId === EVENT_TYPE_BROADCAST ? (
            <Button title={"Book"} onPress={() => {}} />
          ) : null}
          {typeId === EVENT_TYPE_PRIVATE ? (
            <Button
              title="Join"
              onPress={() => {
                setBottomSheetVisible(false);
                setFocusedScreen("NewChat3");
                navigation.navigate("NewChat3", { event: event });
              }}
            />
          ) : null}
          {typeId === EVENT_TYPE_BOOKABLE && hostId !== currentUser.id ? (
            <Button
              title="Book"
              onPress={() => {
                // setBottomSheetVisible(false);
                // setFocusedScreen("NewChat3");
                // navigation.navigate("NewChat3", { event: event });
              }}
            />
          ) : null}
          <Button
            title="Close"
            onPress={() => {
              setBottomSheetVisible(false);
            }}
          />
        </View>
      </View>
    </SafeAreaView>
  );
};

ViewEvent.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.number.isRequired,
    userId: PropTypes.number.isRequired,
    startDate: PropTypes.string.isRequired,
    type: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    isPublic: PropTypes.bool.isRequired,
    duration: PropTypes.number.isRequired,
    shortDescription: PropTypes.string.isRequired,
    longDescription: PropTypes.string,
    difficulty: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    participants: PropTypes.array,
    location: PropTypes.string,
    tags: PropTypes.arrayOf({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
    rule: PropTypes.shape({
      id: PropTypes.number,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      dayOfMonth: PropTypes.number,
      daysOfWeek: PropTypes.string,
      repeatType: PropTypes.shape({
        id: PropTypes.number,
      }),
    }),
    scheduleTags: PropTypes.array,
  }),
  queryClient: PropTypes.instanceOf(QueryClient),
};

export { ViewEvent as default };
