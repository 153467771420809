import React, { useContext } from "react";
import { View } from "react-native";
import tailwind from "twrnc";
import { ThemeContext } from "../components/base/ApplicationContext";

const LibraryScreen = () => {
  const { theme } = useContext(ThemeContext);
  return (
    <View style={tailwind`flex h-full bg-[${theme.backgroundColor}]`}></View>
  );
};

export default LibraryScreen;
