import React from "react";
import PropTypes from "prop-types";
import { StyledInput } from "../base/StyledInput";

const PostalCode = ({ country, postalCode, setPostalCode }) => {
  console.log(country);
  return (
    <>
      <StyledInput
        label={country === 2 ? "Zip Code" : "Postal Code"}
        onChangeText={(text) => setPostalCode(text)}
        value={postalCode}
        width={"350px"}
      />
    </>
  );
};

PostalCode.propTypes = {
  country: PropTypes.number.isRequired,
  postalCode: PropTypes.string.isRequired,
  setPostalCode: PropTypes.func.isRequired,
};

export { PostalCode as default };
