import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Text, View } from "react-native";
import Sets from "./Sets";
import tailwind from "twrnc";
import Button from "../base/Button";
import HorizontalRule from "../base/HorizontalRule";
import { ThemeContext } from "../base/ApplicationContext";

const OverlayControls = ({
  overlayPlan,
  setOverlayPlan,
  planRunning,
  setPlanRunning,
}) => {
  return (
    <View>
      <HorizontalRule />
      <Sets overlayPlan={overlayPlan} setOverlayPlan={setOverlayPlan} />
      <PlannedTimers
        overlayPlan={overlayPlan}
        setOverlayPlan={setOverlayPlan}
        planRunning={planRunning}
        setPlanRunning={setPlanRunning}
      />
    </View>
  );
};

const PlannedTimers = ({
  overlayPlan,
  setOverlayPlan,
  planRunning,
  setPlanRunning,
}) => {
  const { theme } = useContext(ThemeContext);
  return (
    <View style={tailwind`mt-2 ml-2`}>
      <HorizontalRule />
      <Text style={tailwind`text-[${theme.textColor}] font-bold mb-1`}>
        Planned Timers
      </Text>
      {overlayPlan.map((planItem) => (
        <View key={planItem.key} style={tailwind`flex-row`}>
          <Text style={tailwind`text-[${theme.textColor}] w-[75px]`}>
            {planItem.key + 1}
          </Text>
          <Text style={tailwind`text-[${theme.textColor}] w-[75px]`}>
            {planItem.setType === 1 ? "Work" : "Rest"}
          </Text>
          <Text style={tailwind`text-[${theme.textColor}]`}>
            {planItem.duration} seconds
          </Text>
        </View>
      ))}
      {overlayPlan.length ? (
        <View style={tailwind`mt-2 flex-row`}>
          {!planRunning ? (
            <Button title={"Start"} onPress={() => setPlanRunning(true)} />
          ) : (
            <Button title={"Stop"} onPress={() => setPlanRunning(false)} />
          )}
          {!planRunning ? (
            <Button title={"Clear"} onPress={() => setOverlayPlan([])} />
          ) : null}
        </View>
      ) : null}
    </View>
  );
};

PlannedTimers.propTypes = {
  overlayPlan: PropTypes.array.isRequired,
  setOverlayPlan: PropTypes.func.isRequired,
  planRunning: PropTypes.bool.isRequired,
  setPlanRunning: PropTypes.func.isRequired,
};

OverlayControls.propTypes = {
  overlayPlan: PropTypes.array.isRequired,
  setOverlayPlan: PropTypes.func.isRequired,
  planRunning: PropTypes.bool.isRequired,
  setPlanRunning: PropTypes.func.isRequired,
};

export default OverlayControls;
