import React, { View } from "react-native";
import PropTypes from "prop-types";
import OnDemandDetails from "./OnDemandDetails";
import tailwind from "twrnc";
import ExpoPlayer from "../video-players/vod/ExpoPlayer";
import MiniTrainerProfilePanel from "../profile-screen/MiniTrainerProfilePanel";
import { TrainerVideos } from "../profile-screen/TrainerVideos";
import { WebScrollContainer } from "../home-screen/WebScrollContainer";
import { useContext } from "react";
import { ThemeContext } from "../base/ApplicationContext";

const WebLayout = ({ video }) => {
  const trainerId = video.video.userVideos[0].userId.id;
  const firstName = video.video.userVideos[0].userId.firstName;
  const { theme } = useContext(ThemeContext);

  return (
    <View style={tailwind`flex-col bg-[${theme.backgroundColor}]`}>
      <View style={tailwind`w-full grow`}>
        <View style={tailwind`flex-row self-center h-full w-full mr-4`}>
          <View style={tailwind`w-2/3`}>
            <ExpoPlayer video={video} />
          </View>
          <View style={tailwind`ml-2 w-1/3`}>
            <MiniTrainerProfilePanel id={trainerId} />
            <OnDemandDetails video={video} />
          </View>
        </View>
      </View>
      <View style={tailwind`h-[200px]`}>
        <WebScrollContainer style={tailwind`bg-[${theme.backgroundColor}]`}>
          <TrainerVideos id={trainerId} firstName={firstName} />
        </WebScrollContainer>
      </View>
    </View>
  );
};

WebLayout.propTypes = {
  video: PropTypes.object.isRequired,
};

export default WebLayout;
