import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { View } from "react-native";
import tailwind from "twrnc";
import { CheckBox } from "react-native-elements";
import { ThemeContext } from "../base/ApplicationContext";
import { Roboto_400Regular, useFonts } from "@expo-google-fonts/roboto";

const TagItem = ({ item, processTagSelection, tagSelection }) => {
  const { theme } = useContext(ThemeContext);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    let isChecked = false;
    if (tagSelection && tagSelection.length) {
      isChecked =
        tagSelection.filter((tag) => tag.id === item.descendant.id).length > 0;
    }

    if (isChecked) setChecked(true);
  }, []);

  let fonts = useFonts({ Roboto_400Regular });
  if (!fonts) return;

  return (
    <View>
      <CheckBox
        center
        title={item.descendant.tagName.toUpperCase()}
        checked={checked}
        containerStyle={tailwind`rounded-lg p-1`}
        textStyle={[
          tailwind`text-[${theme.tagSelectorTextColor}] text-[12px]`,
          { fontFamily: "Roboto_400Regular" },
        ]}
        checkedColor={theme.tagSelectorCheckedTextColor}
        onPress={() => {
          setChecked(!checked);
          processTagSelection({
            id: item.descendant.id,
            title: item.descendant.tagName,
            deleted: checked,
          });
        }}
      ></CheckBox>
    </View>
  );
};

TagItem.propTypes = {
  item: PropTypes.object.isRequired,
  processTagSelection: PropTypes.func.isRequired,
  tagSelection: PropTypes.array,
};

export default TagItem;
