import React, { useContext, useRef } from "react";
import PropTypes from "prop-types";
import { Text, View } from "react-native";
import tailwind from "twrnc";
import { API_URL } from "../base/Constants";
import { StyledInput } from "../base/StyledInput";
import { Switch } from "@rneui/themed";
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";
import axios from "axios";
import { ActivityIndicator } from "react-native";
import PersonalTrainingBundles from "./PersonalTrainingBundles";
import { CurrentUserContext, ThemeContext } from "../base/ApplicationContext";
import {
  Roboto_400Regular,
  Roboto_700Bold,
  useFonts,
} from "@expo-google-fonts/roboto";

const PersonalTrainingSettings = ({
  baseCost,
  setBaseCost,
  classDuration,
  setClassDuration,
  currency,
  setCurrency,
  isProviding,
  setIsProviding,
}) => {
  const queryClient = new QueryClient();
  const originalCurrency = useRef(currency);

  let fonts = useFonts({ Roboto_700Bold, Roboto_400Regular });
  if (!fonts) return;

  return (
    <QueryClientProvider client={queryClient}>
      <PersonalTrainingSettingsPanel
        baseCost={baseCost}
        setBaseCost={setBaseCost}
        classDuration={classDuration}
        setClassDuration={setClassDuration}
        currency={currency}
        setCurrency={setCurrency}
        isProviding={isProviding}
        setIsProviding={setIsProviding}
        originalCurrency={originalCurrency}
      />
    </QueryClientProvider>
  );
};

const PersonalTrainingSettingsPanel = ({
  baseCost,
  setBaseCost,
  classDuration,
  setClassDuration,
  currency,
  isProviding,
  setIsProviding,
}) => {
  const { currentUser } = useContext(CurrentUserContext);
  const { theme } = useContext(ThemeContext);
  const toggleSwitch = () => setIsProviding((previousState) => !previousState);
  const hasNoCurrency = useRef(false);
  const { isLoading, error, data } = useQuery(["currencies"], () =>
    axios
      .get(API_URL + "currencies", {
        staleTime: 0,
        headers: { authorization: currentUser.token },
      })
      .then((res) => {
        return res.data;
      })
  );

  if (isLoading) return <ActivityIndicator />;
  if (error) return <Text>An error has occurred: ${error.message}</Text>;

  if (!currency) {
    hasNoCurrency.current = true;
    currency = 0;
  }

  return (
    <View style={tailwind`pb-4 mb-4 self-center min-w-[618px]`}>
      <View style={tailwind`py-3 flex-row w-full mb-4 self-center`}>
        <Text
          style={[
            tailwind`text-[20px] text-[${theme.textColor}] w-1/2`,
            { fontFamily: "Roboto_700Bold" },
          ]}
        >
          Personal Training Charges
        </Text>
        <View style={tailwind`flex-row self-start mr-4 w-1/2`}>
          <Switch
            style={tailwind`mt-1`}
            trackColor={{
              false: theme.pTEnabledSwitchTrackColorFalse,
              true: theme.pTEnabledSwitchTrackColorTrue,
            }}
            ios_backgroundColor={"#3e3e3e"}
            onValueChange={toggleSwitch}
            value={isProviding}
          />
          <Text
            style={[
              tailwind`ml-2 pt-1 font-semibold text-[${
                isProviding ? theme.textColor : theme.redColor
              }]`,
              { fontFamily: "Roboto_400Regular" },
            ]}
          >
            {isProviding
              ? "Connections for PT Enabled "
              : "Connections for PT Disabled"}
          </Text>
        </View>
      </View>
      <View style={tailwind`w-full`}>
        <View style={tailwind`w-full flex-row flex-wrap`}>
          <View style={tailwind`w-1/2`}>
            <StyledInput
              disabled={!isProviding}
              label="Cost for single session"
              onChangeText={(text) => setBaseCost(text)}
              placeholder={"0.00"}
              value={baseCost}
            />
          </View>
          <View>
            <StyledInput
              label={"Duration of session (minutes)"}
              onChangeText={(text) => setClassDuration(text)}
              value={classDuration}
              placeholder={"30"}
            />
          </View>
        </View>
        <PersonalTrainingBundles
          singleSessionCost={baseCost}
          currency={currency}
          currencies={data}
        />
      </View>
    </View>
  );
};

PersonalTrainingSettings.propTypes = {
  baseCost: PropTypes.string,
  setBaseCost: PropTypes.func.isRequired,
  classDuration: PropTypes.string.isRequired,
  setClassDuration: PropTypes.func.isRequired,
  currency: PropTypes.number.isRequired,
  setCurrency: PropTypes.func.isRequired,
  isProviding: PropTypes.bool.isRequired,
  setIsProviding: PropTypes.func.isRequired,
};

PersonalTrainingSettingsPanel.propTypes = {
  baseCost: PropTypes.string,
  setBaseCost: PropTypes.func.isRequired,
  classDuration: PropTypes.string.isRequired,
  setClassDuration: PropTypes.func.isRequired,
  currency: PropTypes.number.isRequired,
  setCurrency: PropTypes.func.isRequired,
  isProviding: PropTypes.bool.isRequired,
  setIsProviding: PropTypes.func.isRequired,
  originalCurrency: PropTypes.shape({
    current: PropTypes.any,
  }).isRequired,
};

export { PersonalTrainingSettings };
