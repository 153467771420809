import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Text, View } from "react-native";
import tailwind from "twrnc";
import { FILL_COLOUR_1, FILL_COLOUR_4 } from "../base/Constants";
import dayjs from "dayjs";
import MiniTag from "../base/MiniTag";
import { ThemeContext } from "../base/ApplicationContext";

const LiveStreamDetails = ({ event }) => {
  const { theme } = useContext(ThemeContext);
  const {
    shortDescription,
    longDescription,
    scheduleTags,
    duration,
    startDate,
  } = event.userSchedule;

  const formattedDate = dayjs(startDate).format("ddd, DD MMM YYYY");
  const formattedTime = dayjs(startDate).format("HH:mm");

  return (
    <>
      <View
        style={tailwind`h-full border-[${FILL_COLOUR_1}] border-t-[1px] border-b-[1px]`}
      >
        <View style={tailwind`bg-[${FILL_COLOUR_4}] py-2`}>
          <Text
            style={tailwind`text-[${theme.textColor}] font-semibold self-center`}
          >
            {formattedDate} {formattedTime}
          </Text>
        </View>
        <View style={tailwind`flex-row`}>
          <View style={tailwind`w-1/2 pl-2`}>
            <Text
              style={tailwind`text-[${theme.textColor}] font-semibold mt-2 text-[16px]`}
            >
              {shortDescription}
            </Text>
          </View>
          <View style={tailwind`w-1/2 pr-2`}>
            <Text
              style={tailwind`text-[${theme.textColor}] font-semibold mt-2 self-end`}
            >
              {duration} minutes
            </Text>
          </View>
        </View>
        <View style={tailwind`w-full px-2`}>
          <Text style={tailwind`text-[${theme.textColor}] font-semibold mt-2`}>
            {longDescription}
          </Text>
        </View>
        <View style={tailwind`flex flex-row flex-wrap px-2 mt-2`}>
          {scheduleTags.map((scheduleTag) => (
            <MiniTag
              key={scheduleTag.tag.id}
              label={scheduleTag.tag.tagName}
              colorKey={scheduleTag.tag.tagColorKey}
            />
          ))}
        </View>
      </View>
    </>
  );
};

LiveStreamDetails.propTypes = {
  event: PropTypes.object.isRequired,
};

export default LiveStreamDetails;
