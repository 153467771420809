import React, { useContext, useEffect, useReducer, useState } from "react";
import { Platform, SafeAreaView, Text, View } from "react-native";
import tailwind from "twrnc";
import Button from "../base/Button";
import {
  DIFFICULTY_ALL,
  TREE_TYPE_BODY,
  TREE_TYPE_USER,
} from "../base/Constants";
import TagSelector from "../tags/TagSelector";
import { TagReducer } from "../tags/TagReducer";
import { useNavigation } from "@react-navigation/native";
import {
  BottomSheetContext,
  CurrentUserContext,
  NavigationSyncContext,
  ThemeContext,
} from "../base/ApplicationContext";
import DifficultyNative from "../form/Difficulty.native";
import Difficulty from "../form/Difficulty";

const SearchModal = () => {
  const navigation = useNavigation();
  const { setBottomSheetVisible, setBottomSheetContent } =
    useContext(BottomSheetContext);
  const [difficulty, setDifficulty] = useState(DIFFICULTY_ALL);
  const [userTagSelection, processUserTagSelection] = useReducer(
    TagReducer,
    []
  );
  const [bodyTagSelection, processBodyTagSelection] = useReducer(
    TagReducer,
    []
  );

  const { searchQuery, setSearchQuery } = useContext(CurrentUserContext);
  const { setFocusedScreen } = useContext(NavigationSyncContext);
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    if (searchQuery) {
      const decodedSearchQuery = JSON.parse(searchQuery);

      if (decodedSearchQuery.difficulty !== DIFFICULTY_ALL)
        setDifficulty(decodedSearchQuery.difficulty);

      decodedSearchQuery.userTags.forEach((tag) => {
        processUserTagSelection({
          id: tag.id,
          title: tag.title,
          deleted: 0,
        });
      });

      decodedSearchQuery.bodyTags.forEach((tag) => {
        processBodyTagSelection({
          id: tag.id,
          title: tag.title,
          deleted: 0,
        });
      });
    }
  }, []);

  return (
    <SafeAreaView style={tailwind`bg-[${theme.backgroundColor}]`}>
      <View
        style={tailwind`h-[50px] py-4 mb-2 bg-[${theme.searchModalTopColor}]`}
      >
        <Text
          style={tailwind`self-center text-[${theme.searchModalTextColor}] uppercase font-semibold`}
        >
          Search
        </Text>
      </View>
      <View style={tailwind`w-full`}>
        <View style={tailwind`self-center mx-2 mb-2`}>
          <TagSelector
            tagType={TREE_TYPE_USER}
            processTagSelection={processUserTagSelection}
            tagSelection={userTagSelection}
          />
        </View>
        <View style={tailwind`self-center mx-2 mb-2`}>
          <TagSelector
            tagType={TREE_TYPE_BODY}
            processTagSelection={processBodyTagSelection}
            tagSelection={bodyTagSelection}
          />
        </View>
        <View style={tailwind`w-full self-center mt-8 mb-2 px-3`}>
          {Platform.OS !== "web" ? (
            <Text
              style={[
                tailwind`text-[20px] font-semibold text-[${theme.textColor}] ml-2`,
                { fontFamily: "Roboto_400Regular" },
              ]}
            >
              Difficulty
            </Text>
          ) : null}
          <View style={tailwind`self-center`}>
            {Platform.OS === "web" ? (
              <Difficulty
                difficulty={difficulty}
                setDifficulty={setDifficulty}
              />
            ) : (
              <DifficultyNative
                difficulty={difficulty}
                setDifficulty={setDifficulty}
              />
            )}
          </View>
        </View>
        <View style={tailwind`self-center flex-row py-6`}>
          <Button
            title={"Search"}
            disabled={
              !(
                userTagSelection.filter((tag) => !tag.deleted).length > 0 ||
                bodyTagSelection.filter((tag) => !tag.deleted).length > 0
              )
            }
            onPress={() => {
              const query = {
                userTags: userTagSelection.filter((tag) => !tag.deleted),
                bodyTags: bodyTagSelection.filter((tag) => !tag.deleted),
                difficulty: difficulty,
              };
              setSearchQuery(JSON.stringify(query));
              setBottomSheetVisible(false);
              setBottomSheetContent(<></>);
              setFocusedScreen("SearchResultsScreen");
              navigation.navigate("SearchResultsScreen");
            }}
          />
          <Button
            title={"Close"}
            onPress={() => {
              setBottomSheetVisible(false);
            }}
          />
        </View>
      </View>
    </SafeAreaView>
  );
};

export { SearchModal as default };
