import React, { useContext } from "react";
import PropTypes from "prop-types";
import { QueryClient, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { API_URL } from "../base/Constants";
import { ActivityIndicator, Dimensions, Text, View } from "react-native";
import dayjs from "dayjs";
import ViewEvent from "./ViewEvent";
import tailwind from "twrnc";
import { Calendar } from "react-native-big-calendar";
import CalendarEvent from "./CalendarEvent";
import {
  BottomSheetContext,
  CurrentUserContext,
} from "../base/ApplicationContext";

const CalendarDay = ({ day, queryClient, providerId }) => {
  const { currentUser } = useContext(CurrentUserContext);
  const { setBottomSheetContent, setBottomSheetVisible } =
    useContext(BottomSheetContext);

  const id = currentUser.id;
  const { isLoading, error, data } = useQuery(
    [
      providerId
        ? "providerschedule" + providerId + day
        : "schedule" + id + day,
    ],
    () =>
      axios
        .get(
          providerId
            ? API_URL + "schedule/provider/" + providerId + "/" + day
            : API_URL + "schedule/" + id + "/" + day,
          {
            headers: { authorization: currentUser.token },
          }
        )
        .then((res) => {
          return res.data;
        }),
    { cacheTime: 0, staleTime: 0 }
  );

  if (isLoading) return <ActivityIndicator />;
  if (error) return <Text>An error has occurred: ${error.message}</Text>;

  const parsedData = data.map((event) => {
    return {
      title: event.shortDescription,
      start: dayjs(event.startDate).toDate(),
      end: dayjs(event.startDate).add(event.duration, "minute").toDate(),
      event: event,
    };
  });

  const openModal = (event) => {
    setBottomSheetContent(
      <ViewEvent event={event} queryClient={queryClient} />
    );
    setBottomSheetVisible(true);
  };

  return (
    <View style={tailwind`mx-2`}>
      <Calendar
        date={day}
        showTime={true}
        scrollOffsetMinutes={360}
        renderEvent={CalendarEvent}
        swipeEnabled={false}
        renderHeader={() => null}
        events={parsedData}
        height={Dimensions.get("window").height - 80}
        sortedMonthView={false}
        mode={"day"}
        onPressEvent={(data) => {
          openModal(data.event);
        }}
      />
    </View>
  );
};

CalendarDay.propTypes = {
  day: PropTypes.string.isRequired,
  queryClient: PropTypes.instanceOf(QueryClient),
  providerId: PropTypes.number,
};

export { CalendarDay as default };
