import React, { useContext, useRef } from "react";
import PropTypes from "prop-types";
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";
import { ActivityIndicator, Text, View } from "react-native";
import tailwind from "twrnc";
import { API_URL } from "../base/Constants";
import { StyledPicker } from "../base/StyledPicker";
import axios from "axios";
import { CurrentUserContext, ThemeContext } from "../base/ApplicationContext";
import { Roboto_400Regular, useFonts } from "@expo-google-fonts/roboto";

const Currency = ({ currency, setCurrency }) => {
  const queryClient = new QueryClient();
  const originalCurrency = useRef(currency);

  let fonts = useFonts({ Roboto_400Regular });
  if (!fonts) return;

  return (
    <QueryClientProvider client={queryClient}>
      <CurrencyPanel
        currency={currency}
        setCurrency={setCurrency}
        originalCurrency={originalCurrency}
      />
    </QueryClientProvider>
  );
};

const CurrencyPanel = ({ currency, setCurrency, originalCurrency }) => {
  const { currentUser } = useContext(CurrentUserContext);
  const { theme } = useContext(ThemeContext);
  const hasNoCurrency = useRef(false);
  const { isLoading, error, data } = useQuery(["currencies"], () =>
    axios
      .get(API_URL + "currencies", {
        staleTime: 0,
        headers: { authorization: currentUser.token },
      })
      .then((res) => {
        return res.data;
      })
  );

  if (isLoading) return <ActivityIndicator />;
  if (error) return <Text>An error has occurred: ${error.message}</Text>;

  if (!currency) {
    hasNoCurrency.current = true;
    currency = 0;
  }

  return (
    <>
      <Text
        style={[
          tailwind`text-[${theme.textColor}] font-semibold mb-1`,
          { fontFamily: "Roboto_400Regular" },
        ]}
      >
        Currency
      </Text>
      <StyledPicker
        items={
          hasNoCurrency.current == true
            ? [
                {
                  label: "",
                  value: 0,
                },
                ...data.map((currencyItem) => {
                  return {
                    label: currencyItem.name,
                    value: currencyItem.id,
                  };
                }),
              ]
            : data.map((currencyItem) => {
                return {
                  label: currencyItem.name,
                  value: currencyItem.id,
                };
              })
        }
        selectedValue={currency}
        onValueChange={(itemValue) => {
          setCurrency(Number(itemValue));
        }}
      />
      {originalCurrency.current !== currency ? (
        <View style={tailwind`ml-2`}>
          <Text
            style={[
              tailwind`text-[${theme.redColor}]`,
              { fontFamily: "Roboto_400Regular" },
            ]}
          >
            Requires restart
          </Text>
        </View>
      ) : null}
    </>
  );
};

Currency.propTypes = {
  currency: PropTypes.number.isRequired,
  setCurrency: PropTypes.func.isRequired,
};

CurrencyPanel.propTypes = {
  currency: PropTypes.number.isRequired,
  setCurrency: PropTypes.func.isRequired,
  originalCurrency: PropTypes.shape({
    current: PropTypes.number,
  }).isRequired,
};

export { Currency as default };
