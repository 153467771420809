import * as React from "react";
import PropTypes from "prop-types";
import { Text, View } from "react-native";
import tailwind from "twrnc";
import TimerButton from "./TimerButton";
import { ThemeContext } from "../base/ApplicationContext";

const Sets = ({ overlayPlan, setOverlayPlan }) => {
  const { theme } = React.useContext(ThemeContext);
  const addTimer = (setType, duration) => {
    console.log("calling setOverlayPlan");
    setOverlayPlan([
      ...overlayPlan,
      {
        key: overlayPlan.length++,
        setType: setType,
        duration: duration,
      },
    ]);
  };

  return (
    <View style={tailwind`ml-2`}>
      <View style={tailwind`flex-row mt-2`}>
        <Text
          style={tailwind`pt-3 text-[${theme.textColor}] font-semibold w-[50px]`}
        >
          WORK
        </Text>
        <TimerButton title={"10"} onPress={() => addTimer(1, 10)} />
        <TimerButton title={"20"} onPress={() => addTimer(1, 20)} />
        <TimerButton title={"30"} onPress={() => addTimer(1, 30)} />
        <TimerButton title={"45"} onPress={() => addTimer(1, 45)} />
        <TimerButton title={"60"} onPress={() => addTimer(1, 60)} />
      </View>
      <View style={tailwind`flex-row mt-2`}>
        <Text
          style={tailwind`pt-3 text-[${theme.textColor}] font-semibold w-[50px]`}
        >
          REST
        </Text>
        <TimerButton title={"10"} onPress={() => addTimer(2, 10)} />
        <TimerButton title={"20"} onPress={() => addTimer(2, 20)} />
        <TimerButton title={"30"} onPress={() => addTimer(2, 30)} />
        <TimerButton title={"45"} onPress={() => addTimer(2, 45)} />
        <TimerButton title={"60"} onPress={() => addTimer(2, 60)} />
      </View>
    </View>
  );
};

Sets.propTypes = {
  overlayPlan: PropTypes.array.isRequired,
  setOverlayPlan: PropTypes.func.isRequired,
};

export default Sets;
