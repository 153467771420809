import React, { useContext, useRef, useState } from "react";
import {
  API_URL,
  DEFAULT_PROFILE_URL,
  NOTIFICATION_TYPE_EVENT,
  NOTIFICATION_TYPE_MESSAGE,
  NOTIFICATION_TYPE_SYSTEM,
} from "../components/base/Constants";
import tailwind from "twrnc";
import { Platform, ScrollView, Text, View } from "react-native";
import Button from "../components/base/Button";
import axios from "axios";
import { useNavigation } from "@react-navigation/native";
import { StyledInput } from "../components/base/StyledInput";
import {
  analyzeEmailAddress,
  analyzeLoginPassword,
} from "../components/base/Validation";
import {
  CurrentUserContext,
  FullScreenContext,
  ThemeContext,
} from "../components/base/ApplicationContext";
import { Roboto_400Regular, useFonts } from "@expo-google-fonts/roboto";

const LoginScreen = () => {
  const {
    setCurrentUser,
    setOutstandingNotificationCount,
    setOutstandingMessageCount,
    setOutstandingConnectionCount,
    setLiveStreamCredits,
    setIsProfileComplete,
  } = useContext(CurrentUserContext);
  const { setMobileFullScreen } = useContext(FullScreenContext);
  const { theme } = useContext(ThemeContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginStatus, setLoginStatus] = useState("");
  const navigation = useNavigation();
  const emailAddressStatus = useRef();
  const passwordStatus = useRef();

  const handleLogin = async (email, password, callback) => {
    try {
      await axios
        .post(
          API_URL + "auth/",
          { email, password },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          const {
            id,
            firstName,
            lastName,
            emailAddress,
            type,
            profileUrl,
            currencyId,
            currencyName,
            currencySymbol,
            token,
            credits,
            notifications,
            isProfileCompleted,
            requestedConnections,
          } = response.data;

          setCurrentUser({
            id: id,
            firstName: firstName,
            lastName: lastName,
            emailAddress: emailAddress,
            type: type.id,
            profileUrl: profileUrl || DEFAULT_PROFILE_URL,
            authenticated: true,
            currencyId: currencyId,
            currencyName: currencyName,
            currencySymbol: currencySymbol,
            token: token,
            credits: credits,
            isProfileCompleted: isProfileCompleted,
          });

          let eventNotificationCount = 0,
            messageCount = 0;
          notifications.forEach((notification) => {
            switch (notification.type.id) {
              case NOTIFICATION_TYPE_MESSAGE:
                messageCount++;
                break;
              case NOTIFICATION_TYPE_EVENT:
              case NOTIFICATION_TYPE_SYSTEM:
              default:
                if (!notification.isRead) eventNotificationCount++;
                break;
            }
          });

          setOutstandingNotificationCount(eventNotificationCount);
          setOutstandingMessageCount(messageCount);
          setOutstandingConnectionCount(requestedConnections);
          setLiveStreamCredits(credits);
          setIsProfileComplete(isProfileCompleted);

          setMobileFullScreen(false);
          callback();
        });
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data) {
        setLoginStatus(error.response.data.message);
      } else {
        setLoginStatus(
          "Unable to connect, please check your internet connection and try again."
        );
      }
      setTimeout(() => setLoginStatus(""), 5000);
    }
  };

  let fonts = useFonts({ Roboto_400Regular });
  if (!fonts) return;

  return (
    <ScrollView style={tailwind`bg-[${theme.backgroundColor}]`}>
      <View style={tailwind`w-full pb-4`}>
        <View style={tailwind`flex h-full bg-[${theme.backgroundColor}] pt-5`}>
          <View style={tailwind`mb-5`}></View>
          {Platform.OS === "web" ? (
            <View style={tailwind`flex w-3/4 self-center`}>
              <form>
                <View style={tailwind`mb-5`}>
                  <StyledInput
                    label="Email Address"
                    placeholder="Email address"
                    autoFocus={true}
                    autoComplete="email"
                    keyboardType="email"
                    onChangeText={(text) => {
                      setEmail(text);
                      analyzeEmailAddress(text, emailAddressStatus);
                    }}
                    fieldStatus={emailAddressStatus}
                    width={"300px"}
                  />
                </View>
                <View style={tailwind`mb-5`}>
                  <StyledInput
                    label={"Password"}
                    placeholder="Password"
                    keyboardType="text"
                    autoComplete="password"
                    secure={true}
                    onChangeText={(text) => {
                      setPassword(text);
                      analyzeLoginPassword(text, passwordStatus);
                    }}
                    fieldStatus={passwordStatus}
                    width={"300px"}
                  />
                </View>
                <Button
                  title="Login"
                  disabled={
                    !(
                      password &&
                      email &&
                      passwordStatus.current === "" &&
                      emailAddressStatus.current === ""
                    )
                  }
                  onPress={() => {
                    setLoginStatus("Authenticating...");
                    handleLogin(email, password, () => setLoginStatus(""));
                  }}
                />
                <View style={tailwind`min-h-[30px] self-center`}>
                  <Text
                    style={[
                      tailwind`text-[${theme.redColor}] mt-2`,
                      { fontFamily: "Roboto_400Regular" },
                    ]}
                  >
                    {loginStatus}
                  </Text>
                </View>
                <View style={tailwind`flex-row mt-20 self-center`}>
                  <Text
                    style={[
                      tailwind`text-[${theme.textColor}] mt-3 mr-3`,
                      { fontFamily: "Roboto_400Regular" },
                    ]}
                  >
                    Don&#39;t have an account?
                  </Text>
                  <Button
                    title="Register"
                    onPress={() => navigation.navigate("RegisterUserScreen")}
                  />
                </View>
              </form>
            </View>
          ) : (
            <View style={tailwind`flex w-3/4 self-center`}>
              <View style={tailwind`mb-5`}>
                <StyledInput
                  label="Email Address"
                  placeholder="Email address"
                  autoFocus={true}
                  autoComplete="email"
                  keyboardType="email"
                  onChangeText={(text) => {
                    setEmail(text);
                    analyzeEmailAddress(text, emailAddressStatus);
                  }}
                  fieldStatus={emailAddressStatus}
                  width={"300px"}
                />
              </View>
              <View style={tailwind`mb-5`}>
                <StyledInput
                  label={"Password"}
                  placeholder="Password"
                  keyboardType="text"
                  autoComplete="password"
                  secure={true}
                  onChangeText={(text) => {
                    setPassword(text);
                    analyzeLoginPassword(text, passwordStatus);
                  }}
                  fieldStatus={passwordStatus}
                  width={"300px"}
                />
              </View>
              <Button
                title="Login"
                disabled={
                  !(
                    password &&
                    email &&
                    passwordStatus.current === "" &&
                    emailAddressStatus.current === ""
                  )
                }
                onPress={() => {
                  setLoginStatus("Authenticating...");
                  handleLogin(email, password, () => setLoginStatus(""));
                }}
              />
              <View style={tailwind`min-h-[30px] self-center`}>
                <Text
                  style={[
                    tailwind`text-[${theme.redColor}] mt-2`,
                    { fontFamily: "Roboto_400Regular" },
                  ]}
                >
                  {loginStatus}
                </Text>
              </View>
              <View style={tailwind`flex-row mt-20 self-center`}>
                <Text
                  style={[
                    tailwind`text-[${theme.textColor}] mt-3 mr-3`,
                    { fontFamily: "Roboto_400Regular" },
                  ]}
                >
                  Don&#39;t have an account?
                </Text>
                <Button
                  title="Register"
                  onPress={() => navigation.navigate("RegisterUserScreen")}
                />
              </View>
            </View>
          )}
        </View>
      </View>
    </ScrollView>
  );
};

export default LoginScreen;
