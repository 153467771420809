import React, {useRef} from "react";
import PropTypes from "prop-types";
import ReactHlsPlayer from "react-hls-player/dist";
import { View } from "react-native";
import { LIVE_STREAM_VIEW_URL } from "../base/Constants";

const WebPlayerPreview = ({ event, previewMuted }) => {
  const videoRef = useRef();
  return (
    <View>
      <ReactHlsPlayer
        playerRef={videoRef}
        src={LIVE_STREAM_VIEW_URL + event.eventKey + ".m3u8"}
        muted={previewMuted}
        autoPlay={true}
        controls={true}
        width={"80%"}
        height={"auto"}
        // style={playerStyle}
      />
    </View>
  );
};

WebPlayerPreview.propTypes = {
  event: PropTypes.object.isRequired,
  previewMuted: PropTypes.bool.isRequired,
};

export default WebPlayerPreview;
