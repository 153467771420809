import React, { useContext, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Platform, Pressable, View, ActivityIndicator } from "react-native";
import { Camera, CameraType } from "expo-camera";
import tailwind from "twrnc";
import { ThemeContext } from "../base/ApplicationContext";
import { Image } from "@rneui/themed";

const TakeSelfie = ({ setSelfieImage, setTakingSelfie }) => {
  const { theme } = useContext(ThemeContext);
  const [type, setType] = useState(CameraType.front);
  const cameraRef = useRef(null);

  const toggleCameraType = () => {
    setType((current) =>
      current === CameraType.back ? CameraType.front : CameraType.back
    );
  };

  const handleCapture = async () => {
    if (cameraRef.current) {
      const photo = await cameraRef.current.takePictureAsync();
      console.log("Captured selfie:", photo);
      setSelfieImage(photo);
      setTakingSelfie(false);
    }
  };

  const cancelSelfie = () => {
    setTakingSelfie(false);
  };

  const iconTintColor =
    Platform.OS === "web" ? "#FFFFFF" : theme.mobileChatVideoControlIconColor;

  return (
    <View
      style={tailwind`${
        Platform.OS !== "web"
          ? "w-full mb-4"
          : "h-[600px] w-[600px] self-center rounded-lg mb-2"
      } p-2`}
    >
      <View style={tailwind`absolute top-6 left-4`}>
        {Platform.OS !== "web" && !Platform.isTV ? (
          <Pressable style={tailwind`m-1`} onPress={toggleCameraType}>
            {type === CameraType.front ? (
              <Image
                resizeMode="contain"
                style={tailwind`h-[32px] w-[32px]`}
                source={require("../../assets/camera-front-icon.png")}
                tintColor={iconTintColor}
              />
            ) : (
              <Image
                resizeMode="contain"
                style={tailwind`h-[32px] w-[32px]`}
                source={require("../../assets/camera-rear-icon.png")}
                tintColor={iconTintColor}
              />
            )}
          </Pressable>
        ) : null}
      </View>
      {Platform.OS === "web" ? (
        <>
          <Camera
            ref={cameraRef}
            style={tailwind`max-w-[350px] max-h-[480px] bg-white self-center`}
            type={type}
          >
            <View
              style={tailwind`rounded-lg h-full w-[350px] self-center opacity-0`}
            />
          </Camera>
        </>
      ) : (
        <Camera
          ref={cameraRef}
          style={tailwind`h-[400px] w-[90%] bg-white self-center mt-14`}
          type={type}
        />
      )}
      <Pressable
        style={tailwind`absolute ${
          Platform.OS === "web" ? "bottom-40" : "bottom-20"
        } self-center border-[1px] rounded-full border-[${
          theme.selfieTakePhotoIconColor
        }]`}
        onPress={handleCapture}
      >
        <Image
          style={tailwind`w-[32px] h-[32px]`}
          source={require("../../assets/camera-icon.png")}
          PlaceholderContent={<ActivityIndicator />}
          tintColor={theme.selfieTakePhotoIconColor}
        />
      </Pressable>
      <Pressable
        style={tailwind`absolute ${
          Platform.OS === "web" ? "top-0 right-25" : "top-6 right-6"
        }`}
        onPress={cancelSelfie}
      >
        <Image
          style={tailwind`w-[32px] h-[32px]`}
          source={require("../../assets/close-icon.png")}
          PlaceholderContent={<ActivityIndicator />}
          tintColor={theme.selfieCloseIconColor}
        />
      </Pressable>
    </View>
  );
};

TakeSelfie.propTypes = {
  setSelfieImage: PropTypes.func.isRequired,
  setTakingSelfie: PropTypes.func.isRequired,
};

export { TakeSelfie };
