import React, { useContext } from "react";
import PropTypes from "prop-types";
import {
  Roboto_400Regular,
  Roboto_700Bold,
  useFonts,
} from "@expo-google-fonts/roboto";
import { Inter_400Regular } from "@expo-google-fonts/inter";
import { Dimensions, ScrollView, Text, View } from "react-native";
import tailwind from "twrnc";
import Participants from "../shared/Participants";
import PeerVideo from "./PeerVideo";
import SocketChat from "../shared/SocketChat";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import {
  CurrentUserContext,
  ThemeContext,
} from "../../base/ApplicationContext";

const Tab = createMaterialTopTabNavigator();

const ConsumerLayout = ({
  remoteStreams,
  eventOwnerId,
  participants,
  localStream,
  event,
  onSend,
  chatMessages,
}) => {
  const { currentUser } = useContext(CurrentUserContext);
  const { theme } = useContext(ThemeContext);
  const scrollViewWidth = Dimensions.get("window").width - 680 + "px";
  const panelHeight = tailwind`grow`;

  let fonts = useFonts({ Roboto_700Bold, Inter_400Regular });

  if (!fonts) return;

  return (
    <View style={tailwind`flex-row`}>
      <View style={tailwind`grow`}>
        <View style={panelHeight}>
          {remoteStreams.map((remoteStream) => {
            if (remoteStream.userId === eventOwnerId) {
              //Provider video stream
              let thisParticipant = participants.current.filter(
                (participant) => {
                  return participant.id === remoteStream.userId;
                }
              )[0];

              if (!remoteStream.deleted && remoteStream.stream.active)
                return (
                  <View key={thisParticipant.id} style={tailwind`grow`}>
                    <PeerVideo
                      peerStream={remoteStream}
                      thisParticipant={thisParticipant}
                      isHost={true}
                    />
                  </View>
                );
            }
          })}
        </View>
        <View style={tailwind`flex-row ml-1`}>
          {localStream ? (
            <PeerVideo
              peerStream={{ stream: localStream }}
              thisParticipant={{ id: currentUser.id, name: "" }}
            />
          ) : null}
          <ScrollView horizontal style={tailwind`max-w-[${scrollViewWidth}]`}>
            {remoteStreams.map((remoteStream) => {
              if (remoteStream.userId !== eventOwnerId) {
                let thisParticipant = participants.current.filter(
                  (participant) => {
                    return participant.id === remoteStream.userId;
                  }
                )[0];
                if (!remoteStream.deleted && remoteStream.stream.active)
                  return (
                    <PeerVideo
                      key={thisParticipant.id}
                      peerStream={remoteStream}
                      thisParticipant={thisParticipant}
                      providerMuted={true}
                    />
                  );
              }
            })}
          </ScrollView>
        </View>
      </View>
      <View style={tailwind`w-[300px] h-full mr-2`}>
        <View>
          <Text
            style={[
              tailwind`self-center text-[${theme.textColor}] font-semibold text-xl`,
              { fontFamily: "Roboto_700Bold" },
            ]}
          >
            {event.shortDescription}
          </Text>
          <Text
            style={[
              tailwind`self-center mb-2`,
              { fontFamily: "Inter_400Regular" },
            ]}
          >
            {event.longDescription}
          </Text>
        </View>
        <View
          style={tailwind`w-[300px] h-[${
            Dimensions.get("window").height - 170
          }px] self-center p-2 mt-2 mx-2`}
        >
          <ProviderTabs
            participants={participants}
            event={event}
            messages={chatMessages}
            onSend={onSend}
          />
        </View>
      </View>
    </View>
  );
};

const ProviderTabs = ({ participants, event, messages, onSend }) => {
  const { theme } = useContext(ThemeContext);

  let fonts = useFonts({ Roboto_400Regular });

  if (!fonts) return;

  return (
    <Tab.Navigator
      initialRouteName="consumer.tabs"
      screenOptions={{
        tabBarActiveTintColor: theme.tabBarActiveTintColor,
        tabBarInactiveTintColor: theme.tabBarInactiveTintColor,
        tabBarStyle: { backgroundColor: theme.tabBarBackgroundColor },
        tabBarIndicatorStyle: {
          backgroundColor: theme.tabBarIndicatorColor,
        },
        tabBarLabelStyle: { fontFamily: "Roboto_400Regular" },
      }}
    >
      <Tab.Screen
        name="Chat"
        children={() => (
          <SocketChat event={event} messages={messages} onSend={onSend} />
        )}
      />
      <Tab.Screen
        name="Attendees"
        children={() => <Participants participants={participants} />}
      />
    </Tab.Navigator>
  );
};

ConsumerLayout.propTypes = {
  remoteStreams: PropTypes.array.isRequired,
  eventOwnerId: PropTypes.number.isRequired,
  participants: PropTypes.object.isRequired,
  localStream: PropTypes.object,
  event: PropTypes.object.isRequired,
  onSend: PropTypes.func.isRequired,
  chatMessages: PropTypes.array.isRequired,
  inRoom: PropTypes.bool,
};

ProviderTabs.propTypes = {
  participants: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  messages: PropTypes.array.isRequired,
  onSend: PropTypes.func.isRequired,
};

export default ConsumerLayout;
