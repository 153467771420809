import React from "react";
import PropTypes from "prop-types";
import tailwind from "twrnc";
import { Picker } from "@react-native-picker/picker";

import {
  DIFFICULTY_ADVANCED,
  DIFFICULTY_ALL,
  DIFFICULTY_BEGINNER,
  DIFFICULTY_INTERMEDIATE,
} from "../base/Constants";

const Difficulty = ({ difficulty, setDifficulty }) => {

  const items = [
    {
      label: "All",
      value: DIFFICULTY_ALL,
    },
    {
      label: "Beginner",
      value: DIFFICULTY_BEGINNER,
    },
    {
      label: "Intermediate",
      value: DIFFICULTY_INTERMEDIATE,
    },
    {
      label: "Advanced",
      value: DIFFICULTY_ADVANCED,
    },
  ];

  console.log("In native difficulty");
  return (
    <>
      <Picker
        style={tailwind`w-[250px]`}
        selectedValue={difficulty}
        onValueChange={(itemValue) => setDifficulty(itemValue)}
      >
        {items.map((difficultyItem) => {
          return (
            <Picker.Item
              key={difficultyItem.value}
              label={difficultyItem.label}
              value={difficultyItem.value}
            />
          );
        })}
      </Picker>
    </>
  );
};

Difficulty.propTypes = {
  difficulty: PropTypes.number.isRequired,
  setDifficulty: PropTypes.func.isRequired,
};

export { Difficulty as default };
