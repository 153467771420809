import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useContext } from "react";
import { Platform, Pressable, View } from "react-native";
import { Image } from "@rneui/themed";
import tailwind from "twrnc";
import {
  CurrentUserContext,
  FullScreenContext,
  ThemeContext,
} from "../../base/ApplicationContext";

const PeerVideoControls = ({
  videoRef,
  thisParticipant,
  isMicMuted,
  setIsMicMuted,
  isMuted,
  setIsMuted,
  providerMuted,
  videoShown,
  setVideoShown,
  isHost,
  cameraType,
  setCameraType,
}) => {
  const { currentUser } = useContext(CurrentUserContext);
  const { theme } = useContext(ThemeContext);
  const { mobileFullScreen, setMobileFullScreen } =
    useContext(FullScreenContext);

  useEffect(() => {
    console.log(
      "peervideocontrols mobileFullScreen useEffect",
      mobileFullScreen
    );
  }, [mobileFullScreen]);

  const iconTintColor =
    Platform.OS === "web" ? "#FFFFFF" : theme.mobileChatVideoControlIconColor;

  return (
    <View style={tailwind`flex-row p-1`}>
      {currentUser.id === thisParticipant.id ? (
        <Pressable
          style={tailwind`p-2 w-[36px] h-[36px] ml-1`}
          onPress={() => setIsMicMuted(!isMicMuted)}
        >
          {isMicMuted ? (
            <Image
              resizeMode="contain"
              style={tailwind`h-[18px] w-[18px]`}
              source={require("../../../assets/microphone-icon-off.png")}
              tintColor={theme.redColor}
            />
          ) : (
            <Image
              resizeMode="contain"
              style={tailwind`h-[18px] w-[18px]`}
              source={require("../../../assets/microphone-icon.png")}
              tintColor={iconTintColor}
            />
          )}
        </Pressable>
      ) : (
        <Pressable
          style={tailwind`p-2 w-[36px] h-[36px] ml-1`}
          onPress={() => setIsMuted(!isMuted)}
        >
          {isMuted || (isHost && providerMuted) ? (
            <Image
              resizeMode="contain"
              style={tailwind`h-[18px] w-[18px]`}
              source={require("../../../assets/volume-icon-off.png")}
              tintColor={theme.redColor}
            />
          ) : (
            <Image
              resizeMode="contain"
              style={tailwind`h-[18px] w-[18px]`}
              source={require("../../../assets/volume-icon.png")}
              tintColor={iconTintColor}
            />
          )}
        </Pressable>
      )}
      {currentUser.id === thisParticipant.id ? (
        <Pressable
          style={tailwind`p-2 w-[36px] h-[36px] ml-1`}
          onPress={() => setVideoShown(!videoShown)}
        >
          {!videoShown ? (
            <Image
              resizeMode="contain"
              style={tailwind`h-[18px] w-[18px]`}
              source={require("../../../assets/video-icon-off.png")}
              tintColor={theme.redColor}
            />
          ) : (
            <Image
              resizeMode="contain"
              style={tailwind`h-[18px] w-[18px]`}
              source={require("../../../assets/video-icon.png")}
              tintColor={iconTintColor}
            />
          )}
        </Pressable>
      ) : null}
      {Platform.OS === "web" ? (
        <Pressable
          style={tailwind`p-2 w-[36px] h-[36px] ml-1`}
          onPress={() => videoRef.current.requestFullscreen()}
        >
          <Image
            resizeMode="contain"
            style={tailwind`h-[18px] w-[18px]`}
            source={require("../../../assets/fullscreen-icon.png")}
            tintColor={iconTintColor}
          />
        </Pressable>
      ) : null}
      {currentUser.id === thisParticipant.id &&
      (Platform.OS === "ios" ||
        (Platform.OS === "android" && !Platform.isTV)) ? (
        <Pressable
          style={tailwind`p-2 w-[36px] h-[36px] ml-1`}
          onPress={() => {
            if (cameraType === "user") {
              setCameraType("environment");
            } else {
              setCameraType("user");
            }
          }}
        >
          {cameraType === "user" ? (
            <Image
              resizeMode="contain"
              style={tailwind`h-[18px] w-[18px]`}
              source={require("../../../assets/camera-front-icon.png")}
              tintColor={iconTintColor}
            />
          ) : (
            <Image
              resizeMode="contain"
              style={tailwind`h-[18px] w-[18px]`}
              source={require("../../../assets/camera-rear-icon.png")}
              tintColor={iconTintColor}
            />
          )}
        </Pressable>
      ) : null}
    </View>
  );
};

PeerVideoControls.propTypes = {
  videoRef: PropTypes.shape({
    current: PropTypes.any,
  }),
  thisParticipant: PropTypes.object.isRequired,
  isMicMuted: PropTypes.bool.isRequired,
  setIsMicMuted: PropTypes.func.isRequired,
  isMuted: PropTypes.bool,
  setIsMuted: PropTypes.func.isRequired,
  providerMuted: PropTypes.bool,
  videoShown: PropTypes.bool.isRequired,
  setVideoShown: PropTypes.func.isRequired,
  isHost: PropTypes.bool,
  mobileFullScreen: PropTypes.number,
  setMobileFullScreen: PropTypes.func,
  cameraType: PropTypes.string,
  setCameraType: PropTypes.func,
};

export default PeerVideoControls;
