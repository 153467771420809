import * as React from "react";
import PropTypes from "prop-types";
import { Text, Pressable, View } from "react-native";
import tailwind from "twrnc";
import {
  BUTTON_TEXT_COLOUR,
  CALENDAR_EVENT_COLOUR,
} from "./Constants";
import { ThemeContext } from "./ApplicationContext";

const Button = ({ title, onPress, selected, disabled }) => {
  let buttonStyle = {};
  const { theme } = React.useContext(ThemeContext);

  buttonStyle = selected
    ? tailwind`w-auto h-[40px] bg-[${CALENDAR_EVENT_COLOUR}] rounded-lg p-2 ml-1 w-[100px] max-w-[200px]`
    : disabled
    ? tailwind`w-auto h-[40px] bg-[#cccccc] rounded-lg p-2 ml-1 w-[100px] max-w-[200px]`
    : tailwind`w-auto h-[40px] bg-[${theme.primaryButtonColor}] rounded-lg p-2 ml-1 w-[100px] max-w-[200px]`;

  return (
    <Pressable onPress={!disabled ? onPress : null} style={buttonStyle}>
      <View style={tailwind`py-1`}>
        <Text
          style={tailwind`text-[${BUTTON_TEXT_COLOUR}] font-semibold text-xs self-center`}
        >
          {title}
        </Text>
      </View>
    </Pressable>
  );
};

Button.propTypes = {
  title: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default Button;
