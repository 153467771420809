import React, { useContext } from "react";
import { View, Text, SectionList, Platform } from "react-native";
import tailwind from "twrnc";
import {
  useQuery,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import axios from "axios";
import { API_URL, EVENT_TYPE_BROADCAST } from "../components/base/Constants";
import { ActivityIndicator } from "react-native";
import {
  CurrentUserContext,
  ThemeContext,
} from "../components/base/ApplicationContext";
import SearchResultsSectionContainer from "../components/search/SearchResultsSectionContainer";

const queryClient = new QueryClient();

const SearchResultsScreen = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <View
      style={tailwind`flex h-full bg-[${theme.backgroundColor}] pl-[${
        Platform.OS === "web" ? "50px" : "10px"
      }]`}
    >
      <QueryClientProvider client={queryClient}>
        <Sections />
      </QueryClientProvider>
    </View>
  );
};

function Sections() {
  const { currentUser } = useContext(CurrentUserContext);
  const { theme } = useContext(ThemeContext);

  const { isLoading, error, data } = useQuery(
    ["searchresultpagesections"],
    () =>
      axios
        .get(API_URL + "search/sections", {
          headers: { authorization: currentUser.token },
        })
        .then((res) => res.data)
  );

  if (isLoading) return <ActivityIndicator />;
  if (error) return <Text>{"An error has occurred: " + error.message}</Text>;

  data.forEach((item) => (item.isDynamic = true));

  const formattedData = data.map((item) => ({
    title: item.data?.title || item.title || "",
    type: item.data?.type || item.type || "",
    data: [item],
  }));

  return (
    <SectionList
      sections={formattedData}
      keyExtractor={(item) => item.id.toString()}
      renderItem={({ item }) => {
        return <SearchResultsSectionContainer item={item} />;
      }}
      renderSectionHeader={({ section }) => {
        const title = section.title;
        return (
          <View
            style={tailwind`flex-row bg-[${theme.backgroundColor}] pt-6 pb-4`}
          >
            <View>
              <Text
                style={[
                  tailwind`font-semibold text-[${theme.textColor}] text-[20px]`,
                  { fontFamily: "Roboto_700Bold" },
                ]}
              >
                {title}
              </Text>
            </View>
            {section.type.id === EVENT_TYPE_BROADCAST ? (
              <View
                style={tailwind`rounded-full w-[10px] h-[10px] bg-[${theme.liveDotColor}] mt-2 ml-2`}
              />
            ) : null}
          </View>
        );
      }}
    />
  );
}

export default SearchResultsScreen;
