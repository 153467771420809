import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useReducer,
} from "react";
import PropTypes from "prop-types";
import { SafeAreaView, ScrollView } from "react-native";
import { mediaDevices } from "react-native-webrtc-web-shim";
import tailwind from "twrnc";
import ConsumerLayout from "../components/private-room/web/ConsumerLayout";
import SocketProvider, {
  SocketContext,
} from "../components/private-room/web/SocketProvider";
import PeerConnectionProvider, {
  PeerConnectionContext,
} from "../components/private-room/shared/PeerConnectionProvider";
import ProviderLayout from "../components/private-room/web/ProviderLayout";
import { DEFAULT_PROFILE_URL } from "../components/base/Constants";
import { GiftedChat } from "react-native-gifted-chat";
import {
  CurrentUserContext,
  ThemeContext,
} from "../components/base/ApplicationContext";

function streamReducer(state, item) {
  let existingItem = null;

  if (item.deleted) {
    state = state.filter((stateItem) => {
      return item.userId !== stateItem.userId;
    });
  } else {
    existingItem = state.filter((stateItem) => {
      return stateItem.userId === item.userId;
    })[0];
  }
  if (existingItem) {
    state = state.filter((stateItem) => {
      return stateItem.userId !== existingItem.userId;
    });
  }
  return [...state, item];
}

const NewChat3 = (props) => {
  const [localStream, setLocalStream] = useState();
  const localStreamRef = useRef();
  const [allMuted, setAllMuted] = useState(true);
  const { socketRef } = useContext(SocketContext);
  const { peersRef } = useContext(PeerConnectionContext);

  const [remoteStreams, processRemoteStream] = useReducer(streamReducer, []);
  const [totalRemoteStreams, setTotalRemoteStreams] = useState(0);

  const { event } = props.route.params;
  const roomId = event.eventKey;
  const eventOwnerId = parseInt(event.userId);

  const { currentUser } = useContext(CurrentUserContext);
  const { theme } = useContext(ThemeContext);

  const participants = useRef([]);
  const [messages, setMessages] = useState([]);
  const messagesRef = useRef([]);

  const addNewMessage = (newMessage) => {
    GiftedChat.append(messages, newMessage, true);
    setMessages([...messagesRef.current, newMessage]);
    messagesRef.current.push(newMessage);
  };

  useEffect(() => {
    setTotalRemoteStreams(
      remoteStreams.filter((stream) => !stream.deleted).length
    );
  }, [remoteStreams]);

  useEffect(() => {
    mapParticipants();
    init();
    return () => {
      socketRef.disconnectCall(
        localStreamRef.current,
        setLocalStream,
        remoteStreams,
        processRemoteStream,
        setTotalRemoteStreams,
        peersRef
      );
      setMessages([]);
      localStreamRef.current = null;
    };
  }, []);

  const mapParticipants = () => {
    let eventParticipants = [];
    if (event && event.participants && event.participants.length) {
      const participantsForEvent = event.participants;

      eventParticipants = [];
      participantsForEvent.forEach((participant) => {
        const newParticipant = {
          id: participant.user.id,
          profileUrl: participant.user.profileUrl || DEFAULT_PROFILE_URL,
          name: participant.user.firstName + " " + participant.user.lastName,
        };
        if (currentUser.id !== participant.id) {
          eventParticipants.push(newParticipant);
        }
      });
      participants.current = eventParticipants;
    }
  };

  const init = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    // const audioDevice = devices.find((device) => device.kind === "audiooutput");
    console.log(devices);
    await socketRef.connectSocket();
    await getLocalStream((stream) => {
      setLocalStream(stream);
      localStreamRef.current = stream;
      socketRef.connectToRoom(
        stream,
        roomId,
        currentUser,
        remoteStreams,
        processRemoteStream,
        setTotalRemoteStreams,
        peersRef,
        (newMessage) => {
          addNewMessage(newMessage);
        },
        (userId, messageHistory) => {
          if (userId === currentUser.id) {
            messagesRef.current = messageHistory;
            setMessages(messagesRef.current);
          }
        }
      );
      // setInRoom(true);
    });
  };

  const onSend = (newMessages) => {
    newMessages.forEach(
      (message) => (message.createdAt = message.createdAt.toISOString())
    );
    socketRef.sendMessage(currentUser, roomId, newMessages[0]);
    messagesRef.current.push(newMessages[0]);
    setMessages(messagesRef.current);
  };

  async function getLocalStream(callback) {
    try {
      const stream = await mediaDevices.getUserMedia({
        audio: true,
        video: true,
      });
      callback(stream);
    } catch (err) {
      console.log("error setting local stream", err);
    }
  }

  return (
    <SocketProvider>
      <PeerConnectionProvider>
        <SafeAreaView style={tailwind`bg-[${theme.backgroundColor}] h-full`}>
          <ScrollView>
            {currentUser.id === eventOwnerId ? (
              <>
                <ProviderLayout
                  remoteStreams={remoteStreams}
                  eventOwnerId={eventOwnerId}
                  participants={participants}
                  localStream={localStream}
                  allMuted={allMuted}
                  setAllMuted={setAllMuted}
                  event={event}
                  onSend={onSend}
                  chatMessages={messages}
                  totalRemoteStreams={totalRemoteStreams}
                />
              </>
            ) : (
              <ConsumerLayout
                remoteStreams={remoteStreams}
                eventOwnerId={eventOwnerId}
                participants={participants}
                localStream={localStream}
                event={event}
                onSend={onSend}
                chatMessages={messages}
              />
            )}
          </ScrollView>
        </SafeAreaView>
      </PeerConnectionProvider>
    </SocketProvider>
  );
};

NewChat3.propTypes = {
  route: PropTypes.object.isRequired,
};

export default NewChat3;
