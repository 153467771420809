import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useNavigation } from "@react-navigation/native";
import { View, Pressable, ActivityIndicator, Text } from "react-native";
import tailwind from "twrnc";
import {
  NavigationSyncContext,
  ThemeContext,
} from "../base/ApplicationContext";
import MiniTag from "../base/MiniTag";
import { Image } from "@rneui/themed";

const VideoThumbnail = ({ video }) => {
  const navigation = useNavigation();
  const { theme } = useContext(ThemeContext);
  const { setFocusedScreen } = useContext(NavigationSyncContext);
  const thumbnail = video.video.thumbnailPath;
  const tags = video.video.onDemandVideoTags;
  const userVideos = video.video.userVideos;

  let profileUrl = "";
  if (userVideos && userVideos.length > 0) {
    profileUrl = userVideos[0].userId.profileUrl;
  }

  return (
    <Pressable
      style={tailwind`rounded-lg bg-[${theme.backgroundColor}] mr-[40px] mb-2`}
      onPress={() => {
        setFocusedScreen("OnDemandScreen");
        navigation.navigate("OnDemandScreen", { video: video });
      }}
    >
      <View style={tailwind`rounded-lg max-w-[212px]`}>
        <View>
          <Image
            style={tailwind`w-[212px] h-[118px] rounded-lg`}
            source={{ uri: thumbnail }}
            PlaceholderContent={<ActivityIndicator />}
          >
            <View style={tailwind`grow`} />
            <View style={tailwind`flex-row p-2`}>
              {profileUrl ? (
                <View
                  style={tailwind`rounded-full w-[20px] h-[20px] border-[1px] border-[${theme.onDemandProfileBorderColor}]`}
                >
                  <Image
                    style={tailwind`h-[20px] rounded-full`}
                    source={{ uri: profileUrl }}
                  />
                </View>
              ) : null}
              <View style={tailwind`grow`} />
              <View>
                {tags.length ? (
                  <MiniTag
                    key={tags[0].id}
                    label={
                      (tags[0].tagId && tags[0].tagId.tagName).toUpperCase() ||
                      ""
                    }
                    colorKey={tags[0].tagId.tagColorKey}
                  />
                ) : null}
              </View>
              <View
                style={tailwind`bg-[${theme.timePillBackgoundColor}] rounded-full pt-[3px] px-[6px]`}
              >
                <Text
                  style={[
                    tailwind`text-[${theme.timePillTextColor}] text-[10px]`,
                    { fontFamily: "Inter_600SemiBold" },
                  ]}
                >
                  30 MIN
                </Text>
              </View>
            </View>
          </Image>
        </View>
      </View>
    </Pressable>
  );
};

VideoThumbnail.propTypes = {
  video: PropTypes.object.isRequired,
};

export default VideoThumbnail;
