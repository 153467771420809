import React, { ScrollView } from "react-native";
import tailwind from "twrnc";
import NotificationsPanel from "../components/notifications/NotificationsPanel";
import { ThemeContext } from "../components/base/ApplicationContext";
import { useContext } from "react";

const NotificationScreen = () => {
  const { theme } = useContext(ThemeContext);
  return (
    <>
      <ScrollView
        style={tailwind`flex w-full h-full bg-[${theme.backgroundColor}]`}
      >
        <NotificationsPanel />
      </ScrollView>
    </>
  );
};

export { NotificationScreen };
