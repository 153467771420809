import React, { useContext } from "react";
import { Pressable, SafeAreaView, Text, View } from "react-native";
import { useNavigation } from "@react-navigation/native";
import {
  NavigationSyncContext,
  ThemeContext,
} from "../components/base/ApplicationContext";
import tailwind from "twrnc";
import { Roboto_400Regular, useFonts } from "@expo-google-fonts/roboto";

const SettingsScreen = () => {
  const navigation = useNavigation();
  const { setFocusedScreen } = useContext(NavigationSyncContext);
  const { theme } = useContext(ThemeContext);

  let fonts = useFonts({ Roboto_400Regular });
  if (!fonts) return;

  return (
    <SafeAreaView style={tailwind`h-full pt-3 bg-[${theme.backgroundColor}]`}>
      <View>
        {/* <Pressable
          style={tailwind`self-center`}
          onPress={() => {
            setFocusedScreen("LibraryScreen");
            navigation.navigate("LibraryScreen");
          }}
        >
          <View
            style={tailwind`rounded-lg mx-1 p-3 bg-[${theme.bundleItemBackgroundColor}] w-full self-center mb-2`}
          >
            <Text
              style={[
                tailwind`font-semibold self-center`,
                { fontFamily: "Roboto_400Regular" },
              ]}
            >
              LIBRARY
            </Text>
          </View>
        </Pressable> */}
        <Pressable
          style={tailwind`self-center`}
          onPress={() => {
            navigation.navigate("LogoutScreen");
          }}
        >
          <View
            style={tailwind`rounded-lg mx-1 p-3 bg-[${theme.bundleItemBackgroundColor}] w-full self-center mb-2`}
          >
            <Text
              style={[
                tailwind`font-semibold self-center`,
                { fontFamily: "Roboto_400Regular" },
              ]}
            >
              LOGOUT
            </Text>
          </View>
        </Pressable>
      </View>
    </SafeAreaView>
  );
};

export { SettingsScreen as default };
