import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Text, View, Platform } from "react-native";
import tailwind from "twrnc";
import { StyledInput } from "../base/StyledInput";
import { analyzeConfirmPassword, analyzePassword } from "../base/Validation";
import { ThemeContext } from "../base/ApplicationContext";
import { Roboto_700Bold, useFonts } from "@expo-google-fonts/roboto";

const ChangePasswordForm = ({
  setExistingPassword,
  setNewPassword,
  confirmPassword,
  setConfirmPassword,
  passwordStatus,
  confirmPasswordStatus,
}) => {
  const { theme } = useContext(ThemeContext);

  let fonts = useFonts({ Roboto_700Bold });
  if (!fonts) return;

  return (
    <>
      {Platform.OS === "web" ? (
        <form>
          <View style={tailwind`w-[618px]`}>
            <Text
              style={[
                tailwind`text-[20px] mx-2 my-4 font-semibold text-[${theme.textColor}]`,
                { fontFamily: "Roboto_700Bold" },
              ]}
            >
              Change Password
            </Text>

            <View style={tailwind`w-1/2 pl-2 mb-4`}>
              <StyledInput
                label="Existing Password"
                onChangeText={(text) => {
                  setExistingPassword(text);
                }}
                secure={true}
                placeholder={"existing password"}
              />
            </View>
            <View style={tailwind`flex-row`}>
              <View style={tailwind`w-1/2 pl-2 mb-4`}>
                <StyledInput
                  label="New Password"
                  onChangeText={(text) => {
                    setNewPassword(text);
                    analyzePassword(text, passwordStatus);
                  }}
                  placeholder={"new password"}
                  secure={true}
                  fieldStatus={passwordStatus}
                />
              </View>
              <View style={tailwind`w-1/2`}>
                <StyledInput
                  label="Confirm New Password"
                  onChangeText={(text) => {
                    setConfirmPassword(text);
                    analyzeConfirmPassword(
                      text,
                      confirmPassword,
                      confirmPasswordStatus
                    );
                  }}
                  placeholder={"confirm password"}
                  fieldStatus={confirmPasswordStatus}
                  secure={true}
                />
              </View>
            </View>
          </View>
        </form>
      ) : (
        <View>
          <Text
            style={[
              tailwind`text-[20px] mx-2 mt-2 mb-4 font-semibold text-[${theme.textColor}]`,
              { fontFamily: "Roboto_700Bold" },
            ]}
          >
            Change Password
          </Text>

          <View style={tailwind`w-full pl-2 mb-6`}>
            <StyledInput
              label="Existing Password"
              onChangeText={(text) => {
                setExistingPassword(text);
              }}
              secure={true}
              placeholder={"existing password"}
            />
          </View>
          <View
            style={tailwind`${Platform.OS === "web" ? "flex-row" : "flex-col"}`}
          >
            <View
              style={tailwind`${
                Platform.OS === "web" ? "w-1/2" : "w-full"
              } pl-2 mb-2`}
            >
              <StyledInput
                label="New Password"
                onChangeText={(text) => {
                  setNewPassword(text);
                  analyzePassword(text, passwordStatus);
                }}
                placeholder={"new password"}
                secure={true}
                fieldStatus={passwordStatus}
              />
            </View>
            <View
              style={tailwind`${
                Platform.OS === "web" ? "w-1/2" : "w-full"
              } pl-2 mb-2`}
            >
              <StyledInput
                label="Confirm New Password"
                onChangeText={(text) => {
                  setConfirmPassword(text);
                  analyzeConfirmPassword(
                    text,
                    confirmPassword,
                    confirmPasswordStatus
                  );
                }}
                placeholder={"confirm password"}
                fieldStatus={confirmPasswordStatus}
                secure={true}
              />
            </View>
          </View>
        </View>
      )}
    </>
  );
};

ChangePasswordForm.propTypes = {
  setExistingPassword: PropTypes.func.isRequired,
  setNewPassword: PropTypes.func.isRequired,
  confirmPassword: PropTypes.string.isRequired,
  setConfirmPassword: PropTypes.func.isRequired,
  passwordStatus: PropTypes.object.isRequired,
  confirmPasswordStatus: PropTypes.object.isRequired,
};

export { ChangePasswordForm };
