import React, { memo, useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { ScrollView, View } from "react-native";
import WebPlayer from "../video-players/hls/WebPlayer";
import tailwind from "twrnc";
import LiveStreamDetails from "./LiveStreamDetails";
import { FILL_COLOUR_1, FILL_COLOUR_4 } from "../base/Constants";
import { SocketContext } from "../private-room/web/SocketProvider";
import SocketChat from "../private-room/shared/SocketChat";
import MiniTrainerProfilePanel from "../profile-screen/MiniTrainerProfilePanel";
import { GiftedChat } from "react-native-gifted-chat";
import { CurrentUserContext, ThemeContext } from "../base/ApplicationContext";

const WebLayout = ({ event }) => {
  // Chat
  const [messages, setMessages] = useState([]);
  const messagesRef = useRef([]);
  const { socketRef } = useContext(SocketContext);
  const { currentUser } = useContext(CurrentUserContext);
  const { theme } = useContext(ThemeContext);
  const roomId = event.userSchedule.eventKey;

  const addNewMessage = (newMessage) => {
    GiftedChat.append(messages, newMessage, true);
    setMessages([...messagesRef.current, newMessage]);
    messagesRef.current.push(newMessage);
  };

  const init = async () => {
    // const devices = await navigator.mediaDevices.enumerateDevices();
    // const audioDevice = devices.find((device) => device.kind === "audiooutput");

    await socketRef.connectSocket();
    socketRef.connectToRoom(
      null,
      roomId,
      currentUser,
      null,
      null,
      null,
      (newMessage) => {
        addNewMessage(newMessage);
      },
      (userId, messageHistory) => {
        if (userId === currentUser.id) {
          messagesRef.current = messageHistory;
          setMessages(messagesRef.current);
        }
      }
    );
  };

  const onSend = (newMessages) => {
    newMessages.forEach(
      (message) => (message.createdAt = message.createdAt.toISOString())
    );
    socketRef.sendMessage(currentUser, roomId, newMessages[0]);
    messagesRef.current.push(newMessages[0]);
    setMessages(messagesRef.current);
  };

  useEffect(() => {
    init();
    return () => {
      socketRef.disconnectCall();
      setMessages([]);
    };
  }, []);

  const { user } = event.userSchedule;

  return (
    <ScrollView style={tailwind`bg-[${theme.backgroundColor}]`}>
      <View
        style={tailwind`flex flex-row h-full w-full p-5 bg-[${theme.backgroundColor}]`}
      >
        <View style={tailwind`w-[60%] mr-1`}>
          <MemoizedWebPlayerWrapper event={event} />
        </View>
        <View style={tailwind`w-[40%]`}>
          <View style={tailwind`flex-row`}>
            <View style={tailwind`w-[50%]`}>
              <LiveStreamDetails event={event} />
            </View>
            <View style={tailwind`w-[50%]`}>
              <View
                style={tailwind`border-t-[1px] border-b-[1px] border-[${FILL_COLOUR_1}] bg-[${FILL_COLOUR_4}]`}
              >
                <MiniTrainerProfilePanel id={user.id} />
              </View>
            </View>
          </View>
          <View>
            <SocketChat event={event} messages={messages} onSend={onSend} />
          </View>
        </View>
      </View>
    </ScrollView>
  );
};

const WebPlayerWrapper = memo(({ event }) => {
  return (
    <>
      <WebPlayer event={event} />
    </>
  );
});
WebPlayerWrapper.displayName = "WebPlayerWrapper";
WebPlayerWrapper.propTypes = {
  event: PropTypes.object.isRequired,
};
const MemoizedWebPlayerWrapper = React.memo(WebPlayerWrapper);

WebLayout.propTypes = {
  event: PropTypes.object.isRequired,
};

export { WebLayout };
