import React, { useRef } from "react";
import PropTypes from "prop-types";
// import BannerCarousel from "./BannerCarousel";
import LiveThumbnailStrip from "./LiveThumbnailStrip";
import OnDemandThumbnailStrip from "./OnDemandThumbnailStrip";
import TrainerThumbnailStrip from "./TrainerThumbnailStrip";
import tailwind from "twrnc";
import { WebScrollContainer } from "./WebScrollContainer";

// import LibraryScrollContainer from "./LibraryScrollContainer";
// import GroupLikeScrollContainer from "./GroupLikeScrollContainer";

const SectionContainer = ({ item }) => {
  const scrollViewRef = useRef(null);
  const sectionType = item.type.id;
  const isDynamic = item.isDynamic;

  switch (sectionType) {
    case 1: //live
      return (
        <WebScrollContainer
          scrollViewRef={scrollViewRef}
          style={tailwind`pt-1 pb-4`}
        >
          <LiveThumbnailStrip section={item} />
        </WebScrollContainer>
      );
    case 2: //trainers
      return (
        <WebScrollContainer style={tailwind`pb-4`}>
          <TrainerThumbnailStrip section={item} />
        </WebScrollContainer>
      );
    case 6:
    default: //vod
      return (
        <WebScrollContainer
          scrollViewRef={scrollViewRef}
          style={tailwind`pb-4`}
        >
          {/* <ScrollView
            ref={scrollViewRef}
            horizontal
            showsHorizontalScrollIndicator={showHorizontalScrollIndicator}
            style={tailwind`py-1`}
          > */}
          <OnDemandThumbnailStrip section={item} isDynamic={isDynamic} />
          {/* </ScrollView> */}
        </WebScrollContainer>
      );
  }
};
// { {title === "banners" && <BannerCarousel />}
// {title === "trainers" && <TrainerScrollContainer />}
// {title === "live" && <LiveScrollContainer />}
// {title === "on-demand" && <OnDemandScrollContainer />}
// {title === "library" && <LibraryScrollContainer />}
// {title === "group-live" && <LiveScrollContainer />}
// {title === "group-like" && <GroupLikeScrollContainer />} */}

SectionContainer.propTypes = {
  item: PropTypes.object.isRequired,
};

export default SectionContainer;
