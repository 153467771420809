import React, { useContext } from "react";
import PropTypes from "prop-types";
import {
  useQuery,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import tailwind from "twrnc";
import axios from "axios";
import {
  ActivityIndicator,
  ScrollView,
  Text,
  Pressable,
  View,
  Platform,
} from "react-native";
import { Image } from "@rneui/themed";
import {
  API_URL,
  CONNECTION_STATUS_CONNECTED,
  DEFAULT_PROFILE_URL,
} from "../base/Constants";
import { CurrentUserContext, ThemeContext } from "../base/ApplicationContext";
import {
  Roboto_400Regular,
  Roboto_700Bold,
  useFonts,
} from "@expo-google-fonts/roboto";

const Attendees = ({ attendees, setAttendees }) => {
  const queryClient = new QueryClient();
  const { currentUser } = useContext(CurrentUserContext);
  const { theme } = useContext(ThemeContext);

  let fonts = useFonts({ Roboto_700Bold, Roboto_400Regular });
  if (!fonts) return;

  return (
    <QueryClientProvider client={queryClient}>
      <View style={tailwind`flex-row`}>
        <View style={tailwind`w-1/2`}>
          <Text
            style={[
              tailwind`self-center text-[${theme.textColor}] font-semibold mb-2`,
              { fontFamily: "Roboto_700Bold" },
            ]}
          >
            Connections
          </Text>
          <AttendeeList
            id={currentUser.id}
            selectedAttendees={attendees}
            onPress={(newAttendee) => {
              let newAttendees = attendees;
              if (
                newAttendees.filter(
                  (attendee) => attendee.id === newAttendee.id
                ).length
              ) {
                newAttendees = attendees.filter(
                  (attendee) => attendee.id !== newAttendee.id
                );
              } else {
                newAttendees.push({
                  id: newAttendee.id,
                  profileUrl: newAttendee.profileUrl || DEFAULT_PROFILE_URL,
                  name: newAttendee.firstName + " " + newAttendee.lastName,
                });
              }
              newAttendees = [...new Set(newAttendees)];
              setAttendees(newAttendees);
            }}
          />
        </View>
        <View style={tailwind`w-1/2`}>
          <Text
            style={[
              tailwind`self-center text-[${theme.textColor}] font-semibold mb-2`,
              { fontFamily: "Roboto_700Bold" },
            ]}
          >
            Invited
          </Text>

          <ScrollView
            style={tailwind`mx-4 p-2 rounded-lg bg-[${theme.inviteeListBackgroundColor}] h-[200px]`}
          >
            {attendees.length ? (
              attendees.map((attendee) => {
                return (
                  <View key={attendee.id}>
                    {attendee.id !== currentUser.id ? (
                      <View style={tailwind`m-1`}>
                        <View style={tailwind`flex-row`}>
                          <Image
                            style={tailwind`w-[${
                              Platform.OS === "web" ? "40px" : "35px"
                            }] h-[${
                              Platform.OS === "web" ? "40px" : "35px"
                            }] rounded-full self-center`}
                            source={{
                              uri: attendee.profileUrl || DEFAULT_PROFILE_URL,
                            }}
                            PlaceholderContent={<ActivityIndicator />}
                          />
                          <Text
                            style={[
                              tailwind`flex-wrap text-[${
                                theme.textColor
                              }] text-[${
                                Platform.OS === "web" ? "14px" : "10px"
                              }] font-semibold self-center pl-2`,
                              { fontFamily: "Roboto_400Regular" },
                            ]}
                          >
                            {attendee.name}
                          </Text>
                        </View>
                      </View>
                    ) : null}
                  </View>
                );
              })
            ) : (
              <View style={tailwind`flex-col`}>
                <Text
                  style={[
                    tailwind`flex-wrap text-[${theme.textColor}] text-[${
                      Platform.OS === "web" ? "14px" : "10px"
                    }] font-semibold self-center pl-2`,
                    { fontFamily: "Roboto_400Regular" },
                  ]}
                >
                  No-one - Invite between 1 and 7 connections
                </Text>
              </View>
            )}
          </ScrollView>
        </View>
      </View>
    </QueryClientProvider>
  );
};

Attendees.propTypes = {
  attendees: PropTypes.array.isRequired,
  setAttendees: PropTypes.func.isRequired,
};

function AttendeeList(props) {
  const { currentUser } = useContext(CurrentUserContext);
  const { theme } = useContext(ThemeContext);
  const userId = props.id;
  const selectedAttendees = props.selectedAttendees;
  const onPress = props.onPress;

  const { isLoading, error, data, isFetching } = useQuery(
    ["connected" + userId],
    () =>
      axios
        .get(API_URL + "userconnection/connections/" + userId, {
          headers: { authorization: currentUser.token },
        })
        .then((res) => res.data)
  );

  if (isLoading || isFetching) return <ActivityIndicator />;
  if (error) return <Text>An error has occurred: {error.message}</Text>;

  const connections = data.filter((connection) => {
    return connection.status === CONNECTION_STATUS_CONNECTED;
  });
  const attendees = connections.map((connection) => {
    return connection.sourceUser.id !== currentUser.id
      ? connection.sourceUser
      : connection.destinationUser;
  });
  return (
    <ScrollView
      style={tailwind`mx-4 p-2 rounded-lg bg-[${theme.attendeeListBackgroundColor}] h-[200px]`}
    >
      {attendees.map((attendee) => (
        <View key={attendee.id}>
          {attendee.id !== currentUser.id ? (
            <AttendeeItem
              onPress={() => onPress(attendee)}
              name={attendee.firstName + " " + attendee.lastName}
              attendeeProfileUrl={attendee.profileUrl || DEFAULT_PROFILE_URL}
              selected={
                selectedAttendees.filter(
                  (currentAttendee) => currentAttendee.id === attendee.id
                ).length > 0
              }
            />
          ) : null}
        </View>
      ))}
    </ScrollView>
  );
}

AttendeeList.propTypes = {
  id: PropTypes.number.isRequired,
  selectedAttendees: PropTypes.array.isRequired,
  onPress: PropTypes.func.isRequired,
};

function AttendeeItem({ attendeeProfileUrl, name, selected, onPress }) {
  const { theme } = useContext(ThemeContext);
  return (
    <Pressable onPress={onPress} style={tailwind`m-1`}>
      <View
        style={tailwind`flex-row mx-1 rounded-lg p-1 bg-[${
          selected
            ? theme.attendeeListSelectedBackgroundColor
            : theme.attendeeListBackgroundColor
        }]`}
      >
        <View style={tailwind`flex-row`}>
          <Image
            style={tailwind`w-[${Platform.OS === "web" ? "40px" : "35px"}] h-[${
              Platform.OS === "web" ? "40px" : "35px"
            }] rounded-full self-center`}
            source={{ uri: attendeeProfileUrl || DEFAULT_PROFILE_URL }}
            PlaceholderContent={<ActivityIndicator />}
          />
          <Text
            style={[
              tailwind`flex-wrap text-[${
                selected ? theme.attendeeListSelectedTextColor : theme.textColor
              }] text-[${
                Platform.OS === "web" ? "14px" : "10px"
              }] font-semibold self-center pl-2`,
              { fontFamily: "Roboto_400Regular" },
            ]}
          >
            {name}
          </Text>
        </View>
      </View>
    </Pressable>
  );
}

AttendeeItem.propTypes = {
  attendeeProfileUrl: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  onPress: PropTypes.func.isRequired,
};

export default Attendees;
