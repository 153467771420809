import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import {
  ActivityIndicator,
  ScrollView,
  Text,
  Pressable,
  View,
  Platform,
} from "react-native";
import tailwind from "twrnc";
import {
  API_URL,
  CONNECTION_STATUS_CONNECTED,
  DEFAULT_PROFILE_URL,
} from "../components/base/Constants";
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";
import axios from "axios";
import { Image } from "@rneui/themed";
import { ChatWindow } from "../components/private-chat/ChatWindow";
import {
  CurrentUserContext,
  ThemeContext,
} from "../components/base/ApplicationContext";
import { Roboto_400Regular, useFonts } from "@expo-google-fonts/roboto";

const queryClient = new QueryClient();

const MessagingScreen = (props) => {
  let { initialConnectionId, initialParams } =
    props && props.route && props.route.params
      ? props.route.params
      : { initialConnectionId: 0, initialParams: {} };

  const { theme } = useContext(ThemeContext);

  let fonts = useFonts({ Roboto_400Regular });

  if (!fonts) return;

  return (
    <View style={tailwind`flex h-full bg-[${theme.backgroundColor}]`}>
      <QueryClientProvider client={queryClient}>
        <MiniConnectionsPanel
          initialConnectionId={initialConnectionId}
          initialParams={initialParams}
        />
      </QueryClientProvider>
    </View>
  );
};

MessagingScreen.propTypes = {
  route: PropTypes.shape({
    params: PropTypes.shape({
      initialConnectionId: PropTypes.number,
      initialParams: PropTypes.shape({
        roomId: PropTypes.string,
        userId: PropTypes.number,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        profileUrl: PropTypes.string,
        userType: PropTypes.number,
      }),
    }),
  }),
};

function MiniConnectionsPanel({ initialConnectionId, initialParams }) {
  const [params, setParams] = useState(initialParams);
  const [selectedConnection, setSelectedConnection] =
    useState(initialConnectionId);
  const { currentUser } = useContext(CurrentUserContext);
  const userId = currentUser.id;
  const { isLoading, error, data } = useQuery(["connections" + userId], () =>
    axios
      .get(API_URL + "userconnection/connections/" + userId, {
        headers: { authorization: currentUser.token },
      })
      .then((res) => res.data)
  );

  if (isLoading) return <ActivityIndicator />;
  if (error) return <Text>An error has occurred: ${error.message}</Text>;

  const formattedData = data.filter((item) => {
    return item.status === CONNECTION_STATUS_CONNECTED;
  });

  //   if (!selectedConnection) {
  //     setSelectedConnection(formattedData[0].id);
  //   }

  return (
    <View style={tailwind`flex-row pt-3`}>
      <View style={tailwind`w-1/4 h-full`}>
        <ScrollView>
          {formattedData.map((item) => {
            const user =
              item.sourceUser.id === userId
                ? item.destinationUser
                : item.sourceUser;
            return (
              <View key={item.id}>
                <ConnectionItem
                  connectionId={item.id}
                  roomId={item.roomId}
                  userId={user.id}
                  firstName={user.firstName}
                  lastName={user.lastName}
                  profileUrl={user.profileUrl}
                  userType={user.type.id}
                  setParams={setParams}
                  selectedConnection={selectedConnection}
                  setSelectedConnection={setSelectedConnection}
                />
              </View>
            );
          })}
        </ScrollView>
      </View>
      <View style={tailwind`w-3/4 h-full`}>
        {params && params.userId && <ChatWindow params={params} />}
      </View>
    </View>
  );
}

MiniConnectionsPanel.propTypes = {
  initialConnectionId: PropTypes.number,
  initialParams: PropTypes.shape({
    roomId: PropTypes.string,
    userId: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    profileUrl: PropTypes.string,
    userType: PropTypes.number,
  }),
};

function ConnectionItem({
  connectionId,
  roomId,
  userId,
  firstName,
  lastName,
  profileUrl,
  userType,
  setParams,
  selectedConnection,
  setSelectedConnection,
}) {
  const { theme } = useContext(ThemeContext);
  const shortName = firstName + " " + lastName;
  return (
    <Pressable
      key={userId}
      onPress={() => {
        setSelectedConnection(connectionId);
        setParams({
          roomId: roomId,
          userId: userId,
          firstName: firstName,
          lastName: lastName,
          profileUrl: profileUrl,
          userType: userType,
        });
      }}
    >
      <View
        style={tailwind`self-start w-full p-2 ${
          Platform.OS === "web" ? "flex-row" : "flex-col"
        } ${
          selectedConnection === connectionId
            ? "bg-[" + theme.chatConnectionActiveBackgroundColor + "]"
            : "bg-[" + theme.backgroundColor + "]"
        }`}
      >
        <View style={tailwind`self-center`}>
          <Image
            style={tailwind`w-[30px] h-[30px] rounded-full self-center`}
            source={{ uri: profileUrl || DEFAULT_PROFILE_URL }}
            PlaceholderContent={<ActivityIndicator />}
          />
        </View>
        <View style={tailwind`self-center`}>
          <Text
            style={[
              tailwind`font-semibold text-[${
                selectedConnection === connectionId
                  ? theme.chatConnectionActiveTextColor
                  : theme.textColor
              }] text-[14px] ml-2`,
              { fontFamily: "Roboto_400Regular" },
            ]}
          >
            {shortName}
          </Text>
        </View>
      </View>
    </Pressable>
  );
}

ConnectionItem.propTypes = {
  connectionId: PropTypes.number.isRequired,
  roomId: PropTypes.string.isRequired,
  userId: PropTypes.number.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  profileUrl: PropTypes.string,
  userType: PropTypes.number.isRequired,
  setParams: PropTypes.func.isRequired,
  selectedConnection: PropTypes.number,
  setSelectedConnection: PropTypes.func.isRequired,
};

export { MessagingScreen };
