import React, { useContext } from "react";
import PropTypes from "prop-types";
import LiveThumbnail from "./LiveThumbnail";
import {
  useQuery,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import axios from "axios";
import { ActivityIndicator, Text } from "react-native";
import { API_URL } from "../base/Constants";
import { CurrentUserContext } from "../base/ApplicationContext";

const queryClient = new QueryClient();

const LiveThumbnailStrip = ({ section }) => {
  const { currentUser } = useContext(CurrentUserContext);
  return (
    <QueryClientProvider client={queryClient}>
      <LiveStrip sectionId={section.id} userId={currentUser.id} />
    </QueryClientProvider>
  );
};

LiveThumbnailStrip.propTypes = {
  section: PropTypes.object.isRequired,
};

function LiveStrip({ sectionId, userId }) {
  const { currentUser } = useContext(CurrentUserContext);
  const { isLoading, error, data } = useQuery(["section" + sectionId], () =>
    axios
      .get(API_URL + "homepage/section/" + sectionId + "/" + userId, {
        headers: { authorization: currentUser.token },
      })
      .then((res) => res.data)
  );

  if (isLoading) return <ActivityIndicator />;
  if (error) return <Text>{"An error has occurred:" + error.message}</Text>;

  return (
    <>
      {data.map((liveEvent) => (
        <LiveThumbnail key={liveEvent.viewId} event={liveEvent} />
      ))}
    </>
  );
}

LiveStrip.propTypes = {
  sectionId: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
};

export default LiveThumbnailStrip;
