import React, { useContext } from "react";
import PropTypes from "prop-types";
import { View, ScrollView, Platform } from "react-native";
import OnDemandDetails from "./OnDemandDetails";
import tailwind from "twrnc";
import ExpoPlayer from "../video-players/vod/ExpoPlayer";
import { TrainerVideos } from "../profile-screen/TrainerVideos";
import MiniTrainerProfilePanel from "../profile-screen/MiniTrainerProfilePanel";
import { ThemeContext } from "../base/ApplicationContext";

const PortraitLayout = ({ video }) => {
  const trainerId = video.video.userVideos[0].userId.id;
  const firstName = video.video.userVideos[0].userId.firstName;
  const { theme } = useContext(ThemeContext);
  return (
    <View style={tailwind`bg-[${theme.backgroundColor}]`}>
      <View style={tailwind`self-center`}>
        {Platform.OS === "ios" ? (
          <ExpoPlayer video={video} />
        ) : (
          <ExpoPlayer video={video} />
        )}
      </View>
      <View style={tailwind`${Platform.OS === "ios" ? "h-1/4" : "h-1/3"}`}>
        <ScrollView>
          <OnDemandDetails video={video} />
          <MiniTrainerProfilePanel id={trainerId} />
        </ScrollView>
      </View>

      <View style={tailwind`h-[200px]`}>
        <ScrollView horizontal style={tailwind`bg-[${theme.backgroundColor}]`}>
          <TrainerVideos id={trainerId} firstName={firstName} />
        </ScrollView>
      </View>
    </View>
  );
};

PortraitLayout.propTypes = {
  video: PropTypes.object.isRequired,
};

export default PortraitLayout;
