import React, { useContext } from "react";
import PropTypes from "prop-types";
import {
  useFonts,
  Roboto_700Bold,
  Roboto_400Regular,
} from "@expo-google-fonts/roboto";
import { Inter_400Regular, Inter_500Medium } from "@expo-google-fonts/inter";
import { Pressable, Text, View } from "react-native";
import tailwind from "twrnc";
import {
  NavigationSyncContext,
  ThemeContext,
} from "../base/ApplicationContext";
import { Image } from "@rneui/themed";

const PersonalTrainingCostsPanel = ({
  id,
  firstName,
  shortName,
  data,
  isProfileOwner,
  navigation,
}) => {
  const { theme } = useContext(ThemeContext);
  const { setFocusedScreen } = useContext(NavigationSyncContext);

  let [fontsLoaded] = useFonts({
    Roboto_700Bold,
    Roboto_400Regular,
    Inter_400Regular,
    Inter_500Medium,
  });

  if (!fontsLoaded) {
    return null;
  }

  if (!data.currency) return null;

  const handleProviderCalendarPress = () => {
    if (isProfileOwner) {
      setFocusedScreen("CalendarScreen");
      navigation.navigate("CalendarScreen");
    } else {
      setFocusedScreen("ProviderCalendarScreen");
      navigation.navigate("ProviderCalendarScreen", {
        providerId: id,
        providerShortName: shortName,
      });
    }
  };

  return (
    <View style={tailwind`self-center w-full max-w-[800px] mt-4 px-4`}>
      <Text
        style={[
          tailwind`capitalize text-[${theme.textColor}] font-bold text-[18px] mt-3 mb-4`,
          { fontFamily: "Roboto_700Bold" },
        ]}
      >
        Personal Training costs for {firstName}
      </Text>
      <View style={tailwind`flex-row`}>
        <Text
          style={[
            tailwind`text-[${theme.textColor}] w-1/2 font-bold`,
            { fontFamily: "Inter_500Medium" },
          ]}
        >
          Price per session
        </Text>
        <Text
          style={[
            tailwind`text-[${theme.textColor}] w-1/2 font-semibold`,
            { fontFamily: "Inter_400Regular" },
          ]}
        >
          {data.currency.symbol}
          {data.baseCost.toFixed(2)}
        </Text>
      </View>
      <View style={tailwind`flex-row`}>
        <Text
          style={[
            tailwind`text-[${theme.textColor}] w-1/2 font-bold`,
            { fontFamily: "Inter_500Medium" },
          ]}
        >
          Session duration
        </Text>
        <Text
          style={[
            tailwind`text-[${theme.textColor}] w-1/2 font-semibold`,
            { fontFamily: "Inter_400Regular" },
          ]}
        >
          {data.classDuration} minutes
        </Text>
      </View>
      {data.sessionBundles.length ? (
        <>
          <Text
            style={[
              tailwind`self-center text-[${theme.textColor}] font-semibold mt-4 mb-2`,
              { fontFamily: "Roboto_700Bold" },
            ]}
          >
            Discount bundles
          </Text>
          <View>
            {data.sessionBundles.map((bundleItem) => {
              let calculatedNetTotal = 0;
              let calculatedNetTotalWithDiscount = 0;

              switch (bundleItem.discountType.id) {
                case 1:
                  calculatedNetTotal = data.baseCost * bundleItem.sessions;
                  calculatedNetTotalWithDiscount =
                    calculatedNetTotal -
                    (bundleItem.discountAmount / 100) * calculatedNetTotal;
                  break;
                case 2:
                default:
                  calculatedNetTotal = data.baseCost * bundleItem.sessions;
                  calculatedNetTotalWithDiscount =
                    calculatedNetTotal - bundleItem.discountAmount;
                  break;
              }

              let serviceFee = 1.5 * bundleItem.sessions;
              let calculatedNetTotalWithDiscountAndFee =
                calculatedNetTotalWithDiscount + serviceFee;
              let vatTotal = (20 / 100) * calculatedNetTotalWithDiscountAndFee;
              let grandTotal = Number(
                calculatedNetTotalWithDiscountAndFee + vatTotal
              );

              calculatedNetTotalWithDiscountAndFee =
                calculatedNetTotalWithDiscountAndFee.toFixed(2);
              serviceFee = serviceFee.toFixed(2);
              vatTotal = vatTotal.toFixed(2);
              grandTotal = grandTotal.toFixed(2);

              return (
                <View key={bundleItem.id} style={tailwind`flex-row`}>
                  <Text
                    style={[
                      tailwind`text-[${theme.textColor}] w-2/5`,
                      { fontFamily: "Inter_400Regular" },
                    ]}
                  >
                    {bundleItem.sessions} sessions
                  </Text>
                  <Text
                    style={[
                      tailwind`text-[${theme.textColor}] w-2/5`,
                      { fontFamily: "Inter_400Regular" },
                    ]}
                  >
                    {bundleItem.discountType.id === 2 && data.currency.symbol}
                    {bundleItem.discountAmount.toFixed(2)}
                    {bundleItem.discountType.id === 1 && "%"} Discount
                  </Text>
                  <Text
                    style={[
                      tailwind`text-[${theme.textColor}] font-semibold w-1/5`,
                      { fontFamily: "Inter_400Regular" },
                    ]}
                  >
                    {data.currency.symbol}
                    {grandTotal}
                  </Text>
                </View>
              );
            })}
          </View>
        </>
      ) : null}
      {!isProfileOwner ? (
        <View style={tailwind`mt-4 p-2`}>
          <Text
            style={[
              tailwind`text-[${theme.textColor}] font-semibold mb-4 self-center`,
              { fontFamily: "Roboto_400Regular" },
            ]}
          >
            Book a personal training session with {firstName}
          </Text>
          <Pressable
            onPress={handleProviderCalendarPress}
            style={tailwind`self-center`}
          >
            <View
              style={tailwind`border-[1px] border-[${theme.calendarAddIconBorderColor}] bg-[${theme.backgroundColor}] rounded-full p-3`}
            >
              <Image
                resizeMode="contain"
                style={tailwind`h-[18px] w-[18px]`}
                source={require("../../assets/calendar-plus-icon.png")}
              />
            </View>
          </Pressable>
        </View>
      ) : null}
    </View>
  );
};

PersonalTrainingCostsPanel.propTypes = {
  id: PropTypes.number.isRequired,
  firstName: PropTypes.string.isRequired,
  shortName: PropTypes.string.isRequired,
  isProfileOwner: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    baseCost: PropTypes.number.isRequired,
    classDuration: PropTypes.number.isRequired,
    currency: PropTypes.shape({
      symbol: PropTypes.string.isRequired,
    }),
    sessionBundles: PropTypes.array,
  }).isRequired,
  navigation: PropTypes.shape({
    navigate: PropTypes.func.isRequired,
  }),
};

export { PersonalTrainingCostsPanel as default };
