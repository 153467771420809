import { useContext } from "react";
import React, { Text, Pressable, View } from "react-native";
import PropTypes from "prop-types";
import {
  useFonts,
  Roboto_700Bold,
  Roboto_400Regular,
} from "@expo-google-fonts/roboto";
import tailwind from "twrnc";
import { ThemeContext } from "../base/ApplicationContext";

const CalendarDayHeading = ({ label, onPress, dayNumber, selected }) => {
  const { theme } = useContext(ThemeContext);
  let [fontsLoaded] = useFonts({ Roboto_700Bold, Roboto_400Regular });
  if (!fontsLoaded) return null;

  return (
    <View>
      <Pressable style={tailwind`py-4 px-2`} onPress={onPress}>
        {selected ? (
          <>
            <View
              style={tailwind`p-4 rounded-full bg-[${theme.calendarDayHeadingBackgroundColor}] border-[${theme.calendarDayHeadingBorderColor}]`}
            >
              <Text
                style={[
                  tailwind`text-[${theme.calendarDayHeadingTextColor}] self-center`,
                  { fontFamily: "Roboto_700Bold" },
                ]}
              >
                {dayNumber}
              </Text>
            </View>
            <View style={tailwind`px-2 mt-2`}>
              <Text
                style={[
                  tailwind`text-[${theme.textColor}] uppercase self-center`,
                  { fontFamily: "Roboto_400Regular" },
                ]}
              >
                {label}
              </Text>
            </View>
          </>
        ) : (
          <>
            <View
              style={tailwind`p-4 rounded-full bg-[${theme.calendarDayHeadingBackgroundInactiveColor}] border-[#9CA0AF]`}
            >
              <Text
                style={[
                  tailwind`text-[${theme.calendarDayHeadingTextInactiveColor}] self-center`,
                  { fontFamily: "Roboto_400Regular" },
                ]}
              >
                {dayNumber}
              </Text>
            </View>
            <View style={tailwind`px-2 mt-2`}>
              <Text
                style={[
                  tailwind`text-[${theme.calendarDayHeadingTextInactiveColor}] uppercase self-center`,
                  { fontFamily: "Roboto_400Regular" },
                ]}
              >
                {label}
              </Text>
            </View>
          </>
        )}
      </Pressable>
    </View>
  );
};

CalendarDayHeading.propTypes = {
  label: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
  dayNumber: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
};

export default CalendarDayHeading;
