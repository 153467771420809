import React, { useContext } from "react";
import { View } from "react-native";
import { ThemeContext } from "./ApplicationContext";

const HorizontalRule = () => {
  const { theme } = useContext(ThemeContext);
  return (
    <View
      style={{
        marginTop: 5,
        marginBottom: 8,
        borderTopColor: theme.horizontalRuleColor,
        borderTopWidth: 1,
        resizeMode: "contain",
        alignSelf: "center",
        width: "90%",
      }}
    ></View>
  );
};

export default HorizontalRule;
