import React, { useContext, useState } from "react";
import { FlatList, Text, Pressable, View } from "react-native";
import tailwind from "twrnc";
import {
  API_URL,
  USER_TYPE_AMBASSADOR,
  USER_TYPE_PROVIDER,
} from "../components/base/Constants";
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";
import axios from "axios";
import { ActivityIndicator } from "react-native";
import Button from "../components/base/Button";
import { useNavigation } from "@react-navigation/native";
import {
  CurrentUserContext,
  NavigationSyncContext,
  ThemeContext,
} from "../components/base/ApplicationContext";

const queryClient = new QueryClient();

const BundleScreen = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <BundleOptions />
    </QueryClientProvider>
  );
};

function BundleOptions() {
  const navigation = useNavigation();
  const { currentUser } = useContext(CurrentUserContext);
  const { theme } = useContext(ThemeContext);
  const { setFocusedScreen } = useContext(NavigationSyncContext);
  const currencySymbol = currentUser.currencySymbol;
  const [selectedBundle, setSelectedBundle] = useState();

  if (!currentUser.isProfileCompleted) {
    const userId = currentUser.id;
    const userType = currentUser.type;

    return (
      <View
        style={tailwind`flex-col w-full h-full bg-[${theme.backgroundColor}]`}
      >
        <View style={tailwind` self-center`}>
          <Text
            style={tailwind`self-center text-[${theme.textColor}] font-semibold mb-4`}
          >
            {currentUser.type === USER_TYPE_PROVIDER ||
            currentUser.type === USER_TYPE_AMBASSADOR
              ? "Please complete your personal training charges in your profile to purchase credits"
              : "Please complete your billing settings in your profile to purchase credits"}
          </Text>
          <View style={tailwind`self-center`}>
            <Button
              title="Profile"
              onPress={() => {
                setFocusedScreen("EditProfileScreen");
                navigation.navigate("EditProfileScreen", {
                  userId: userId,
                  userType: userType,
                });
              }}
            />
          </View>
        </View>
      </View>
    );
  } else {
    const { isLoading, error, data } = useQuery(["bundles"], () =>
      axios
        .get(API_URL + "bundles/" + currentUser.currencyId, {
          cacheTime: 0,
          headers: { authorization: currentUser.token },
        })
        .then((res) => {
          return res.data;
        })
    );

    if (isLoading) return <ActivityIndicator />;
    if (error) return <Text>An error has occurred: ${error.message}</Text>;

    return (
      <View
        style={tailwind`flex-col w-full h-full bg-[${theme.backgroundColor}]`}
      >
        <View style={tailwind` self-center`}>
          <Text
            style={tailwind`self-center text-[${theme.textColor}] font-semibold mb-4`}
          >
            Choose a bundle
          </Text>
          <FlatList
            keyExtractor={(item) => item.id}
            numColumns={1}
            scrollEnabled={false}
            data={data}
            snapToEnd={true}
            renderItem={({ item }) => {
              const subscriptionSpacer = item.credits === -1 ? 25 : 0;
              const bgColor =
                selectedBundle === item.id
                  ? theme.bundleItemSelectedBackgroundColor
                  : theme.bundleItemBackgroundColor;
              return (
                <View style={tailwind`mt-[${subscriptionSpacer}]`}>
                  {item.credits === -1 ? (
                    <Text
                      style={tailwind`self-center text-[${theme.textColor}] font-semibold mb-4`}
                    >
                      Or go unlimited with a monthly subscription
                    </Text>
                  ) : null}
                  <Pressable
                    style={tailwind`rounded-lg mx-1 p-3 bg-[${bgColor}] w-full self-center mb-2`}
                    onPress={() => setSelectedBundle(item.id)}
                  >
                    <View key={item.id} style={tailwind`flex-row`}>
                      <Text
                        style={tailwind`text-[${theme.textColor}] grow mr-4`}
                      >
                        {item.credits === -1 ? "Unlimited" : item.credits}
                        {item.credits === -1
                          ? ""
                          : item.credits === 1
                          ? " Credit"
                          : " Credits"}
                      </Text>
                      <Text
                        style={tailwind`text-[${theme.textColor}] self-end`}
                      >
                        {currencySymbol}
                        {item.price.toFixed(2)}
                      </Text>
                    </View>
                  </Pressable>
                </View>
              );
            }}
          />
          <View style={tailwind`self-center mb-4 mt-10`}>
            <Button
              title="Buy"
              onPress={() => {
                setFocusedScreen("OrderSummaryScreen");
                navigation.navigate("OrderSummaryScreen", {
                  bundleId: selectedBundle,
                  bundles: data,
                });
              }}
              disabled={!selectedBundle}
            />
          </View>
        </View>
      </View>
    );
  }
}

export { BundleScreen };
