import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  DrawerContentScrollView,
  DrawerItem,
  createDrawerNavigator,
} from "@react-navigation/drawer";
import HomeScreen from "../../screens/HomeScreen";
import NetworkScreen from "../../screens/NetworkScreen";
import OnDemandScreen from "../../screens/OnDemandScreen";
import YoutubeScreen from "../../screens/YoutubeScreen";
import ViewProfileScreen from "../../screens/ViewProfileScreen";
import GroupCall from "../../screens/GroupCall";
import { Platform, View } from "react-native";
import CalendarScreen from "../../screens/CalendarScreen";
import NewEventScreen from "../../screens/NewEventScreen";
import EditEventScreen from "../../screens/EditEventScreen";
import NewChat3 from "../../screens/NewChat3";
import {
  FILL_COLOUR_1,
  USER_TYPE_AMBASSADOR,
  USER_TYPE_PROVIDER,
} from "../base/Constants";
import LoginScreen from "../../screens/LoginScreen";
import RegisterUserScreen from "../../screens/RegisterUserScreen";
import { EditProfileScreen } from "../../screens/EditProfileScreen";
import { MessagingScreen } from "../../screens/MessagingScreen";
import LibraryScreen from "../../screens/LibraryScreen";
import { NotificationScreen } from "../../screens/NotificationsScreen";
import { BundleScreen } from "../../screens/BundleScreen";
import Broadcast3Screen from "../../screens/Broadcast3Screen";
import { OrderSummaryScreen } from "../../screens/OrderSummaryScreen";
import ViewLiveScreen from "../../screens/ViewLiveScreen";
import { Text } from "react-native";
import tailwind from "twrnc";
import { Image } from "@rneui/themed";
import ProviderCalendarScreen from "../../screens/ProviderCalendarScreen";
import SearchResultsScreen from "../../screens/SearchResultsScreen";
import {
  CurrentUserContext,
  NavigationSyncContext,
  ThemeContext,
} from "../base/ApplicationContext";
import Header from "../base/Header";
import SettingsScreen from "../../screens/SettingsScreen";

const NavigationDrawers = () => {
  const RootStack = createDrawerNavigator();
  const {
    currentUser,
    outstandingConnectionCount,
    outstandingNotificationCount,
    outstandingMessageCount,
    // isProfileComplete,
  } = useContext(CurrentUserContext);
  const { theme } = useContext(ThemeContext);

  const userId = (currentUser && currentUser.id) || null;
  const userType = (currentUser && currentUser.type) || null;
  const title = (text) =>
    Platform.select({ web: `FitCentr - ${text}`, default: text });

  const [ytRefreshKey, setYtRefreshKey] = useState();
  useEffect(() => {
    setYtRefreshKey(0);
  }, []);

  let screenOptions = {
    drawerStyle: {
      backgroundColor: theme.backgroundColor,
      borderRightWidth: 1,
      width: 112,
    },
    drawerLabelStyle: {
      color: theme.textColor,
    },
    drawerActiveTintColor: "#000",
  };

  if (Platform.OS === "web" && currentUser.authenticated) {
    screenOptions = {
      animationEnabled: true,
      drawerStyle: {
        backgroundColor: theme.navigationDrawerBackgroundColor,
        width: 112,
        borderRightWidth: 0,
      },
      drawerLabelStyle: {
        color: theme.textColor,
      },
      drawerActiveTintColor: "#000",
      drawerType: "permanent",
    };
  }

  return (
    <RootStack.Navigator
      screenOptions={screenOptions}
      drawerContent={(props) => <CustomDrawerContent {...props} />}
    >
      {!currentUser || !currentUser.authenticated ? (
        <>
          <RootStack.Group>
            <RootStack.Screen
              name="LoginScreen"
              component={LoginScreen}
              options={{
                headerShown: false,
                orientation: "all",
                drawerLabel: "Login",
                unmountOnBlur: true,
                title: title("Login"),
              }}
            />
            <RootStack.Screen
              name="RegisterUserScreen"
              component={RegisterUserScreen}
              options={{
                headerShown: false,
                orientation: "all",
                drawerLabel: "Register User",
                unmountOnBlur: true,
                drawerItemStyle: { display: "none" },
                title: title("Register"),
              }}
            />
          </RootStack.Group>
        </>
      ) : (
        <>
          <RootStack.Group>
            {userType === USER_TYPE_PROVIDER ||
            userType === USER_TYPE_AMBASSADOR ? (
              <>
                <RootStack.Screen
                  name="HomeScreen"
                  component={HomeScreen}
                  options={{
                    headerShown: true,
                    orientation: "all",
                    drawerLabel: "Home",
                    unmountOnBlur: true,
                    title: title("Home"),
                    header: () => {
                      return <Header />;
                    },
                  }}
                />
                {Platform.OS === "web" ? (
                  <RootStack.Screen
                    name="CalendarScreen"
                    component={CalendarScreen}
                    options={{
                      headerShown: false,
                      orientation: "all",
                      drawerLabel: "Schedule",
                      unmountOnBlur: true,
                      title: title("Schedule"),
                    }}
                  />
                ) : (
                  <RootStack.Screen
                    name="CalendarScreen"
                    component={CalendarScreen}
                    options={{
                      headerShown: true,
                      orientation: "all",
                      drawerLabel: "Schedule",
                      unmountOnBlur: true,
                      title: title("Schedule"),
                      header: () => {
                        return <Header />;
                      },
                    }}
                  />
                )}
              </>
            ) : (
              <>
                <RootStack.Screen
                  name="HomeScreen"
                  component={HomeScreen}
                  options={{
                    headerMode: "screen",
                    headerShown: true,
                    orientation: "all",
                    drawerLabel: "Home",
                    unmountOnBlur: true,
                    title: title("Home"),
                    header: () => {
                      return <Header />;
                    },
                  }}
                />
                {Platform.OS === "web" ? (
                  <RootStack.Screen
                    name="CalendarScreen"
                    component={CalendarScreen}
                    options={{
                      headerShown: false,
                      orientation: "all",
                      drawerLabel: "Schedule",
                      unmountOnBlur: true,
                      title: title("Schedule"),
                    }}
                  />
                ) : (
                  <RootStack.Screen
                    name="CalendarScreen"
                    component={CalendarScreen}
                    options={{
                      headerShown: true,
                      orientation: "all",
                      drawerLabel: "Schedule",
                      unmountOnBlur: true,
                      title: title("Schedule"),
                      header: () => {
                        return <Header />;
                      },
                    }}
                  />
                )}
              </>
            )}
            <RootStack.Screen
              name="LibraryScreen"
              component={LibraryScreen}
              options={{
                headerShown: true,
                orientation: "all",
                drawerLabel: "Library",
                unmountOnBlur: true,
                title: title("Library"),
                header: () => {
                  return <Header />;
                },
              }}
            />
            <RootStack.Screen
              name="MessagingScreen"
              component={MessagingScreen}
              initialParams={{ initialConnectionId: 0, initialParams: {} }}
              options={{
                headerShown: true,
                orientation: "all",
                drawerLabel: () => (
                  <View>
                    {Number(outstandingMessageCount) > 0 ? (
                      <View
                        style={tailwind`absolute top--3 right-1 rounded-full bg-[${FILL_COLOUR_1}] w-[20px] h-[20px]`}
                      >
                        <Text
                          style={tailwind`text-[8px] self-center pt-[6px] text-white`}
                        >
                          {outstandingMessageCount}
                        </Text>
                      </View>
                    ) : null}
                    <Text
                      style={tailwind`text-[${theme.textColor}] font-semibold`}
                    >
                      Messages
                    </Text>
                  </View>
                ),
                unmountOnBlur: true,
                title: title("Messages"),
                header: () => {
                  return <Header />;
                },
              }}
            />
            <RootStack.Screen
              name="NetworkScreen"
              component={NetworkScreen}
              options={{
                headerShown: true,
                orientation: "all",
                drawerLabel: () => (
                  <View>
                    {Number(outstandingConnectionCount) > 0 ? (
                      <View
                        style={tailwind`absolute top--3 right-1 rounded-full bg-[${FILL_COLOUR_1}] w-[20px] h-[20px]`}
                      >
                        <Text
                          style={tailwind`text-[8px] self-center pt-[6px] text-white`}
                        >
                          {outstandingConnectionCount}
                        </Text>
                      </View>
                    ) : null}
                    <Text
                      style={tailwind`text-[${theme.textColor}] font-semibold`}
                    >
                      Connections
                    </Text>
                  </View>
                ),
                unmountOnBlur: true,
                title: title("Connections"),
                header: () => {
                  return <Header />;
                },
              }}
            />
            <RootStack.Screen
              name="NotificationsScreen"
              component={NotificationScreen}
              options={{
                headerShown: true,
                orientation: "all",
                drawerLabel: () => (
                  <View>
                    {Number(outstandingNotificationCount) > 0 ? (
                      <View
                        style={tailwind`absolute top--3 right-1 rounded-full bg-[${FILL_COLOUR_1}] w-[20px] h-[20px]`}
                      >
                        <Text
                          style={tailwind`text-[8px] self-center pt-[6px] text-white`}
                        >
                          {outstandingNotificationCount}
                        </Text>
                      </View>
                    ) : null}
                    <Text
                      style={tailwind`text-[${theme.textColor}] font-semibold`}
                    >
                      Notifications
                    </Text>
                  </View>
                ),
                unmountOnBlur: true,
                title: title("Notifications"),
                header: () => {
                  return <Header />;
                },
              }}
            />
            <RootStack.Screen
              name="BundleScreen"
              component={BundleScreen}
              options={{
                unmountOnBlur: true,
                headerShown: true,
                header: () => {
                  return <Header />;
                },
                orientation: "all",
                drawerLabel: "Buy Credits",
                title: title("Buy Credits"),
              }}
            />
            <RootStack.Screen
              name="LogoutScreen"
              component={LogoutButton}
              options={{
                headerShown: false,
                orientation: "all",
                drawerLabel: "Logout",
                title: title("Logout"),
              }}
            ></RootStack.Screen>
            {userType === USER_TYPE_PROVIDER ? (
              <RootStack.Screen
                name="GroupCall"
                component={GroupCall}
                options={{
                  headerShown: false,
                  orientation: "all",
                  drawerLabel: "Group Call",
                  unmountOnBlur: true,
                  drawerItemStyle: { display: "none" },
                  title: title("Group Call"),
                }}
              />
            ) : null}
            {userType === USER_TYPE_AMBASSADOR ? (
              <>
                <RootStack.Screen
                  name="Broadcast3Screen"
                  component={Broadcast3Screen}
                  options={{
                    headerShown: false,
                    orientation: "all",
                    drawerLabel: "Broadcast3",
                    unmountOnBlur: true,
                    drawerItemStyle: { display: "none" },
                    title: title("Live Broadcast"),
                  }}
                />
                <RootStack.Screen
                  name="GroupCall"
                  component={GroupCall}
                  options={{
                    headerShown: false,
                    orientation: "all",
                    drawerLabel: "Group Call",
                    unmountOnBlur: true,
                    drawerItemStyle: { display: "none" },
                    title: title("Group Call"),
                  }}
                />
              </>
            ) : null}
          </RootStack.Group>
          <RootStack.Group>
            <RootStack.Screen
              name="OrderSummaryScreen"
              component={OrderSummaryScreen}
              options={{
                headerShown: true,
                header: () => {
                  return <Header />;
                },
                orientation: "all",
                drawerItemStyle: { display: "none" },
                unmountOnBlur: true,
                title: title("Order Summary"),
              }}
            />
            <RootStack.Screen
              name="ViewProfileScreen"
              component={ViewProfileScreen}
              options={{
                headerShown: true,
                header: () => {
                  return <Header />;
                },
                orientation: "all",
                drawerItemStyle: { display: "none" },
                unmountOnBlur: true,
                title: title("Profile"),
              }}
            />
            {Platform.OS === "web" ? (
              <RootStack.Screen
                name="ProviderCalendarScreen"
                component={ProviderCalendarScreen}
                options={{
                  headerShown: false,
                  orientation: "all",
                  drawerItemStyle: { display: "none" },
                  unmountOnBlur: true,
                  title: title("Provider Schedule"),
                }}
              />
            ) : (
              <RootStack.Screen
                name="ProviderCalendarScreen"
                component={ProviderCalendarScreen}
                options={{
                  headerShown: true,
                  header: () => {
                    return <Header />;
                  },
                  orientation: "all",
                  drawerItemStyle: { display: "none" },
                  unmountOnBlur: true,
                  title: title("Provider Schedule"),
                }}
              />
            )}
            <RootStack.Screen
              name="SearchResultsScreen"
              component={SearchResultsScreen}
              options={{
                headerShown: true,
                orientation: "all",
                drawerItemStyle: { display: "none" },
                unmountOnBlur: true,
                title: title("Search Results"),
                header: () => {
                  return <Header />;
                },
              }}
            />
            <RootStack.Screen
              name="SettingsScreen"
              component={SettingsScreen}
              options={{
                headerShown: true,
                orientation: "all",
                drawerItemStyle: { display: "none" },
                unmountOnBlur: true,
                title: title("Options"),
                header: () => {
                  return <Header />;
                },
              }}
            />
            <RootStack.Screen
              name="EditProfileScreen"
              component={EditProfileScreen}
              initialParams={{ id: userId, type: userType }}
              options={{
                headerShown: true,
                header: () => {
                  return <Header />;
                },
                orientation: "all",
                drawerItemStyle: { display: "none" },
                unmountOnBlur: true,
                title: title("Edit Profile"),
              }}
            />
            <RootStack.Screen
              name="NewEventScreen"
              component={NewEventScreen}
              options={{
                headerShown: true,
                header: () => {
                  return <Header />;
                },
                orientation: "all",
                drawerItemStyle: { display: "none" },
                unmountOnBlur: true,
                title: title("Create Event"),
              }}
            />
            <RootStack.Screen
              name="EditEventScreen"
              component={EditEventScreen}
              options={{
                headerShown: true,
                header: () => {
                  return <Header />;
                },
                orientation: "all",
                drawerItemStyle: { display: "none" },
                unmountOnBlur: true,
                title: title("Edit Event"),
              }}
            />
            <RootStack.Screen
              name="ViewLiveScreen"
              component={ViewLiveScreen}
              options={{
                headerShown: true,
                header: () => {
                  return <Header />;
                },
                orientation: "all",
                drawerItemStyle: { display: "none" },
                unmountOnBlur: true,
                title: title("Live Broadcast"),
              }}
            />
            <RootStack.Screen
              name="OnDemandScreen"
              component={OnDemandScreen}
              options={{
                headerShown: true,
                header: () => {
                  return <Header />;
                },
                orientation: "all",
                drawerItemStyle: { display: "none" },
                unmountOnBlur: true,
                title: title("On Demand"),
              }}
            />
            <RootStack.Screen
              name="YoutubeScreen"
              key={ytRefreshKey}
              component={YoutubeScreen}
              options={{
                headerShown: true,
                header: () => {
                  return <Header />;
                },
                drawerIcon: () => null,
                drawerItemStyle: { display: "none" },
                unmountOnBlur: true,
                title: title("On Demand"),
              }}
            />
            <RootStack.Screen
              name="NewChat3"
              component={NewChat3}
              options={{
                orientation: "all",
                drawerItemStyle: { display: "none" },
                unmountOnBlur: true,
                title: title("Chat"),
                headerShown: true,
                header: () => {
                  return <Header />;
                },
              }}
            />
          </RootStack.Group>
        </>
      )}
    </RootStack.Navigator>
  );
};

function CustomDrawerContent(props) {
  const { navigation } = props;
  const { currentUser } = useContext(CurrentUserContext);
  const { theme } = useContext(ThemeContext);
  const { focusedScreen, setFocusedScreen } = useContext(NavigationSyncContext);

  return (
    <DrawerContentScrollView
      {...props}
      style={tailwind`bg-[${theme.navigationDrawerBackgroundColor}]`}
    >
      <DrawerItem
        label={() => {
          return (
            <View style={tailwind`w-[112px] mb-10`}>
              <Image
                resizeMode="contain"
                style={tailwind`h-[56px] w-[75px] self-center`}
                source={require("../../assets/fitcentr-logo-orange-white.png")}
              />
            </View>
          );
        }}
        onPress={() => {}}
      />
      {Platform.OS === "web" ? (
        <>
          <DrawerItem
            focused={focusedScreen === "HomeScreen"}
            activeTintColor={theme.navigationDrawerActiveTintColor}
            drawerStyle={tailwind`bg-[#ff0000]`}
            label={({ focused }) => {
              return (
                <View style={tailwind`w-[112px] mb-4`}>
                  <Image
                    resizeMode="contain"
                    style={tailwind`h-[18px] w-[18px] ml-[26px]`}
                    source={require("../../assets/home-icon.png")}
                    tintColor={focused ? "#FFFFFF" : "#998E8E"}
                  />
                </View>
              );
            }}
            onPress={() => {
              setFocusedScreen("HomeScreen");
              navigation.navigate("HomeScreen");
            }}
          />
          <DrawerItem
            focused={focusedScreen === "CalendarScreen"}
            activeTintColor={theme.navigationDrawerActiveTintColor}
            label={({ focused }) => (
              <View style={tailwind`w-[112px] mb-4`}>
                <Image
                  resizeMode="contain"
                  style={tailwind`h-[18px] w-[18px] ml-[26px]`}
                  source={require("../../assets/calendar-icon.png")}
                  tintColor={focused ? "#FFFFFF" : "#998E8E"}
                />
              </View>
            )}
            onPress={() => {
              setFocusedScreen("CalendarScreen");
              navigation.navigate("CalendarScreen");
            }}
          />
          <DrawerItem
            focused={focusedScreen === "MessagingScreen"}
            activeTintColor={theme.navigationDrawerActiveTintColor}
            label={({ focused }) => (
              <View style={tailwind`w-[112px] mb-4`}>
                <Image
                  resizeMode="contain"
                  style={tailwind`h-[18px] w-[18px] ml-[26px]`}
                  source={require("../../assets/messages-icon.png")}
                  tintColor={focused ? "#FFFFFF" : "#998E8E"}
                />
              </View>
            )}
            onPress={() => {
              setFocusedScreen("MessagingScreen");
              navigation.navigate("MessagingScreen");
            }}
          />
          <DrawerItem
            focused={focusedScreen === "NetworkScreen"}
            activeTintColor={theme.navigationDrawerActiveTintColor}
            label={({ focused }) => (
              <View style={tailwind`w-[112px] mb-4`}>
                <Image
                  resizeMode="contain"
                  style={tailwind`h-[18px] w-[18px] ml-[26px]`}
                  source={require("../../assets/connections-icon.png")}
                  tintColor={focused ? "#FFFFFF" : "#998E8E"}
                />
              </View>
            )}
            onPress={() => {
              setFocusedScreen("NetworkScreen");
              navigation.navigate("NetworkScreen");
            }}
          />
          <DrawerItem
            focused={focusedScreen === "NotificationsScreen"}
            activeTintColor={theme.navigationDrawerActiveTintColor}
            label={({ focused }) => (
              <View style={tailwind`w-[112px] mb-4`}>
                <Image
                  resizeMode="contain"
                  style={tailwind`h-[18px] w-[18px] ml-[26px]`}
                  source={require("../../assets/notifications-icon.png")}
                  tintColor={focused ? "#FFFFFF" : "#998E8E"}
                />
              </View>
            )}
            onPress={() => {
              setFocusedScreen("NotificationsScreen");
              navigation.navigate("NotificationsScreen");
            }}
          />
        </>
      ) : null}
      <DrawerItem
        focused={focusedScreen === "SettingsScreen"}
        activeTintColor={theme.navigationDrawerActiveTintColor}
        label={({ focused }) => (
          <View style={tailwind`w-[60px] mb-4`}>
            <Image
              resizeMode="contain"
              style={tailwind`h-[18px] w-[18px] ml-[26px]`}
              source={require("../../assets/settings-icon.png")}
              tintColor={focused ? "#FFFFFF" : "#998E8E"}
            />
          </View>
        )}
        onPress={() => {
          setFocusedScreen("SettingsScreen");
          navigation.navigate("SettingsScreen");
        }}
      />
      <DrawerItem
        focused={focusedScreen === "CalendarScreen"}
        activeTintColor={theme.navigationDrawerActiveTintColor}
        label={() => {
          return (
            <View style={tailwind`w-[70px] h-[120px]`}>
              <View style={tailwind`self-center h-full`}>
                <View style={tailwind`grow`} />
                <View
                  style={tailwind`rounded-full w-[55px] h-[55px] border-[1px] border-[${theme.onDemandProfileBorderColor}]`}
                >
                  <Image
                    style={tailwind`h-[55px] rounded-full`}
                    source={{ uri: currentUser.profileUrl }}
                  />
                </View>
              </View>
            </View>
          );
        }}
        onPress={() => {
          setFocusedScreen("ViewProfileScreen");
          navigation.navigate("ViewProfileScreen", {
            id: currentUser.id,
            type: currentUser.type,
          });
        }}
      />
      <DrawerItem
        label={() => {
          return (
            <View style={tailwind`mt--3 w-[70px] h-[30px]`}>
              <View style={tailwind`flex-row self-center`}>
                <Image
                  resizeMode="contain"
                  style={tailwind`h-[18px] w-[18px]`}
                  source={require("../../assets/credits-icon.png")}
                  tintColor={theme.navigationDrawerTextColor}
                />
                <Text
                  style={tailwind`capitalize text-[${theme.navigationDrawerTextColor}] font-semibold text-xs ml-2`}
                >
                  {currentUser.credits}
                </Text>
              </View>
            </View>
          );
        }}
        onPress={() => {
          setFocusedScreen("BundleScreen");
          navigation.navigate("BundleScreen");
        }}
      />
    </DrawerContentScrollView>
  );
}

CustomDrawerContent.propTypes = {
  navigation: PropTypes.any,
};

const LogoutButton = () => {
  const { setCurrentUser, setLoggedIn } = useContext(CurrentUserContext);

  const handleLogout = () => {
    setCurrentUser({});
    setLoggedIn(false);
  };

  useEffect(() => {
    handleLogout();
  }, []);
};

export default NavigationDrawers;
