import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import {
  View,
  Text,
  ScrollView,
  SafeAreaView,
  Platform,
  Pressable,
} from "react-native";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import HorizontalRule from "../components/base/HorizontalRule";
import tailwind from "twrnc";
import CalendarDayHeading from "../components/calendar-screen/CalendarDayHeading";
import {
  USER_TYPE_AMBASSADOR,
  USER_TYPE_PROVIDER,
} from "../components/base/Constants";
import { WebScrollContainer } from "../components/home-screen/WebScrollContainer";
import CalendarDay from "../components/calendar-screen/CalendarDay";
import NewEventScreen from "./NewEventScreen";
import { Image } from "@rneui/themed";
import {
  BottomSheetContext,
  CurrentUserContext,
  ThemeContext,
} from "../components/base/ApplicationContext";

const queryClient = new QueryClient({
  defaultOptions: { queries: { staleTime: 0 } },
});

const ProviderCalendarScreen = (props) => {
  dayjs.extend(utc);
  const viewStartDate = dayjs.utc().format("YYYY-MM-DD");
  const [selectedDate, setSelectedDate] = useState(viewStartDate);
  const { providerId, providerShortName } = props.route.params;

  const quickDaySelect = (event, date) => {
    setSelectedDate(dayjs(date).format("YYYY-MM-DD"));
    setHeadings(setupHeadings(dayjs(date).format("YYYY-MM-DD"), viewStartDate));
  };

  const setupHeadings = (selectedDate, initialDate) => {
    const headings = [];
    for (let index = 0; index < 31; index++) {
      let upcomingDate = dayjs(initialDate).add(index, "day");
      headings.push({
        label: dayjs(upcomingDate).format("ddd"),
        onPress: (event) => quickDaySelect(event, upcomingDate),
        dayNumber: dayjs(upcomingDate).format("DD"),
        monthNumber: dayjs(upcomingDate).format("MM"),
        selected:
          dayjs(upcomingDate).toString() === dayjs(selectedDate).toString(),
      });
    }
    return headings;
  };

  const [headings, setHeadings] = useState(
    setupHeadings(selectedDate, viewStartDate)
  );

  const { setBottomSheetContent, setBottomSheetVisible } =
    useContext(BottomSheetContext);
  const { currentUser } = useContext(CurrentUserContext);
  const { theme } = useContext(ThemeContext);

  const calendarTitle =
    providerShortName +
    (providerShortName.substr(providerShortName.length - 1) !== "s"
      ? "'s" + " Calendar"
      : "'" + " Calendar");

  return (
    <SafeAreaView style={tailwind`h-full bg-[${theme.backgroundColor}]`}>
      <View
        style={
          Platform.OS === "web"
            ? tailwind`w-full bg-[${theme.providerCalendarTopColor}] py-5`
            : tailwind`w-full bg-[${theme.providerCalendarTopColor}] py-3`
        }
      >
        <Text
          style={tailwind`self-center text-[${theme.providerCalendarTextColor}] font-semibold`}
        >
          {calendarTitle}
        </Text>
      </View>
      <View style={tailwind`flex w-full`}>
        <WebScrollContainer
          horizontal
          showsHorizontalScrollIndicator={Platform.OS === "web" ? true : false}
          style={tailwind`ml-2 mr-2`}
        >
          {headings &&
            headings.map((heading) => {
              return (
                <CalendarDayHeading
                  key={heading.dayNumber + heading.monthNumber}
                  label={heading.label}
                  onPress={heading.onPress}
                  dayNumber={heading.dayNumber}
                  selected={heading.selected}
                />
              );
            })}
        </WebScrollContainer>
      </View>
      <View style={tailwind`self-center`}>
        <Text style={tailwind`text-[${theme.textColor}]`}>
          {dayjs(selectedDate).format("ddd, DD MMM YYYY")}
        </Text>
      </View>
      <HorizontalRule />
      <ScrollView style={tailwind`mb-4`}>
        <QueryClientProvider client={queryClient}>
          <CalendarDay
            day={selectedDate}
            queryClient={queryClient}
            providerId={providerId}
          />
        </QueryClientProvider>
      </ScrollView>
      {currentUser.type === USER_TYPE_PROVIDER ||
      currentUser.type === USER_TYPE_AMBASSADOR ? (
        <View
          style={
            Platform.OS === "web"
              ? tailwind`absolute bottom-20 self-center`
              : tailwind`absolute bottom-5 self-center`
          }
        >
          <Pressable
            onPress={() => {
              setBottomSheetContent(
                <NewEventScreen
                  route={{ params: { queryClient: queryClient } }}
                />
              );
              setBottomSheetVisible(true);
            }}
          >
            <View
              style={tailwind`border-[1px] border-[${theme.calendarAddIconBorderColor}] bg-[${theme.backgroundColor}] rounded-full p-3`}
            >
              <Image
                resizeMode="contain"
                style={tailwind`h-[18px] w-[18px]`}
                source={require("../assets/calendar-plus-icon.png")}
              />
            </View>
          </Pressable>
        </View>
      ) : null}
    </SafeAreaView>
  );
};

ProviderCalendarScreen.propTypes = {
  route: PropTypes.shape({
    params: PropTypes.shape({
      providerId: PropTypes.number.isRequired,
      providerShortName: PropTypes.string.isRequired,
      initialDate: PropTypes.string,
    }),
  }),
};

export default ProviderCalendarScreen;
