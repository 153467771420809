import React, { useContext } from "react";
import { View, Text, Platform, Pressable } from "react-native";
import { Icon, Image } from "@rneui/themed";
import tailwind from "twrnc";
import { DrawerActions, useNavigation } from "@react-navigation/native";
import SearchModal from "../search/SearchModal";
import { BottomSheetContext, ThemeContext } from "./ApplicationContext";

const Header = () => {
  const navigation = useNavigation();
  const { theme } = useContext(ThemeContext);
  const { setBottomSheetContent, setBottomSheetVisible } =
    useContext(BottomSheetContext);

  return (
    <View
      style={tailwind`flex-row justify-center w-full bg-[${theme.backgroundColor}]`}
    >
      <View style={tailwind`flex-row w-full`}>
        <View style={tailwind`flex-row w-full self-center justify-end pr-6`}>
          <Pressable
            style={tailwind`w-10 h-10 rounded-lg py-[6px] ml-1 mr-4 mt-2`}
            onPress={() => {
              setBottomSheetContent(<SearchModal />);
              setBottomSheetVisible(true);
            }}
          >
            <View>
              <Image
                resizeMode="contain"
                style={tailwind`h-[32px] w-[32px]`}
                source={require("../../assets/search-icon.png")}
                tintColor={theme.iconColor}
              />
              {Platform.isTV && (
                <Text style={tailwind`pt-1 text-center`}>Search</Text>
              )}
            </View>
          </Pressable>
          {Platform.isTV || Platform.OS === "web" ? null : (
            <Pressable
              style={tailwind`w-10 h-10 rounded-lg py-[6px] ml-1 mr-4 mt-2`}
              onPress={() => navigation.dispatch(DrawerActions.toggleDrawer())}
            >
              <View>
                <Icon
                  name="menu"
                  color={theme.footerNavigationIconColor}
                  type="feather"
                />
                {Platform.isTV && (
                  <Text style={tailwind`pt-1 text-center`}>Search</Text>
                )}
              </View>
            </Pressable>
          )}
        </View>
      </View>
    </View>
  );
};

export default Header;
