import React, { useContext } from "react";
import PropTypes from "prop-types";
import { SafeAreaView, Text, View } from "react-native";
import tailwind from "twrnc";
import Button from "./Button";
import { BottomSheetContext, ThemeContext } from "./ApplicationContext";

const ErrorMessage = ({ errorMessage }) => {
  const { setBottomSheetVisible } = useContext(BottomSheetContext);
  const { theme } = useContext(ThemeContext);

  return (
    <SafeAreaView style={tailwind`bg-[${theme.backgroundColor}]`}>
      <View style={tailwind`h-full w-full`}>
        <View style={tailwind`h-[50px] py-4`}>
          <Text
            style={tailwind`self-center text-[${theme.redColor}] uppercase font-bold`}
          >
            Error
          </Text>
        </View>
        <View style={tailwind`py-6`}>
          <Text
            style={tailwind`self-center text-[${theme.textColor}] font-semibold`}
          >
            {errorMessage}
          </Text>
        </View>
        <View style={tailwind`self-center pb-6`}>
          <Button
            title={"Dismiss"}
            onPress={() => {
              setBottomSheetVisible(false);
            }}
          />
        </View>
      </View>
    </SafeAreaView>
  );
};

ErrorMessage.propTypes = {
  errorMessage: PropTypes.string.isRequired,
};

export { ErrorMessage as default };
