import React, { useContext } from "react";
import PropTypes from "prop-types";
import { View } from "react-native";
import tailwind from "twrnc";
import Button from "../../base/Button";
import { ThemeContext } from "../../base/ApplicationContext";

const ProviderRoomControls = ({ allMuted, setAllMuted }) => {
  const { theme } = useContext(ThemeContext);
  return (
    <View
      style={tailwind`bg-[${theme.providerSettingsBackgroundColor}] p-2 w-full h-full`}
    >
      <View style={tailwind`self-center`}>
        {allMuted ? (
          <Button title="Unmute All" onPress={() => setAllMuted(false)} />
        ) : (
          <Button title="Mute All" onPress={() => setAllMuted(true)} />
        )}
      </View>
    </View>
  );
};

ProviderRoomControls.propTypes = {
  allMuted: PropTypes.bool.isRequired,
  setAllMuted: PropTypes.func.isRequired,
};

export default ProviderRoomControls;
