import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useFonts, Inter_600SemiBold } from "@expo-google-fonts/inter";
import { Roboto_700Bold, Roboto_400Regular } from "@expo-google-fonts/roboto";
import { View, Pressable, Text } from "react-native";
import { Image } from "@rneui/themed";
import tailwind from "twrnc";
import { DEFAULT_PROFILE_URL } from "../base/Constants";
import dayjs from "dayjs";
import { QueryClient } from "@tanstack/react-query";
import ViewEvent from "../calendar-screen/ViewEvent";
import { BottomSheetContext, ThemeContext } from "../base/ApplicationContext";
import MiniTag from "../base/MiniTag";

const LiveThumbnail = ({ event }) => {
  let userSchedule;
  if (event.userSchedule) {
    userSchedule = event.userSchedule;
  } else {
    userSchedule = event;
    event.userSchedule = userSchedule;
  }

  const startDate = userSchedule.startDate;
  const shortDescription = userSchedule.shortDescription;
  const longDescription = userSchedule.longDescription;
  const duration = userSchedule.duration;
  const profileUrl =
    (userSchedule.user && userSchedule.user.profileUrl) || DEFAULT_PROFILE_URL;
  const shortName =
    (userSchedule.user &&
      userSchedule.user.firstName + " " + userSchedule.user.lastName) ||
    "";
  const tags = userSchedule.scheduleTags;
  const { setBottomSheetContent, setBottomSheetVisible } =
    useContext(BottomSheetContext);
  const { theme } = useContext(ThemeContext);

  let displayDate;
  let eventTime = dayjs(startDate).format("HH:mm");
  if (dayjs(startDate).format("YYYYMMDD") === dayjs().format("YYYYMMDD"))
    displayDate = "TODAY, " + eventTime;
  else if (
    dayjs(startDate).format("YYYYMMDD") ===
    dayjs().add(1, "day").format("YYYYMMDD")
  )
    displayDate = "TOMORROW, " + eventTime;
  else
    displayDate = (
      dayjs(startDate).format("dddd") +
      ", " +
      eventTime
    ).toUpperCase();

  let [fontsLoaded] = useFonts({
    Inter_600SemiBold,
    Roboto_700Bold,
    Roboto_400Regular,
  });

  if (!fontsLoaded) {
    return null;
  }

  return (
    <Pressable
      style={tailwind`rounded-lg`}
      onPress={() => {
        const queryClient = new QueryClient();
        setBottomSheetContent(
          <ViewEvent event={event} queryClient={queryClient} />
        );
        setBottomSheetVisible(true);
      }}
    >
      <View style={tailwind``}>
        <View style={tailwind`min-h-[30px]`}>
          <View
            style={tailwind`w-[365px] h-[240px] grow rounded-lg bg-[${theme.liveThumbnailBackgroundColor}] mr-[40px]`}
          >
            <View style={tailwind`absolute`}>
              <Image
                style={tailwind`w-[365px] h-[240px] self-center rounded-lg`}
                source={{ uri: profileUrl }}
              />
            </View>
            <View style={tailwind`p-1`}>
              <View style={tailwind`p-2 flex-row`}>
                <View style={tailwind`flex-row flex-wrap`}>
                  {tags && tags.length ? (
                    <MiniTag
                      key={tags[0].tag.id}
                      label={tags[0].tag.tagName.toUpperCase()}
                      colorKey={tags[0].tag.tagColorKey}
                    />
                  ) : null}
                </View>
                <View
                  style={tailwind`bg-[${theme.timePillBackgoundColor}] rounded-full pt-[3px] px-[6px] opacity-80`}
                >
                  <Text
                    style={[
                      tailwind`text-[${theme.timePillTextColor}] text-[10px]`,
                      { fontFamily: "Inter_600SemiBold" },
                    ]}
                  >
                    {duration} MIN
                  </Text>
                </View>
                <View style={tailwind`grow`} />
                <View
                  style={tailwind`bg-[${theme.timePillBackgoundColor}] rounded-full pt-[3px] px-[6px] opacity-80`}
                >
                  <Text
                    style={[
                      tailwind`text-[${theme.timePillTextColor}] self-end text-[10px]`,
                      { fontFamily: "Inter_600SemiBold" },
                    ]}
                  >
                    {displayDate}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View style={tailwind`mt-2`}>
            <Text
              style={[
                tailwind`font-semibold self-start grow text-[${theme.textColor}] text-[20px]`,
                { fontFamily: "Roboto_700Bold" },
              ]}
            >
              {shortDescription}
            </Text>
            <View style={tailwind`mb-4 mt-2 max-w-[365px] min-h-[10px]`}>
              <Text
                numberOfLines={1}
                style={[
                  tailwind`text-[10px] self-start text-[${theme.textColor}] text-[12px]`,
                  { fontFamily: "Roboto_400Regular" },
                ]}
              >
                {longDescription}
              </Text>
            </View>
            <View style={tailwind`flex-row`}>
              <View
                style={tailwind`rounded-full bg-[${theme.backgroundColor}]`}
              >
                <Image
                  style={tailwind`w-[40px] h-[40px] rounded-full`}
                  source={{ uri: profileUrl }}
                />
              </View>
              <View style={tailwind`ml-3`}>
                <Text
                  style={[
                    tailwind`text-[16px] text-[${theme.textColor}]`,
                    { fontFamily: "Roboto_700Bold" },
                  ]}
                >
                  {shortName}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    </Pressable>
  );
};

LiveThumbnail.propTypes = {
  event: PropTypes.object.isRequired,
};

export default LiveThumbnail;
