import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { useNavigation } from "@react-navigation/native";
import { ActivityIndicator, Text, Pressable, View } from "react-native";
import { Image } from "@rneui/themed";
import tailwind from "twrnc";
import {
  API_URL,
  CONNECTION_STATUS_BLOCKED,
  CONNECTION_STATUS_CONNECTED,
  CONNECTION_STATUS_IGNORED,
  CONNECTION_STATUS_REQUESTED,
  DEFAULT_PROFILE_URL,
  USER_TYPE_AMBASSADOR,
} from "../base/Constants";
import axios from "axios";
import {
  CurrentUserContext,
  NavigationSyncContext,
  ThemeContext,
} from "../base/ApplicationContext";
import {
  Roboto_400Regular,
  Roboto_700Bold,
  useFonts,
} from "@expo-google-fonts/roboto";

const ConnectionThumbnail = ({
  connectionId,
  userId,
  firstName,
  lastName,
  profileUrl,
  status,
  userType,
  isSourceUser,
  blockerUserId,
  handleStatusChange,
  roomId,
  isProviding,
}) => {
  const shortName = firstName + " " + lastName;
  const navigation = useNavigation();
  const {
    currentUser,
    setOutstandingConnectionCount,
    outstandingConnectionCount,
  } = useContext(CurrentUserContext);
  const { setFocusedScreen } = useContext(NavigationSyncContext);
  const { theme } = useContext(ThemeContext);
  const [connectionStatus, setConnectionStatus] = useState(status);

  const acceptConnection = () => {
    axios
      .post(
        API_URL + "userconnection/accept",
        {
          id: connectionId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: currentUser.token,
          },
        }
      )
      .then(() => {
        setConnectionStatus(CONNECTION_STATUS_CONNECTED);
        setOutstandingConnectionCount(outstandingConnectionCount - 1);
        handleStatusChange();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const rejectConnection = () => {
    axios
      .post(
        API_URL + "userconnection/reject",
        { id: connectionId },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: currentUser.token,
          },
        }
      )
      .then(() => {
        setConnectionStatus(CONNECTION_STATUS_IGNORED);
        handleStatusChange();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const blockConnection = () => {
    axios
      .post(
        API_URL + "userconnection/block",
        { id: connectionId },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: currentUser.token,
          },
        }
      )
      .then(() => {
        setConnectionStatus(CONNECTION_STATUS_BLOCKED);
        handleStatusChange();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const unblockConnection = () => {
    axios
      .post(
        API_URL + "userconnection/accept",
        {
          id: connectionId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: currentUser.token,
          },
        }
      )
      .then(() => {
        setConnectionStatus(CONNECTION_STATUS_CONNECTED);
        handleStatusChange();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const messageConnection = () => {
    setFocusedScreen("MessagingScreen");
    navigation.navigate("MessagingScreen", {
      initialConnectionId: connectionId,
      initialParams: {
        userId: userId,
        firstName: firstName,
        lastName: lastName,
        profileUrl: profileUrl,
        userType: userType,
        roomId: roomId,
      },
    });
  };

  const showCalendar = () => {
    setFocusedScreen("ProviderCalendarScreen");
    navigation.navigate("ProviderCalendarScreen", {
      providerId: userId,
      providerShortName: shortName,
    });
  };

  let fonts = useFonts({ Roboto_400Regular, Roboto_700Bold });

  if (!fonts) return;

  return (
    <View
      style={tailwind`rounded-lg bg-[${theme.connectionThumbnailBackgroundColor}] p-2 m-2`}
    >
      <View style={tailwind``}>
        <View style={tailwind`flex-row mb-4`}>
          <View style={tailwind`flex-row`}>
            <Image
              style={tailwind`w-[40px] h-[40px] rounded-full self-center`}
              source={{ uri: profileUrl || DEFAULT_PROFILE_URL }}
              PlaceholderContent={<ActivityIndicator />}
            />
            <View style={tailwind``}>
              <Text
                style={[
                  tailwind`capitalize text-[${theme.textColor}] mx-2 text-[14px]`,
                  { fontFamily: "Roboto_700Bold" },
                ]}
              >
                {shortName}
              </Text>
              {userType === USER_TYPE_AMBASSADOR ? (
                <View style={tailwind`w-full flex-row px-4`}>
                  <Image
                    resizeMode="contain"
                    style={tailwind`h-[16px] w-[13px] mr-2`}
                    source={require("../../assets/ambassador-icon.png")}
                  />
                  <Text
                    style={[
                      tailwind`text-[${theme.textColor}] font-semibold`,
                      { fontFamily: "Roboto_400Regular" },
                    ]}
                  >
                    Ambassador
                  </Text>
                </View>
              ) : null}
            </View>
          </View>
        </View>
        <View style={tailwind`flex-row`}>
          <View style={tailwind`flex-row grow`}>
            {connectionStatus === CONNECTION_STATUS_CONNECTED ? (
              <>
                <Pressable
                  style={tailwind`border-[1px] rounded-full mr-4 py-1 px-2 border-[${theme.connectionThumbnailIconBorderColor}] bg-[${theme.connectionThumbnailIconBackgroundColor}]`}
                  onPress={messageConnection}
                >
                  <Image
                    style={tailwind`w-[26px] h-[26px]`}
                    source={require("../../assets/user-chat-icon.png")}
                    PlaceholderContent={<ActivityIndicator />}
                    tintColor={theme.connectionThumbnailIconColor}
                  />
                </Pressable>

                {isProviding ? (
                  <Pressable
                    style={tailwind`border-[1px] rounded-full mr-4 p-1 border-[${theme.connectionThumbnailIconBorderColor}] bg-[${theme.connectionThumbnailIconBackgroundColor}]`}
                    onPress={showCalendar}
                  >
                    <Image
                      style={tailwind`w-[32px] h-[32px]`}
                      source={require("../../assets/calendar-icon.png")}
                      PlaceholderContent={<ActivityIndicator />}
                      tintColor={theme.connectionThumbnailIconColor}
                    />
                  </Pressable>
                ) : null}
              </>
            ) : null}
            <Pressable
              style={tailwind`border-[1px] rounded-full p-1 border-[${theme.connectionThumbnailIconBorderColor}] bg-[${theme.connectionThumbnailIconBackgroundColor}]`}
              onPress={() => {
                setFocusedScreen("ViewProfileScreen");
                navigation.navigate("ViewProfileScreen", {
                  id: userId,
                  type: userType,
                });
              }}
            >
              <Image
                style={tailwind`w-[32px] h-[32px]`}
                source={require("../../assets/user-profile-icon.png")}
                PlaceholderContent={<ActivityIndicator />}
                tintColor={theme.connectionThumbnailIconColor}
              />
            </Pressable>
          </View>
          <View>
            {!isSourceUser &&
            connectionStatus === CONNECTION_STATUS_REQUESTED ? (
              <View style={tailwind`flex-row`}>
                <Pressable
                  style={tailwind`border-[1px] rounded-full mr-4 p-1 border-[${theme.connectionThumbnailIconBorderColor}] bg-[${theme.connectionThumbnailIconBackgroundColor}]`}
                  name="acceptConnection"
                  onPress={acceptConnection}
                >
                  <Image
                    style={tailwind`w-[32px] h-[32px]`}
                    source={require("../../assets/user-plus-icon.png")}
                    PlaceholderContent={<ActivityIndicator />}
                    tintColor={theme.greenColor}
                  />
                </Pressable>
                <Pressable
                  style={tailwind`border-[1px] rounded-full p-1 border-[${theme.connectionThumbnailIconBorderColor}] bg-[${theme.connectionThumbnailIconBackgroundColor}]`}
                  onPress={rejectConnection}
                >
                  <Image
                    style={tailwind`w-[32px] h-[32px]`}
                    source={require("../../assets/user-minus-icon.png")}
                    PlaceholderContent={<ActivityIndicator />}
                    tintColor={theme.redColor}
                  />
                </Pressable>
              </View>
            ) : null}
            {connectionStatus === CONNECTION_STATUS_CONNECTED ? (
              <Pressable
                style={tailwind`border-[1px] rounded-full mr-1 p-1 border-[${theme.connectionThumbnailIconBorderColor}] bg-[${theme.connectionThumbnailIconBackgroundColor}]`}
                onPress={blockConnection}
              >
                <Image
                  style={tailwind`w-[32px] h-[32px]`}
                  source={require("../../assets/user-block-icon.png")}
                  PlaceholderContent={<ActivityIndicator />}
                  tintColor={theme.redColor}
                />
              </Pressable>
            ) : null}
            {blockerUserId === currentUser.id &&
            (connectionStatus === CONNECTION_STATUS_IGNORED ||
              connectionStatus === CONNECTION_STATUS_BLOCKED) ? (
              <>
                <Pressable
                  style={tailwind`border-[1px] rounded-full mr-1 p-1 border-[${theme.connectionThumbnailIconBorderColor}] bg-[${theme.connectionThumbnailIconBackgroundColor}]`}
                  name="unblockConnection"
                  onPress={unblockConnection}
                >
                  <Image
                    style={tailwind`w-[32px] h-[32px]`}
                    source={require("../../assets/user-plus-icon.png")}
                    PlaceholderContent={<ActivityIndicator />}
                    tintColor={theme.greenColor}
                  />
                </Pressable>
              </>
            ) : null}
          </View>
        </View>
      </View>
    </View>
  );
};

ConnectionThumbnail.propTypes = {
  connectionId: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  profileUrl: PropTypes.string,
  status: PropTypes.number.isRequired,
  userType: PropTypes.number.isRequired,
  isSourceUser: PropTypes.bool.isRequired,
  handleStatusChange: PropTypes.func.isRequired,
  roomId: PropTypes.string.isRequired,
  blockerUserId: PropTypes.number,
  isProviding: PropTypes.bool,
};
export default ConnectionThumbnail;
