import React, { useContext } from "react";
import PropTypes from "prop-types";
import { View, Text, ScrollView, ActivityIndicator } from "react-native";
import tailwind from "twrnc";
import {
  CurrentUserContext,
  ThemeContext,
} from "../../base/ApplicationContext";
import { Roboto_400Regular, useFonts } from "@expo-google-fonts/roboto";
import { Image } from "@rneui/themed";
import { DEFAULT_PROFILE_URL } from "../../base/Constants";

const Participants = ({ participants }) => {
  const { currentUser } = useContext(CurrentUserContext);
  const { theme } = useContext(ThemeContext);
  let fonts = useFonts(Roboto_400Regular);

  if (!fonts) return;

  return (
    <View
      style={tailwind`bg-[${theme.attendeeListBackgroundColor}] p-2 h-full`}
    >
      <View style={tailwind`flex-col`}>
        <ScrollView>
          <View>
            {participants &&
              participants.current &&
              participants.current.map((participant) => {
                if (participant.id !== currentUser.id)
                  return (
                    <View style={tailwind`flex-row`} key={participant.id}>
                      <Image
                        style={tailwind`w-[15px] h-[20px] rounded-xl m-1`}
                        source={{
                          uri: participant.profileUrl || DEFAULT_PROFILE_URL,
                        }}
                        PlaceholderContent={<ActivityIndicator />}
                      />
                      <Text
                        style={[
                          tailwind`text-[${theme.textColor}] pt-1`,
                          { fontFamily: "Roboto_400Regular" },
                        ]}
                      >
                        {participant.name}
                      </Text>
                    </View>
                  );
              })}
          </View>
        </ScrollView>
      </View>
    </View>
  );
};

Participants.propTypes = {
  participants: PropTypes.object.isRequired,
};

export default Participants;
