import React, { useContext } from "react";
import { Text, View, ActivityIndicator, Pressable } from "react-native";
import {
  useQuery,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import axios from "axios";

import tailwind from "twrnc";
import {
  API_URL,
  DEFAULT_PROFILE_URL,
  NOTIFICATION_TYPE_CONNECTION,
  NOTIFICATION_TYPE_EVENT,
} from "../base/Constants";
import { Image } from "@rneui/themed";
import ViewEvent from "../calendar-screen/ViewEvent";
import { useNavigation } from "@react-navigation/native";
import {
  BottomSheetContext,
  CurrentUserContext,
  NavigationSyncContext,
  ThemeContext,
} from "../base/ApplicationContext";
import {
  Roboto_400Regular,
  Roboto_700Bold,
  useFonts,
} from "@expo-google-fonts/roboto";
import dayjs from "dayjs";

const queryClient = new QueryClient();

const NotificationsPanel = () => {
  let fonts = useFonts({ Roboto_400Regular, Roboto_700Bold });
  if (!fonts) return;

  return (
    <QueryClientProvider client={queryClient}>
      <View>
        <Notifications />
      </View>
    </QueryClientProvider>
  );
};

const Notifications = () => {
  const navigation = useNavigation();
  const {
    currentUser,
    outstandingNotificationCount,
    setOutstandingNotificationCount,
  } = useContext(CurrentUserContext);
  const userId = currentUser.id;
  const { setBottomSheetContent, setBottomSheetVisible } =
    useContext(BottomSheetContext);
  const { setFocusedScreen } = useContext(NavigationSyncContext);
  const { theme } = useContext(ThemeContext);

  const { isLoading, error, data } = useQuery(["notifications" + userId], () =>
    axios
      .get(API_URL + "notification/" + userId, {
        headers: { authorization: currentUser.token },
      })
      .then((res) => res.data)
  );

  if (isLoading) return <ActivityIndicator />;
  if (error) return <Text>An error has occurred: ${error.message}</Text>;

  const handleUpdateNotification = (notificationId, callback) => {
    axios
      .patch(
        API_URL + "notification/" + notificationId,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            authorization: currentUser.token,
          },
        }
      )
      .then(() => callback());
  };

  const handleRedirect = (notificationType, event) => {
    switch (notificationType) {
      case NOTIFICATION_TYPE_EVENT:
        setOutstandingNotificationCount(outstandingNotificationCount - 1);
        setBottomSheetContent(<ViewEvent event={event} />);
        setBottomSheetVisible(true);
        break;
      case NOTIFICATION_TYPE_CONNECTION:
        setOutstandingNotificationCount(outstandingNotificationCount - 1);
        setFocusedScreen("NetworkScreen");
        navigation.navigate("NetworkScreen");
        break;
    }
  };

  const filteredData = data.filter((notification) => !notification.isRead);

  if (!filteredData.length) setOutstandingNotificationCount(0);

  return (
    <View style={tailwind`p-2`}>
      {!filteredData.length ? (
        <View
          style={tailwind`my-1 mx-2 rounded-sm bg-[${theme.notificationItemBackgroundColor}] p-2`}
        >
          <Text
            style={[
              tailwind`font-semibold text-[${theme.textColor}] self-center mb-2 pt-2`,
              { fontFamily: "Roboto_400Regular" },
            ]}
          >
            No Notifications
          </Text>
        </View>
      ) : null}
      {filteredData &&
        filteredData.map((notification) => {
          const time =
            notification.userSchedule && notification.userSchedule.startDate
              ? dayjs(notification.userSchedule.startDate).format("HH:mm")
              : "";
          return notification.isRead !== true ? (
            <View
              key={notification.id}
              style={tailwind`my-1 mx-2 rounded-lg bg-[${theme.notificationItemBackgroundColor}] p-2`}
            >
              <View>
                <Text
                  style={[
                    tailwind`font-semibold text-[${theme.textColor}] self-center mb-2`,
                    { fontFamily: "Roboto_700Bold" },
                  ]}
                >
                  {notification.message}
                </Text>
              </View>
              <View key={notification.id} style={tailwind`flex-row`}>
                <View style={tailwind`mr-[80px]`}>
                  <View style={tailwind`self-center`}>
                    {notification.userSchedule &&
                    notification.userSchedule.user ? (
                      <Image
                        style={tailwind`w-[15px] h-[20px] rounded-xl m-1`}
                        source={{
                          uri:
                            notification.userSchedule.user.profileUrl ||
                            DEFAULT_PROFILE_URL,
                        }}
                        PlaceholderContent={<ActivityIndicator />}
                      />
                    ) : null}
                  </View>
                  {notification.userSchedule &&
                  notification.userSchedule.user ? (
                    <Text style={{ fontFamily: "Roboto_700Bold" }}>
                      {notification.userSchedule.user.firstName +
                        " " +
                        notification.userSchedule.user.lastName}
                    </Text>
                  ) : null}
                </View>
                <View>
                  {notification.type.id === NOTIFICATION_TYPE_EVENT &&
                  notification.userSchedule ? (
                    <Pressable
                      onPress={() => {
                        handleUpdateNotification(notification.id, () =>
                          handleRedirect(
                            notification.type.id,
                            notification.userSchedule
                          )
                        );
                      }}
                    >
                      <View>
                        <Text
                          style={[
                            tailwind`font-semibold`,
                            { fontFamily: "Roboto_700Bold" },
                          ]}
                        >
                          {notification.userSchedule.shortDescription}
                        </Text>
                        <View style={tailwind`flex-row`}>
                          <Text
                            style={[
                              tailwind`text-[${theme.viewEventHeadingTextColor}] font-semibold`,
                              { fontFamily: "Roboto_400Regular" },
                            ]}
                          >
                            {dayjs(notification.userSchedule.startDate).format(
                              "ddd MMM DD"
                            )}
                          </Text>
                          <Text
                            style={[
                              tailwind`text-[${theme.viewEventHeadingTextColor}] mx-2`,
                              { fontFamily: "Roboto_400Regular" },
                            ]}
                          >
                            at
                          </Text>
                          <Text
                            style={[
                              tailwind`text-[${theme.viewEventHeadingTextColor}] font-semibold`,
                              { fontFamily: "Roboto_400Regular" },
                            ]}
                          >
                            {time}
                          </Text>
                        </View>
                        <View style={tailwind`flex-row`}>
                          <Text
                            style={[
                              tailwind`text-[${theme.viewEventHeadingTextColor}] text-[12px]`,
                              { fontFamily: "Roboto_400Regular" },
                            ]}
                          >
                            {notification.userSchedule.duration} MIN
                          </Text>
                        </View>
                      </View>
                    </Pressable>
                  ) : null}
                  {notification.type.id === NOTIFICATION_TYPE_CONNECTION &&
                  notification.sourceUser ? (
                    <Pressable
                      onPress={() => {
                        handleUpdateNotification(notification.id, () =>
                          handleRedirect(notification.type.id)
                        );
                      }}
                    >
                      <View>
                        <Text
                          style={[
                            tailwind`font-semibold`,
                            { fontFamily: "Roboto_400Regular" },
                          ]}
                        >
                          {notification.sourceUser.firstName +
                            " " +
                            notification.sourceUser.lastName}
                        </Text>
                        <View style={tailwind`m-1`}>
                          <Image
                            style={tailwind`w-[32px] h-[32px] rounded-full m-1`}
                            source={{
                              uri:
                                notification.sourceUser.profileUrl ||
                                DEFAULT_PROFILE_URL,
                            }}
                            PlaceholderContent={<ActivityIndicator />}
                          />
                        </View>
                      </View>
                    </Pressable>
                  ) : null}
                </View>
              </View>
            </View>
          ) : null;
        })}
    </View>
  );
};

Notifications.propTypes = {};

export default NotificationsPanel;
