import React, { useContext, useReducer, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  ActivityIndicator,
  Platform,
  SafeAreaView,
  ScrollView,
  Text,
  View,
} from "react-native";
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";
import tailwind from "twrnc";
import {
  API_URL,
  TREE_TYPE_BODY,
  TREE_TYPE_USER,
  USER_TYPE_AMBASSADOR,
  USER_TYPE_PROVIDER,
  USER_TYPE_USER,
} from "../components/base/Constants";
import { ImageUpload } from "../components/edit-profile-screen/ImageUpload";
import TagSelector from "../components/tags/TagSelector";
import { TagReducer } from "../components/tags/TagReducer";
import axios from "axios";
import { EditPersonalDetailsForm } from "../components/edit-profile-screen/EditPersonalDetailsForm";
import Button from "../components/base/Button";
import { useNavigation } from "@react-navigation/native";
import { ChangePasswordForm } from "../components/edit-profile-screen/ChangePasswordForm";
import { PersonalTrainingSettings } from "../components/edit-profile-screen/PersonalTrainingSettings";
import { PersonalTrainingSettings as PersonalTrainingSettingsNative } from "../components/edit-profile-screen/PersonalTrainingSettings.native";
import { StyledInput } from "../components/base/StyledInput";
import { TakeSelfie } from "../components/edit-profile-screen/TakeSelfie";
import { BillingSettings } from "../components/edit-profile-screen/BillingSettings";
import { BillingSettings as BillingSettingsNative } from "../components/edit-profile-screen/BillingSettings.native";
import {
  CurrentUserContext,
  NavigationSyncContext,
  ThemeContext,
} from "../components/base/ApplicationContext";
import { Roboto_400Regular, useFonts } from "@expo-google-fonts/roboto";
import HorizontalRule from "../components/base/HorizontalRule";

let token;

const EditProfileScreen = (props) => {
  const { userId, userType } = props.route.params;
  const queryClient = new QueryClient();
  let fonts = useFonts({ Roboto_400Regular });
  if (!fonts) return;

  return (
    <QueryClientProvider client={queryClient}>
      <EditProfileContainer userId={userId} userType={userType} />
    </QueryClientProvider>
  );
};

const UpdatePersonalDetails = async (
  userId,
  firstName,
  lastName,
  profileText,
  phoneNumber,
  newPassword,
  existingPassword,
  baseCost,
  additionalCost,
  classDuration,
  isProviding,
  currency,
  currentUser,
  setCurrentUser,
  addressLine1,
  addressLine2,
  city,
  country,
  unitedState,
  postalCode
) => {
  await axios
    .patch(
      API_URL + "users/" + userId,
      {
        firstName: firstName,
        lastName: lastName,
        profileText: profileText,
        phoneNumber: phoneNumber,
        newPassword: newPassword,
        existingPassword: existingPassword,
        baseCost: baseCost,
        additionalCost: additionalCost,
        classDuration: classDuration,
        isProvidingPersonalTraining: isProviding,
        currency: currency,
        addressLine1: addressLine1,
        addressLine2: addressLine2,
        city: city,
        country: country,
        unitedState: unitedState,
        postalCode: postalCode,
      },
      {
        headers: { authorization: token },
      }
    )
    .then((response) => {
      const updatedUser = response.data.raw;
      const updatedCurrency = updatedUser.currency;

      const newCurrentUser = currentUser;
      newCurrentUser.currency = updatedCurrency.id;
      newCurrentUser.currencySymbol = updatedCurrency.symbol;
      newCurrentUser.isProfileCompleted = updatedUser.isProfileCompleted;
      setCurrentUser(newCurrentUser);
    });
};

const SaveTagSelection = async (userId, userTagSelection, bodyTagSelection) => {
  const toBeSaved = [
    ...userTagSelection.filter((tag) => !tag.deleted),
    ...bodyTagSelection.filter((tag) => !tag.deleted),
  ];
  await axios.patch(
    API_URL + "users/tags/" + userId,
    {
      tags: toBeSaved,
    },
    {
      headers: {
        "Content-Type": "application/json",
        authorization: token,
      },
    }
  );
};

const EditProfileContainer = ({ userId, userType }) => {
  const { currentUser, setCurrentUser } = useContext(CurrentUserContext);
  const { theme } = useContext(ThemeContext);
  const { setFocusedScreen } = useContext(NavigationSyncContext);
  const navigation = useNavigation();
  const [existingPassword, setExistingPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const passwordStatus = useRef();

  const [confirmPassword, setConfirmPassword] = useState("");
  const confirmPasswordStatus = useRef();

  const [firstName, setFirstName] = useState("");
  const firstNameStatus = useRef();

  const [lastName, setLastName] = useState("");
  const lastNameStatus = useRef();

  const [profileText, setProfileText] = useState();

  const [phoneNumber, setPhoneNumber] = useState("");
  const phoneNumberStatus = useRef();

  const [baseCost, setBaseCost] = useState("");
  const [additionalCost, setAdditionalCost] = useState("");
  const [classDuration, setClassDuration] = useState("");
  const [currency, setCurrency] = useState();
  const originalCurrency = useRef();
  const [takingSelfie, setTakingSelfie] = useState(false);
  const [selfieImage, setSelfieImage] = useState();
  const [userTagSelection, processUserTagSelection] = useReducer(
    TagReducer,
    []
  );
  const [bodyTagSelection, processBodyTagSelection] = useReducer(
    TagReducer,
    []
  );
  const [isProviding, setIsProviding] = useState(false);
  const [addressLine1, setAddressLine1] = useState();
  const [addressLine2, setAddressLine2] = useState();
  const [city, setCity] = useState();
  const [country, setCountry] = useState();
  const [unitedState, setUnitedState] = useState();
  const [postalCode, setPostalCode] = useState();

  token = currentUser.token;

  const { isLoading, error, data } = useQuery(["users" + userId], () =>
    axios
      .get(API_URL + "users/" + userId, {
        staleTime: 0,
        headers: { authorization: currentUser.token },
      })
      .then((res) => {
        const {
          tags,
          profileText,
          classDuration,
          baseCost,
          additionalCost,
          phoneNumber,
          isProvidingPersonalTraining,
          currency,
          addressLine1,
          addressLine2,
          city,
          country,
          unitedState,
          postalCode,
        } = res.data;

        const selectedTags = tags.map((tag) => {
          return {
            id: tag.tagId.id,
            type: tag.tagId.descendantClosure[0].ancestor.id,
          };
        });

        selectedTags.forEach((tag) => {
          switch (tag.type) {
            case TREE_TYPE_BODY:
              processBodyTagSelection({
                id: tag.id,
                title: tag.tagName,
                deleted: 0,
              });
              break;
            default:
            case TREE_TYPE_USER:
              processUserTagSelection({
                id: tag.id,
                title: tag.tagName,
                deleted: 0,
              });
              break;
          }
        });

        setProfileText(profileText);
        setClassDuration(classDuration.toString());
        setBaseCost(baseCost.toString());
        setAdditionalCost(additionalCost.toString());
        setPhoneNumber(phoneNumber);
        setIsProviding(isProvidingPersonalTraining);
        setCurrency((currency && currency.id) || 0);
        setAddressLine1(addressLine1);
        setAddressLine2(addressLine2);
        setCity(city);
        setCountry((country && country.id) || 0);
        setUnitedState((unitedState && unitedState.id) || 0);
        setPostalCode(postalCode);

        originalCurrency.current = (currency && currency.id) || 0;
        return res.data;
      })
  );

  if (isLoading) return <ActivityIndicator />;
  if (error) return <Text>An error has occurred: ${error.message}</Text>;

  return (
    <SafeAreaView style={tailwind`flex h-full bg-[${theme.backgroundColor}]`}>
      <ScrollView style={tailwind`w-full self-center`}>
        {takingSelfie ? (
          <TakeSelfie
            setSelfieImage={setSelfieImage}
            setTakingSelfie={setTakingSelfie}
          />
        ) : null}
        <View style={tailwind`flex-row flex-wrap self-center`}>
          <View style={tailwind`self-center self-start`}>
            <ImageUpload
              existingImageUrl={data.profileUrl}
              setTakingSelfie={setTakingSelfie}
              selfieImage={selfieImage}
            />
          </View>
          <View style={tailwind`self-center mb-4`}>
            <EditPersonalDetailsForm
              emailAddress={data.emailAddress}
              firstName={data.firstName}
              setFirstName={setFirstName}
              firstNameStatus={firstNameStatus}
              lastName={data.lastName}
              setLastName={setLastName}
              lastNameStatus={lastNameStatus}
              phoneNumber={data.phoneNumber}
              setPhoneNumber={setPhoneNumber}
              phoneNumberStatus={phoneNumberStatus}
            />
          </View>
        </View>
        <View style={tailwind`self-center mb-4`}>
          <View
            style={tailwind`${
              Platform.OS === "web" ? "self-center" : "self-start ml-2"
            } mb-4`}
          >
            <StyledInput
              label={"About You"}
              multiline={true}
              placeholder={"Write a few words to describe yourself..."}
              value={profileText}
              height={"120px"}
              width={Platform.OS === "web" ? "620px" : "350px"}
              onChangeText={(text) => setProfileText(text)}
            />
          </View>
          <View style={tailwind`mb-2 mt-4 ml-2`}>
            <Text
              style={[
                tailwind`text-[20px] text-[${theme.textColor}] w-1/2`,
                { fontFamily: "Roboto_700Bold" },
              ]}
            >
              Content Filters
            </Text>
          </View>
          <View style={tailwind`self-center mx-2 mb-2`}>
            <TagSelector
              tagType={TREE_TYPE_USER}
              processTagSelection={processUserTagSelection}
              tagSelection={userTagSelection}
            />
          </View>
          {userType === USER_TYPE_USER ? (
            <View style={tailwind`self-center mx-2 mb-2`}>
              <TagSelector
                tagType={TREE_TYPE_BODY}
                processTagSelection={processBodyTagSelection}
                tagSelection={bodyTagSelection}
              />
            </View>
          ) : null}
        </View>
        {userType === USER_TYPE_PROVIDER ||
        userType === USER_TYPE_AMBASSADOR ? (
          <>
            <HorizontalRule />
            <View style={tailwind`mt-2`}>
              {Platform.OS === "web" ? (
                <PersonalTrainingSettings
                  baseCost={baseCost}
                  setBaseCost={setBaseCost}
                  classDuration={classDuration}
                  setClassDuration={setClassDuration}
                  currency={currency}
                  setCurrency={setCurrency}
                  isProviding={isProviding}
                  setIsProviding={setIsProviding}
                />
              ) : (
                <PersonalTrainingSettingsNative
                  baseCost={baseCost}
                  setBaseCost={setBaseCost}
                  additionalCost={additionalCost}
                  setAdditionalCost={setAdditionalCost}
                  classDuration={classDuration}
                  setClassDuration={setClassDuration}
                  currency={currency}
                  setCurrency={setCurrency}
                  isProviding={isProviding}
                  setIsProviding={setIsProviding}
                />
              )}
            </View>
          </>
        ) : null}
        <HorizontalRule />
        {Platform.OS === "web" ? (
          <View style={tailwind`self-center mt-2`}>
            <BillingSettings
              currency={currency}
              setCurrency={setCurrency}
              addressLine1={addressLine1}
              setAddressLine1={setAddressLine1}
              addressLine2={addressLine2}
              setAddressLine2={setAddressLine2}
              city={city}
              setCity={setCity}
              country={country}
              setCountry={setCountry}
              unitedState={unitedState}
              setUnitedState={setUnitedState}
              postalCode={postalCode}
              setPostalCode={setPostalCode}
            />
          </View>
        ) : (
          <View style={tailwind`mt-4`}>
            <BillingSettingsNative
              currency={currency}
              setCurrency={setCurrency}
              addressLine1={addressLine1}
              setAddressLine1={setAddressLine1}
              addressLine2={addressLine2}
              setAddressLine2={setAddressLine2}
              city={city}
              setCity={setCity}
              country={country}
              setCountry={setCountry}
              unitedState={unitedState}
              setUnitedState={setUnitedState}
              postalCode={postalCode}
              setPostalCode={setPostalCode}
            />
          </View>
        )}
        <HorizontalRule />
        <View style={tailwind`mt-6 mb-4`}>
          <View
            style={tailwind`${
              Platform.OS === "web" ? "self-center" : "self-start"
            }`}
          >
            <ChangePasswordForm
              setExistingPassword={setExistingPassword}
              setNewPassword={setNewPassword}
              confirmPassword={confirmPassword}
              setConfirmPassword={setConfirmPassword}
              passwordStatus={passwordStatus}
              confirmPasswordStatus={confirmPasswordStatus}
            />
          </View>
        </View>
        <View style={tailwind`self-center mb-6`}>
          <Button
            title="Save"
            onPress={() => {
              const queryClient = new QueryClient();
              UpdatePersonalDetails(
                userId,
                firstName,
                lastName,
                profileText,
                phoneNumber,
                newPassword,
                existingPassword,
                baseCost,
                additionalCost,
                classDuration,
                isProviding,
                currency,
                currentUser,
                setCurrentUser,
                addressLine1,
                addressLine2,
                city,
                country,
                unitedState,
                postalCode
              );
              SaveTagSelection(userId, userTagSelection, bodyTagSelection);
              queryClient.invalidateQueries([
                "trainer" + userId,
                "user" + userId,
                "bundles",
              ]);
              if (currency !== originalCurrency.current) {
                setTimeout(() => navigation.navigate("LogoutScreen"), 500);
              } else {
                setFocusedScreen("ViewProfileScreen");
                navigation.navigate("ViewProfileScreen", {
                  id: userId,
                  type: userType,
                });
              }
            }}
          />
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

EditProfileContainer.propTypes = {
  userId: PropTypes.number.isRequired,
  userType: PropTypes.number.isRequired,
};

EditProfileScreen.propTypes = {
  route: PropTypes.object.isRequired,
};

export { EditProfileScreen };
