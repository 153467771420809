import React, { useContext } from "react";
import PropTypes from "prop-types";
import TrainerThumbnail from "./TrainerThumbnail";
import {
  useQuery,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import axios from "axios";
import { ActivityIndicator, Text } from "react-native";
import { API_URL } from "../base/Constants";
import { CurrentUserContext } from "../base/ApplicationContext";

const queryClient = new QueryClient();

const TrainerThumbnailStrip = ({ section }) => {
  const { currentUser } = useContext(CurrentUserContext);
  return (
    <QueryClientProvider client={queryClient}>
      <ThumbnailStrip sectionId={section.id} userId={currentUser.id} />
    </QueryClientProvider>
  );
};

TrainerThumbnailStrip.propTypes = {
  section: PropTypes.object.isRequired,
};

function ThumbnailStrip({ sectionId, userId }) {
  const { currentUser } = useContext(CurrentUserContext);
  const { isLoading, error, data } = useQuery(["trainers" + sectionId], () =>
    axios
      .get(API_URL + "homepage/section/" + sectionId + "/" + userId, {
        headers: { authorization: currentUser.token },
      })
      .then((res) => res.data)
  );
  if (isLoading) return <ActivityIndicator />;
  if (error) return <Text>{"An error has occurred: " + error.message}</Text>;

  return (
    <>
      {data.map((trainer) => (
        <TrainerThumbnail key={trainer.id} id={trainer.id} trainer={trainer} />
      ))}
    </>
  );
}

ThumbnailStrip.propTypes = {
  sectionId: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
};

export default TrainerThumbnailStrip;
