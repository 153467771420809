import React, { useContext } from "react";
import PropTypes from "prop-types";
import {
  useQuery,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import axios from "axios";
import { ActivityIndicator, Text } from "react-native";
import { API_URL } from "../base/Constants";
import { CurrentUserContext } from "../base/ApplicationContext";
import TrainerThumbnail from "../home-screen/TrainerThumbnail";

const queryClient = new QueryClient();

const SearchTrainerThumbnailStrip = ({ section }) => {
  const { currentUser } = useContext(CurrentUserContext);
  return (
    <QueryClientProvider client={queryClient}>
      <ThumbnailStrip sectionId={section.id} userId={currentUser.id} />
    </QueryClientProvider>
  );
};

SearchTrainerThumbnailStrip.propTypes = {
  section: PropTypes.object.isRequired,
};

function ThumbnailStrip({ sectionId, userId }) {
  const { currentUser, searchQuery } = useContext(CurrentUserContext);
  const { isLoading, error, data } = useQuery(
    ["trainers" + sectionId + searchQuery],
    () =>
      axios
        .get(
          API_URL +
            "search/section/" +
            sectionId +
            "/" +
            userId +
            "/" +
            searchQuery,
          {
            headers: { authorization: currentUser.token },
          }
        )
        .then((res) => res.data)
  );
  if (isLoading) return <ActivityIndicator />;
  if (error) return <Text>{"An error has occurred: " + error.message}</Text>;

  return (
    <>
      {data.map((trainer) => (
        <TrainerThumbnail key={trainer.id} id={trainer.id} trainer={trainer} />
      ))}
    </>
  );
}

ThumbnailStrip.propTypes = {
  sectionId: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
};

export default SearchTrainerThumbnailStrip;
