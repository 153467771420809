import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { View } from "react-native";
import tailwind from "twrnc";
import Button from "../base/Button";

const StreamControls = ({
  cameraEnabled,
  streaming,
  streamStopping,
  stopStreaming,
  startStreaming,
}) => {
  return (
    <>
      {cameraEnabled &&
        (streaming ? (
          <View style={tailwind`flex-row`}>
            {streamStopping ? (
              <Button
                onPress={stopStreaming}
                title={"End in " + streamStopping}
              />
            ) : (
              <Button onPress={stopStreaming} title="End Stream" />
            )}
          </View>
        ) : (
          <>
            <Button
              title="Go Live"
              disabled={streaming}
              onPress={startStreaming}
            />
          </>
        ))}
    </>
  );
};

StreamControls.propTypes = {
  cameraEnabled: PropTypes.bool.isRequired,
  streaming: PropTypes.bool.isRequired,
  streamStopping: PropTypes.number.isRequired,
  stopStreaming: PropTypes.func.isRequired,
  startStreaming: PropTypes.func.isRequired,
};

export default StreamControls;
