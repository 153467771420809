import React from "react";
import PropTypes from "prop-types";
import { StyledInput } from "../base/StyledInput";

const AddressLine = ({ label, addressLine, setAddressLine }) => {
  return (
    <>
      <StyledInput
        label={label}
        onChangeText={(text) => setAddressLine(text)}
        value={addressLine}
        width={"350px"}
      />
    </>
  );
};

AddressLine.propTypes = {
  label: PropTypes.string.isRequired,
  addressLine: PropTypes.string.isRequired,
  setAddressLine: PropTypes.func.isRequired,
};

export { AddressLine as default };
