import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { View, Platform, Dimensions } from "react-native";
import tailwind from "twrnc";
import * as ScreenOrientation from "expo-screen-orientation";
import { ResizeMode, Video } from "expo-av";

const ExpoPlayer = ({ video }) => {
  const videoUri = video.video.path;
  const defaultDimensions = {
    height: Dimensions.get("window").height,
    width: Dimensions.get("window").width,
  };
  const defaultOrientation =
    defaultDimensions.width < defaultDimensions.height
      ? "portrait"
      : "landscape";
  const getOrientation = (orientation) => {
    if (
      orientation === ScreenOrientation.Orientation.LANDSCAPE_LEFT ||
      orientation === ScreenOrientation.Orientation.LANDSCAPE_RIGHT
    ) {
      return "landscape";
    }
    return "portrait";
  };
  const [orientation, setOrientation] = useState(defaultOrientation);

  useEffect(() => {
    ScreenOrientation.addOrientationChangeListener(({ orientationInfo }) => {
      setTimeout(() => {
        setOrientation(getOrientation(orientationInfo.orientation));
      }, 300);
    });
  }, [orientation]);

  const refVideo = useRef();

  let playerWidth,
    playerHeight,
    videoHeight,
    videoWidth,
    containerStyle,
    playerStyle,
    videoStyle;

  const windowWidth = Dimensions.get("window").width;
  const windowHeight = Dimensions.get("window").height;

  if (Platform.isPad) {
    playerWidth = windowWidth;
    playerHeight = windowHeight;
  } else if (Platform.isTV) {
    videoStyle = {
      height: videoHeight,
      width: videoWidth,
      verticalAlign: "top",
    };

    playerStyle = {
      videoBackgroundColor: "black",
      height: playerHeight,
      width: playerWidth,
      verticalAlign: "top",
    };
  } else if (Platform.OS === "web") {
    playerWidth = windowWidth * (2 / 3);
    playerHeight = windowHeight - 350;
    videoWidth = playerHeight * 1.3333333;
    videoHeight = playerHeight;
    containerStyle = tailwind`h-[${windowHeight - 250}px] w-full`;

    videoStyle = {
      height: videoHeight,
      width: videoWidth,
      verticalAlign: "top",
    };

    playerStyle = {
      videoBackgroundColor: "black",
      height: playerHeight,
      width: playerWidth,
      verticalAlign: "top",
    };
  } else {
    if (orientation === "portrait") {
      if (Platform.OS === "ios") {
        containerStyle = tailwind`h-[${windowHeight / 3}px] w-full`;
        playerHeight = windowHeight / 3;
        playerWidth = windowWidth;
        videoHeight = playerHeight;
        videoWidth = windowWidth;

        videoStyle = {
          height: videoHeight,
          width: videoWidth,
          verticalAlign: "top",
        };

        playerStyle = {
          videoBackgroundColor: "black",
          height: playerHeight,
          width: playerWidth,
          verticalAlign: "top",
        };

        console.log("vh,vw", videoHeight, videoWidth);
        console.log("ph,pw", playerHeight, playerWidth);
      } else {
        if (windowHeight > windowWidth) {
          //android tablet doesn't set Platform.isPad
          containerStyle = tailwind`h-[${windowHeight / 3}px] w-full`;
          playerHeight = windowHeight / 3;
          playerWidth = windowWidth;
          videoWidth = windowWidth;
        } else {
          playerWidth = windowWidth;
          playerHeight = 220;
        }

        videoStyle = {
          height: videoHeight,
          width: videoWidth,
          verticalAlign: "top",
        };

        playerStyle = {
          videoBackgroundColor: "black",
          height: playerHeight,
          width: playerWidth,
          verticalAlign: "top",
        };
      }
    } else {
      playerHeight = windowHeight - 80;
      videoStyle = {
        height: videoHeight,
        width: videoWidth,
        verticalAlign: "top",
      };

      playerStyle = {
        videoBackgroundColor: "black",
        height: playerHeight,
        width: playerWidth,
        verticalAlign: "top",
      };
    }
  }

  return (
    <View style={[containerStyle]}>
      <Video
        ref={refVideo}
        volume={5}
        style={playerStyle}
        source={{
          uri: videoUri,
        }}
        videoStyle={videoStyle}
        useNativeControls
        resizeMode={ResizeMode.CONTAIN}
        onError={(error) => console.log("error", error)}
        onLoadStart={() => console.log("onLoadStart", videoUri)}
        onReadyForDisplay={() => console.log("onReadyForDisplay")}
        shouldPlay={Platform.OS === "web" ? false : true}
        onPlaybackStatusUpdate={(playbackStatus) => {
          if (playbackStatus.error) {
            console.log("playback error");
          }
          if (playbackStatus.didJustFinish) {
            console.log("playback finished");
            // refVideo.current.playAsync();
          }
        }}
      />
    </View>
  );
};

ExpoPlayer.propTypes = {
  video: PropTypes.object.isRequired,
};

export default ExpoPlayer;
