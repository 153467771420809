import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { ActivityIndicator, Platform, Pressable, View } from "react-native";
import { Image } from "@rneui/themed";
import tailwind from "twrnc";
import { API_URL, DEFAULT_PROFILE_URL } from "../base/Constants";
import * as DocumentPicker from "expo-document-picker";
import { CurrentUserContext } from "../base/ApplicationContext";

const ImageUpload = ({ existingImageUrl, setTakingSelfie, selfieImage }) => {
  const { currentUser } = useContext(CurrentUserContext);

  if (!existingImageUrl) return;
  
  useEffect(() => {
    if (selfieImage) {
      uploadImage({
        uri: selfieImage.uri,
        name: "selfie.jpg",
        mimetype: "image/jpeg",
        size: selfieImage.uri.length,
      });
    }
  }, [selfieImage]);

  useEffect(() => {}, [existingImageUrl]);

  const uploadImage = async (singleFile) => {
    const data = new FormData();
    if (singleFile != null) {
      if (Platform.OS === "web") {
        data.append("file_attachment", {
          uri: singleFile.uri,
          name: singleFile.name,
          type: singleFile.mimeType,
          size: singleFile.size,
        });
        data.append("file", singleFile.uri);
        data.append("originalFilename", singleFile.name);

        fetch(API_URL + "users/image/" + currentUser.id, {
          method: "POST",
          body: data,
          headers: {
            Authorization: currentUser.token,
          },
        })
          .then((response) => response.json())
          .then((result) => {
            console.log("result", result);
            if (result.message) {
              console.log(result.message);
            }
            existingImageUrl = result.raw.profileUrl;
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      } else {
        data.append("file_attachment", {
          uri: singleFile.uri,
          name: singleFile.name,
          type: singleFile.mimeType,
          size: selfieImage.uri.length,
        });

        fetch(API_URL + "users/image/" + currentUser.id, {
          method: "POST",
          body: data,
          headers: {
            Authorization: currentUser.token,
          },
        })
          .then((response) => response.json())
          .then((result) => {
            existingImageUrl = result.raw.profileUrl;
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
    }
  };

  async function selectFile() {
    console.log("selectFile");
    try {
      const result = await DocumentPicker.getDocumentAsync({
        copyToCacheDirectory: false,
        type: "image/*",
      });

      if (result.type === "success") {
        console.log("selectFile result", result);
        await uploadImage(result);
      }
    } catch (err) {
      console.warn(err);
      return false;
    }
  }

  //   const checkPermissionsAndroid = async () => {
  //     try {
  //       const result = await PermissionsAndroid.check(
  //         PermissionsAndroid.PERMISSIONS.READ_EXTERNAL_STORAGE
  //       );

  //       if (!result) {
  //         const granted = await PermissionsAndroid.request(
  //           PermissionsAndroid.PERMISSIONS.READ_EXTERNAL_STORAGE,
  //           {
  //             title:
  //               "You need to give storage permission to download and save the file",
  //             message: "App needs access to your camera ",
  //             buttonNeutral: "Ask Me Later",
  //             buttonNegative: "Cancel",
  //             buttonPositive: "OK",
  //           }
  //         );
  //         if (granted === PermissionsAndroid.RESULTS.GRANTED) {
  //           console.log("You can use the camera");
  //           return true;
  //         } else {
  //           Alert.alert("Error", "PERMISSION_ACCESS_FILE");

  //           console.log("Camera permission denied");
  //           return false;
  //         }
  //       } else {
  //         return true;
  //       }
  //     } catch (err) {
  //       console.warn(err);
  //       return false;
  //     }
  //   };

  return (
    <View style={tailwind`flex-row px-2`}>
      <Image
        style={tailwind`w-[118px] h-[164px] rounded-lg`}
        source={{ uri: existingImageUrl || DEFAULT_PROFILE_URL }}
        PlaceholderContent={<ActivityIndicator />}
      />
      <View style={tailwind`ml-2 pt-4`}>
        <Pressable
          style={tailwind`w-10 h-10 py-[6px] mx-1 mr-4 mb-2`}
          onPress={selectFile}
        >
          <View style={tailwind`self-center`}>
            <Image
              style={tailwind`w-[32px] h-[32px]`}
              source={require("../../assets/upload-icon.png")}
              PlaceholderContent={<ActivityIndicator />}
            />
          </View>
        </Pressable>
        <Pressable
          style={tailwind`w-10 h-10 py-[6px] mx-1 mr-4`}
          onPress={() => setTakingSelfie(true)}
        >
          <View style={tailwind`self-center`}>
            <Image
              style={tailwind`w-[32px] h-[32px]`}
              source={require("../../assets/camera-icon.png")}
              PlaceholderContent={<ActivityIndicator />}
            />
          </View>
        </Pressable>
      </View>
    </View>
  );
};

ImageUpload.propTypes = {
  existingImageUrl: PropTypes.string,
  setTakingSelfie: PropTypes.func.isRequired,
  selfieImage: PropTypes.object,
};

export { ImageUpload };
