import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Dimensions, Keyboard, Platform, View } from "react-native";
import { GiftedChat } from "react-native-gifted-chat";
import tailwind from "twrnc";
import {
  CurrentUserContext,
  ThemeContext,
} from "../../base/ApplicationContext";

const SocketChat = (props) => {
  const { currentUser } = useContext(CurrentUserContext);
  const { theme } = useContext(ThemeContext);
  const windowHeight = Dimensions.get("window").height;
  console.log("Detected windowHeight as", windowHeight);
  let initialChatContainerHeight;
  switch (Platform.OS) {
    case "ios":
      initialChatContainerHeight = windowHeight - 330;
      break;
    case "android":
      initialChatContainerHeight = windowHeight - 330;
      console.log("in android", initialChatContainerHeight);
      break;
    default:
      initialChatContainerHeight = windowHeight - 120;
      break;
  }
  initialChatContainerHeight -= Platform.OS === "web" ? -60 : -120;
  const [chatContainerHeight, setChatContainerHeight] = useState(
    initialChatContainerHeight
  );

  console.log("initialChatContainerHeight", initialChatContainerHeight);

  let messages;
  let onSend;

  if (props.event) {
    messages = props.messages;
    onSend = props.onSend;
  } else {
    messages = props.route.params.messages.messages;
    onSend = props.route.params.onSend.onSend;
  }

  useEffect(() => {
    const keyboardWillShowListener = Keyboard.addListener(
      Platform.OS === "ios" ? "keyboardWillShow" : "keyboardDidShow",
      (e) => {
        console.log("keyboardWillShowListener triggered");
        const keyboardHeight = e.endCoordinates.height;
        const updatedChatContainerHeight =
          Platform.OS === "ios"
            ? windowHeight - 210
            : windowHeight - keyboardHeight - 220;
        setChatContainerHeight(updatedChatContainerHeight);
      }
    );

    const keyboardWillHideListener = Keyboard.addListener(
      Platform.OS === "ios" ? "keyboardWillHide" : "keyboardDidHide",
      () => {
        console.log("keyboardWillHideListener triggered");
        setChatContainerHeight(initialChatContainerHeight);
      }
    );

    // Clean up listeners when the component unmounts
    return () => {
      keyboardWillShowListener.remove();
      keyboardWillHideListener.remove();
    };
  }, [windowHeight, initialChatContainerHeight]);

  console.log("chatContainerHeight", chatContainerHeight);
  return (
    <View
      style={[
        tailwind`bg-[${theme.chatBackgroundColor}] w-full max-w-[800px] pt-6`,
        { height: chatContainerHeight },
      ]}
    >
      <GiftedChat
        renderUsernameOnMessage={true}
        alwaysShowSendButton={true}
        showAvatarForEveryMessage={true}
        messages={messages}
        onSend={(messages) => onSend(messages)}
        user={{
          _id: currentUser.id,
          name: currentUser.firstName,
          avatar: currentUser.profileUrl,
        }}
        scrollToBottom={true}
        bottomOffset={10}
        infiniteScroll
        inverted={false}
        alignTop={true}
        alwaysShowSend={true}
      />
    </View>
  );
};

SocketChat.propTypes = {
  route: PropTypes.object,
  event: PropTypes.object,
  messages: PropTypes.array,
  onSend: PropTypes.func,
};

export default SocketChat;
