import React, { useContext } from "react";
import PropTypes from "prop-types";
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";
import axios from "axios";
import { ActivityIndicator, SectionList, Text, View } from "react-native";
import { API_URL } from "../base/Constants";
import TagSection from "./TagSection";
import tailwind from "twrnc";
import { CurrentUserContext, ThemeContext } from "../base/ApplicationContext";

const queryClient = new QueryClient();

const TagSelector = ({ tagType, processTagSelection, tagSelection }) => {
  const { theme } = useContext(ThemeContext);

  return (
    <View style={tailwind`max-h-[300px]`}>
      <QueryClientProvider client={queryClient}>
        <View
          style={tailwind`max-w-[600px] rounded-lg bg-[${theme.tagSelectorBackgroundColor}]`}
        >
          <TagCategories
            tagType={tagType}
            processTagSelection={processTagSelection}
            tagSelection={tagSelection}
          />
        </View>
      </QueryClientProvider>
    </View>
  );
};

TagSelector.propTypes = {
  tagType: PropTypes.number.isRequired,
  processTagSelection: PropTypes.func.isRequired,
  tagSelection: PropTypes.array,
};

const TagCategories = ({ tagType, processTagSelection, tagSelection }) => {
  const { currentUser } = useContext(CurrentUserContext);
  const { theme } = useContext(ThemeContext);
  const { isLoading, error, data } = useQuery(["roottags" + tagType], () =>
    axios
      .get(API_URL + "tags/" + tagType, {
        headers: { authorization: currentUser.token },
      })
      .then((res) => res.data)
  );

  if (isLoading) return <ActivityIndicator />;
  if (error) return <Text>{"An error has occurred: " + error.message}</Text>;

  const formattedData = [
    {
      data: [data],
    },
  ];

  return (
    <SectionList
      scrollEnabled={false}
      nestedScrollEnabled={false}
      sections={formattedData}
      keyExtractor={(item) => item.id.toString()}
      renderItem={({ item }) => {
        return (
          <TagSection
            item={item}
            processTagSelection={processTagSelection}
            tagSelection={tagSelection}
          />
        );
      }}
      renderSectionHeader={({ section: { data } }) => {
        return (
          <View>
            <View style={tailwind`flex-row`}>
              <View style={tailwind`py-3`}>
                <Text
                  style={[
                    tailwind`text-[20px] font-semibold text-[${theme.tagSelectorTextColor}] ml-2`,
                    { fontFamily: "Roboto_400Regular" },
                  ]}
                >
                  {data[0].tagName}
                </Text>
              </View>
            </View>
          </View>
        );
      }}
    />
  );
};

TagCategories.propTypes = {
  tagType: PropTypes.number.isRequired,
  processTagSelection: PropTypes.func.isRequired,
  tagSelection: PropTypes.array,
};

export default TagSelector;
