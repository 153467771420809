import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import RevolutCheckout from "@revolut/checkout";
import axios from "axios";
import { API_URL } from "../base/Constants";
import { useNavigation } from "@react-navigation/native";
import { Image } from "@rneui/themed";
import tailwind from "twrnc";
import {
  CurrentUserContext,
  NavigationSyncContext,
} from "../base/ApplicationContext";

const CheckoutWebView = ({ order, setOrder }) => {
  const navigation = useNavigation();
  const { currentUser, setCurrentUser } = useContext(CurrentUserContext);
  const { setFocusedScreen } = useContext(NavigationSyncContext);
  const emailAddress = currentUser.emailAddress;
  const token = currentUser.token;
  useEffect(() => {
    checkout(
      order,
      setOrder,
      navigation,
      currentUser,
      setCurrentUser,
      setFocusedScreen
    );
  }, []);

  async function checkout(
    order,
    setOrder,
    navigation,
    currentUser,
    setCurrentUser,
    setFocusedScreen
  ) {
    const publicOrderId = order.public_id;
    const uniqueOrderId = order.id;
    const paymentId = order.paymentId;
    await RevolutCheckout(publicOrderId, "sandbox").then((RC) => {
      RC.payWithPopup({
        email: emailAddress,
        onSuccess() {
          setTimeout(
            () =>
              finishOrder(
                order,
                setOrder,
                token,
                paymentId,
                navigation,
                currentUser,
                setCurrentUser,
                setFocusedScreen
              ),
            500
          );
        },
        onError(err) {
          logRevolutPopupError(uniqueOrderId, token, paymentId, err, setOrder);
        },
        onCancel() {
          setOrder(null);
        },
      });
    });
  }

  return (
    <>
      <Image
        resizeMode="contain"
        style={tailwind`h-15 w-20`}
        source={require("../../assets/images/revolut-logo.png")}
      />
    </>
  );
};

async function logRevolutPopupError(
  uniqueOrderId,
  token,
  paymentId,
  err,
  setOrder
) {
  if (!err) return;
  axios
    .post(
      API_URL + "order/popuperror",
      {
        id: uniqueOrderId,
        paymentId: paymentId,
        error: JSON.stringify(err),
        errorMessage: err.message,
      },
      {
        headers: {
          "Content-Type": "application/json",
          authorization: token,
        },
      }
    )
    .then(() => setOrder(null));
}

async function finishOrder(
  order,
  setOrder,
  token,
  paymentId,
  navigation,
  currentUser,
  setCurrentUser,
  setFocusedScreen
) {
  axios
    .post(
      API_URL + "order/complete",
      {
        id: order.id,
        paymentId: paymentId,
      },
      {
        headers: {
          "Content-Type": "application/json",
          authorization: token,
        },
      }
    )
    .then((response) => {
      const { raw } = response.data;
      const updatedCurrentUser = currentUser;
      switch (raw.state) {
        case "COMPLETED":
        case "AUTHORISED":
        case "PROCESSED":
        case "PROCESSING":
          updatedCurrentUser.credits += order.credits;
          setCurrentUser(updatedCurrentUser);
          setFocusedScreen("HomeScreen");
          navigation.navigate("HomeScreen");
          break;
        default:
          setFocusedScreen("BundleScreen");
          navigation.navigate("BundleScreen");
          break;
      }
    })
    .catch((error) => {
      alert(
        "There was a problem with completing your order.  Please try again."
      );
      console.log(error);
      setOrder(null);
    });
}

CheckoutWebView.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string.isRequired,
    public_id: PropTypes.string.isRequired,
    paymentId: PropTypes.number.isRequired,
  }).isRequired,
  setOrder: PropTypes.func.isRequired,
};

export { CheckoutWebView };
