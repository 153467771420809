import { useNavigation } from "@react-navigation/native";
import React, { useContext, useEffect } from "react";
import { FlatList, Pressable, View, Text, Platform } from "react-native";
import { Image } from "@rneui/themed";
import tailwind from "twrnc";
import { USER_TYPE_AMBASSADOR, USER_TYPE_PROVIDER } from "../base/Constants";
import {
  CurrentUserContext,
  NavigationSyncContext,
  ThemeContext,
} from "../base/ApplicationContext";

const NavigationFooterBar = (props) => {
  console.log(props);
  const {
    currentUser,
    outstandingConnectionCount,
    outstandingNotificationCount,
  } = useContext(CurrentUserContext);
  const userType = currentUser.type;
  const { theme } = useContext(ThemeContext);
  const { focusedScreen, setFocusedScreen } = useContext(NavigationSyncContext);
  const navigation = useNavigation();

  useEffect(() => {}, [focusedScreen]);

  const data = [
    {
      id: "1",
      title: "Home",
      screen: "HomeScreen",
      name: "home",
      icon: require("../../assets/home-icon.png"),
      color:
        focusedScreen === "HomeScreen"
          ? theme.footerNavigationIconActiveColor
          : theme.footerNavigationIconColor,
    },
    {
      id: "10",
      title: "Calendar",
      screen: "CalendarScreen",
      name: "calendar",
      icon: require("../../assets/calendar-icon.png"),
      color:
        focusedScreen === "CalendarScreen"
          ? theme.footerNavigationIconActiveColor
          : theme.footerNavigationIconColor,
    },
    {
      id: "25",
      title: "Messages",
      screen: "MessagingScreen",
      name: "message-circle",
      icon: require("../../assets/messages-icon.png"),
      color:
        focusedScreen === "MessagingScreen"
          ? theme.footerNavigationIconActiveColor
          : theme.footerNavigationIconColor,
    },
    {
      id: "30",
      title: "Network",
      screen: "NetworkScreen",
      name: "users",
      badge: outstandingConnectionCount,
      icon: require("../../assets/connections-icon.png"),
      color:
        focusedScreen === "NetworkScreen"
          ? theme.footerNavigationIconActiveColor
          : theme.footerNavigationIconColor,
    },
    {
      id: "40",
      title: "Notifications",
      screen: "NotificationsScreen",
      name: "bell",
      badge: outstandingNotificationCount,
      icon: require("../../assets/notifications-icon.png"),
      color:
        focusedScreen === "NotificationsScreen"
          ? theme.footerNavigationIconActiveColor
          : theme.footerNavigationIconColor,
    },
  ];

  const dataProvider = [
    {
      id: "1",
      title: "Home",
      screen: "HomeScreen",
      name: "home",
      icon: require("../../assets/home-icon.png"),
      color:
        focusedScreen === "HomeScreen"
          ? theme.footerNavigationIconActiveColor
          : theme.footerNavigationIconColor,
    },
    {
      id: "10",
      title: "Calendar",
      screen: "CalendarScreen",
      name: "calendar",
      icon: require("../../assets/calendar-icon.png"),
      color:
        focusedScreen === "CalendarScreen"
          ? theme.footerNavigationIconActiveColor
          : theme.footerNavigationIconColor,
    },
    {
      id: "25",
      title: "Messages",
      screen: "MessagingScreen",
      name: "message-circle",
      icon: require("../../assets/messages-icon.png"),
      color:
        focusedScreen === "MessagingScreen"
          ? theme.footerNavigationIconActiveColor
          : theme.footerNavigationIconColor,
    },
    {
      id: "30",
      title: "Network",
      screen: "NetworkScreen",
      name: "users",
      badge: outstandingConnectionCount,
      icon: require("../../assets/connections-icon.png"),
      color:
        focusedScreen === "NetworkScreen"
          ? theme.footerNavigationIconActiveColor
          : theme.footerNavigationIconColor,
    },
    {
      id: "40",
      title: "Notifications",
      screen: "NotificationsScreen",
      name: "bell",
      badge: outstandingNotificationCount,
      icon: require("../../assets/notifications-icon.png"),
      color:
        focusedScreen === "NotificationsScreen"
          ? theme.footerNavigationIconActiveColor
          : theme.footerNavigationIconColor,
    },
  ];

  return (
    <FlatList
      data={
        userType === USER_TYPE_PROVIDER || userType === USER_TYPE_AMBASSADOR
          ? dataProvider
          : data
      }
      keyExtractor={(item) => item.id}
      numColumns={5}
      scrollEnabled={false}
      style={tailwind`bg-[${theme.navigationDrawerBackgroundColor}]`}
      renderItem={({ item }) => (
        <Pressable
          style={
            Platform.OS === "ios"
              ? tailwind`pl-2 pt-5 w-1/5`
              : tailwind`pt-5 w-1/5`
          }
          onPress={() => {
            setFocusedScreen(item.screen);
            navigation.navigate(item.screen);
          }}
        >
          <View>
            {item.badge ? (
              <View
                style={tailwind`absolute top--3 ${
                  Platform.OS === "ios" ? "right-4" : "right-7"
                } rounded-full w-[15px] h-[15px] bg-[${
                  theme.notificationBadgeBackgroundColor
                }]`}
              >
                <Text
                  style={tailwind`text-[8px] self-center pt-[2px] text-[${theme.notificationBadgeTextColor}]`}
                >
                  {item.badge}
                </Text>
              </View>
            ) : null}
            <Image
              resizeMode="contain"
              style={tailwind`h-[18px] w-[18px] ml-[26px]`}
              source={item.icon}
              tintColor={item.color}
            />
            {Platform.isTV && (
              <Text style={tailwind`pt-1 text-center`}>{item.title}</Text>
            )}
          </View>
        </Pressable>
      )}
    />
  );
};

export default NavigationFooterBar;
