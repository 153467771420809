import React from "react";
import PropTypes from "prop-types";
import { View } from "react-native";
import tailwind from "twrnc";
import { StyledInput } from "../base/StyledInput";
import {
  analyzeFirstName,
  analyzeLastName,
  analyzePhoneNumber,
} from "../base/Validation";

const EditPersonalDetailsForm = ({
  emailAddress,
  firstName,
  setFirstName,
  firstNameStatus,
  lastName,
  setLastName,
  lastNameStatus,
  phoneNumber,
  setPhoneNumber,
  phoneNumberStatus,
}) => {
  return (
    <>
      <View style={tailwind`mb-4 px-2 self-center w-full mt-2`}>
        <View style={tailwind`flex-row`}>
          <View style={tailwind`w-1/2`}>
            <StyledInput
              label={"First Name"}
              placeholder={"Enter your first name"}
              value={firstName}
              onChangeText={(text) => {
                setFirstName(text);
                analyzeFirstName(text, firstNameStatus);
              }}
              fieldStatus={firstNameStatus}
            />
          </View>
          <View style={tailwind`w-1/2`}>
            <StyledInput
              label={"Last Name"}
              placeholder={"Enter your last name"}
              value={lastName}
              onChangeText={(text) => {
                setLastName(text);
                analyzeLastName(text, lastNameStatus);
              }}
              fieldStatus={lastNameStatus}
            />
          </View>
        </View>
        <View style={tailwind`flex-row mt-4`}>
          <View style={tailwind`w-1/2`}>
            <StyledInput
              label={"Email Address"}
              disabled={true}
              placeholder={"someone@somewhere.net"}
              value={emailAddress}
              onChangeText={() => null}
            />
          </View>
          <View style={tailwind`w-1/2`}>
            <StyledInput
              label={"Phone Number"}
              multiline={false}
              inputStyle={tailwind`text-base bg-white rounded-lg pl-1`}
              containerStyle={tailwind`max-w-[220px]`}
              placeholder={"+441604123456"}
              autoComplete={"off"}
              autoFocus={false}
              value={phoneNumber}
              autoCorrect={false}
              onChangeText={(text) => {
                setPhoneNumber(text);
                analyzePhoneNumber(text, phoneNumberStatus);
              }}
              fieldStatus={phoneNumberStatus}
            />
          </View>
        </View>
      </View>
    </>
  );
};

EditPersonalDetailsForm.propTypes = {
  emailAddress: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  setFirstName: PropTypes.func.isRequired,
  firstNameStatus: PropTypes.object.isRequired,
  lastName: PropTypes.string.isRequired,
  setLastName: PropTypes.func.isRequired,
  lastNameStatus: PropTypes.object.isRequired,
  phoneNumber: PropTypes.string,
  setPhoneNumber: PropTypes.func.isRequired,
  phoneNumberStatus: PropTypes.object.isRequired,
};

export { EditPersonalDetailsForm };
