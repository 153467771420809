import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Platform, Text, View } from "react-native";
import {
  REPEAT_TYPE_DAILY,
  REPEAT_TYPE_MONTHLY,
  REPEAT_TYPE_NONE,
  REPEAT_TYPE_WEEKLY,
} from "../base/Constants";
import tailwind from "twrnc";
import { StyledPicker } from "../base/StyledPicker";
import { StyledCheckBox } from "./StyledCheckBox";
import Button from "../base/Button";
import dayjs from "dayjs";
import { ThemeContext } from "../base/ApplicationContext";
import { Roboto_400Regular, useFonts } from "@expo-google-fonts/roboto";

const RecurrencePanel = ({
  startDate,
  repeatType,
  repeatStartDate,
  openRepeatStartDatePicker,
  repeatEndDate,
  openRepeatEndDatePicker,
  setRepeatType,
  isMonday,
  setIsMonday,
  isTuesday,
  setIsTuesday,
  isWednesday,
  setIsWednesday,
  isThursday,
  setIsThursday,
  isFriday,
  setIsFriday,
  isSaturday,
  setIsSaturday,
  isSunday,
  setIsSunday,
  dayOfMonth,
  setDayOfMonth,
}) => {
  const { theme } = useContext(ThemeContext);
  const [dayOfWeekLabel, setDayOfWeekLabel] = useState("");

  useEffect(() => {
    setDayOfWeekLabel(dayjs(startDate).format("dddd"));
  }, [startDate]);

  let fonts = useFonts({ Roboto_400Regular });
  if (!fonts) return;

  return (
    <View
      style={tailwind`${
        Platform.OS === "web" ? "w-1/4" : "w-[98%]"
      } p-2 rounded-lg`}
    >
      <View style={tailwind`mr-4`}>
        <Text
          style={[
            tailwind`text-[${theme.textColor}] font-semibold mb-1`,
            { fontFamily: "Roboto_400Regular" },
          ]}
        >
          Repeats
        </Text>
        <StyledPicker
          selectedValue={repeatType}
          onValueChange={(itemValue) => setRepeatType(parseInt(itemValue))}
          items={[
            {
              label: "Never",
              value: REPEAT_TYPE_NONE,
            },
            {
              label: "Daily",
              value: REPEAT_TYPE_DAILY,
            },
            {
              label: "Weekly",
              value: REPEAT_TYPE_WEEKLY,
            },
            {
              label: "Monthly",
              value: REPEAT_TYPE_MONTHLY,
            },
          ]}
        />
      </View>
      {repeatType === REPEAT_TYPE_MONTHLY ? (
        <>
          <Text
            style={[
              tailwind`text-[${theme.textColor}] font-semibold mb-1 mt-2`,
              { fontFamily: "Roboto_400Regular" },
            ]}
          >
            on the
          </Text>
          <StyledPicker
            onValueChange={(itemValue) => setDayOfMonth(parseInt(itemValue))}
            selectedValue={dayOfMonth}
            items={[
              {
                label: "1st",
                value: 1,
              },
              {
                label: "2nd",
                value: 2,
              },
              {
                label: "3rd",
                value: 3,
              },
              {
                label: "4th",
                value: 4,
              },
              {
                label: "5th",
                value: 5,
              },
              {
                label: "6th",
                value: 6,
              },
              {
                label: "7th",
                value: 7,
              },
              {
                label: "8th",
                value: 8,
              },
              {
                label: "9th",
                value: 9,
              },
              {
                label: "10th",
                value: 10,
              },
              {
                label: "11th",
                value: 11,
              },
              {
                label: "12th",
                value: 12,
              },
              {
                label: "13th",
                value: 13,
              },
              {
                label: "14th",
                value: 14,
              },
              {
                label: "15th",
                value: 15,
              },
              {
                label: "16th",
                value: 16,
              },
              {
                label: "17th",
                value: 17,
              },
              {
                label: "18th",
                value: 18,
              },
              {
                label: "19th",
                value: 19,
              },
              {
                label: "20th",
                value: 20,
              },
              {
                label: "21st",
                value: 21,
              },
              {
                label: "22nd",
                value: 22,
              },
              {
                label: "23rd",
                value: 23,
              },
              {
                label: "24th",
                value: 24,
              },
              {
                label: "25th",
                value: 25,
              },
              {
                label: "26th",
                value: 26,
              },
              {
                label: "27th",
                value: 27,
              },
              {
                label: "28th",
                value: 28,
              },
            ]}
          />
        </>
      ) : null}
      {repeatType === REPEAT_TYPE_WEEKLY ? (
        <Text
          style={[
            tailwind`text-[${theme.textColor}] font-semibold mt-2 mb-4`,
            { fontFamily: "Roboto_400Regular" },
          ]}
        >
          on {dayOfWeekLabel}s
        </Text>
      ) : null}
      {repeatType === REPEAT_TYPE_DAILY ? (
        <View>
          <Text
            style={[
              tailwind`text-[${theme.textColor}] font-semibold mt-2`,
              { fontFamily: "Roboto_400Regular" },
            ]}
          >
            on the following days
          </Text>
          <View style={tailwind`flex-row`}>
            <StyledCheckBox
              title={"M"}
              checked={isMonday}
              onPress={() => setIsMonday(!isMonday)}
            />
            <StyledCheckBox
              title={"T"}
              checked={isTuesday}
              onPress={() => setIsTuesday(!isTuesday)}
            />
            <StyledCheckBox
              title={"W"}
              checked={isWednesday}
              onPress={() => setIsWednesday(!isWednesday)}
            />
            <StyledCheckBox
              title={"T"}
              checked={isThursday}
              onPress={() => setIsThursday(!isThursday)}
            />
            <StyledCheckBox
              title={"F"}
              checked={isFriday}
              onPress={() => setIsFriday(!isFriday)}
            />
            <StyledCheckBox
              title={"S"}
              checked={isSaturday}
              onPress={() => setIsSaturday(!isSaturday)}
              textColour={theme.redColor}
            />
            <StyledCheckBox
              title={"S"}
              checked={isSunday}
              onPress={() => setIsSunday(!isSunday)}
              textColour={theme.redColor}
            />
          </View>
        </View>
      ) : null}
      {repeatType !== REPEAT_TYPE_NONE ? (
        <>
          <View>
            <Text
              style={[
                tailwind`text-[${theme.textColor}] font-semibold mb-1`,
                { fontFamily: "Roboto_400Regular" },
              ]}
            >
              Starting from
            </Text>
            <View style={tailwind`self-center`}>
              <Button
                title={dayjs(repeatStartDate).format("ddd MMM DD")}
                onPress={openRepeatStartDatePicker}
              />
            </View>
          </View>
          <View>
            <Text
              style={[
                tailwind`text-[${theme.textColor}] font-semibold mb-1`,
                { fontFamily: "Roboto_400Regular" },
              ]}
            >
              Ending on
            </Text>
            <View style={tailwind`self-center`}>
              <Button
                title={dayjs(repeatEndDate).format("ddd MMM DD")}
                onPress={openRepeatEndDatePicker}
              />
            </View>
          </View>
        </>
      ) : null}
    </View>
  );
};

RecurrencePanel.propTypes = {
  startDate: PropTypes.instanceOf(Date).isRequired,
  repeatType: PropTypes.number.isRequired,
  repeatStartDate: PropTypes.instanceOf(Date),
  openRepeatStartDatePicker: PropTypes.func.isRequired,
  repeatEndDate: PropTypes.instanceOf(Date),
  openRepeatEndDatePicker: PropTypes.func.isRequired,
  setRepeatType: PropTypes.func.isRequired,
  isMonday: PropTypes.bool.isRequired,
  setIsMonday: PropTypes.func.isRequired,
  isTuesday: PropTypes.bool.isRequired,
  setIsTuesday: PropTypes.func.isRequired,
  isWednesday: PropTypes.bool.isRequired,
  setIsWednesday: PropTypes.func.isRequired,
  isThursday: PropTypes.bool.isRequired,
  setIsThursday: PropTypes.func.isRequired,
  isFriday: PropTypes.bool.isRequired,
  setIsFriday: PropTypes.func.isRequired,
  isSaturday: PropTypes.bool.isRequired,
  setIsSaturday: PropTypes.func.isRequired,
  isSunday: PropTypes.bool.isRequired,
  setIsSunday: PropTypes.func.isRequired,
  dayOfMonth: PropTypes.number.isRequired,
  setDayOfMonth: PropTypes.func.isRequired,
};

export { RecurrencePanel };
