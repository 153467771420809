import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Dimensions } from "react-native";
import * as ScreenOrientation from "expo-screen-orientation";
import { WebLayout } from "../components/view-live-screen/WebLayout";

const ViewLiveScreen = (props) => {
  const defaultDimensions = {
    height: Dimensions.get("window").height,
    width: Dimensions.get("window").width,
  };
  const defaultOrientation =
    defaultDimensions.width < defaultDimensions.height
      ? "portrait"
      : "landscape";

  const [orientation, setOrientation] = useState(defaultOrientation);

  const getOrientation = (orientation) => {
    if (
      orientation === ScreenOrientation.Orientation.LANDSCAPE_LEFT ||
      orientation === ScreenOrientation.Orientation.LANDSCAPE_RIGHT
    ) {
      return "landscape";
    }
    return "portrait";
  };

  const { event } = props.route.params;

  useEffect(() => {
    ScreenOrientation.addOrientationChangeListener(({ orientationInfo }) => {
      setTimeout(() => {
        setOrientation(getOrientation(orientationInfo.orientation));
      }, 300);
    });
  }, [orientation]);

  return <WebLayout event={event} />;
};

ViewLiveScreen.propTypes = {
  route: PropTypes.object.isRequired,
};

export default ViewLiveScreen;
