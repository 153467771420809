import React, { useContext } from "react";
import { Platform, ScrollView, View } from "react-native";
import tailwind from "twrnc";
import ConnectionsPanel from "../components/network/ConnectionsPanel";
import { ThemeContext } from "../components/base/ApplicationContext";

const NetworkScreen = () => {
  const { theme } = useContext(ThemeContext);
  return (
    <>
      {Platform.OS === "web" ? (
        <ScrollView style={tailwind`flex h-full bg-[${theme.backgroundColor}]`}>
          <ConnectionsPanel />
        </ScrollView>
      ) : (
        <View style={tailwind`flex h-full bg-[${theme.backgroundColor}]`}>
          <ConnectionsPanel />
        </View>
      )}
    </>
  );
};

export default NetworkScreen;
