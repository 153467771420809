import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Text, View, ActivityIndicator, SectionList } from "react-native";
import {
  useQuery,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import axios from "axios";

import tailwind from "twrnc";
import ConnectionThumbnail from "./ConnectionThumbnail";
import {
  API_URL,
  CONNECTION_STATUS_BLOCKED,
  CONNECTION_STATUS_CONNECTED,
  CONNECTION_STATUS_IGNORED,
  CONNECTION_STATUS_REQUESTED,
  USER_TYPE_AMBASSADOR,
  USER_TYPE_PROVIDER,
} from "../base/Constants";
import { CurrentUserContext, ThemeContext } from "../base/ApplicationContext";
import { Roboto_700Bold, useFonts } from "@expo-google-fonts/roboto";

const queryClient = new QueryClient();

const ConnectionsPanel = () => {
  const { currentUser } = useContext(CurrentUserContext);
  const handleStatusChange = () => {
    queryClient.setQueryData(["connections", currentUser.id], () => {
      queryClient.invalidateQueries("connections" + currentUser.id);
    });
  };

  let fonts = useFonts({ Roboto_700Bold });

  if (!fonts) return;

  return (
    <QueryClientProvider client={queryClient}>
      <View style={tailwind``}>
        <Connections handleStatusChange={handleStatusChange} />
      </View>
    </QueryClientProvider>
  );
};

// function CurrentFollowing() {}

// function CurrentFollowers() {}

// function SuggestedConnections() {}

const Connections = ({ handleStatusChange }) => {
  const { currentUser } = useContext(CurrentUserContext);
  const { theme } = useContext(ThemeContext);
  const userId = currentUser.id;
  const { isLoading, error, data } = useQuery(["connections" + userId], () =>
    axios
      .get(API_URL + "userconnection/connections/" + userId, {
        headers: { authorization: currentUser.token },
      })
      .then((res) => res.data)
  );

  if (isLoading) return <ActivityIndicator />;
  if (error) return <Text>An error has occurred: ${error.message}</Text>;

  const formattedData = [
    {
      title: "Requested",
      data: data.filter((item) => {
        return item.status === CONNECTION_STATUS_REQUESTED;
      }),
    },
    {
      title: "Connected",
      data: data.filter((item) => {
        return item.status === CONNECTION_STATUS_CONNECTED;
      }),
    },
  ];

  if (
    currentUser.type === USER_TYPE_PROVIDER ||
    currentUser.type === USER_TYPE_AMBASSADOR
  ) {
    formattedData.push({
      title: "Ignored",
      data: data.filter((item) => {
        return item.status === CONNECTION_STATUS_IGNORED;
      }),
    });
  }
  formattedData.push({
    title: "Blocked",
    data: data.filter((item) => {
      return item.status === CONNECTION_STATUS_BLOCKED;
    }),
  });

  return (
    <SectionList
      sections={formattedData}
      keyExtractor={(item) => item.id}
      renderSectionHeader={({ section: { title } }) => {
        return (
          <View
            style={tailwind`w-full pt-4 py-1 border-b-[1px] border-[${theme.connectionSectionTitleBottomBorderColor}] bg-[${theme.backgroundColor}]`}
          >
            <Text
              style={[
                tailwind`font-semibold text-[${theme.textColor}] self-center`,
                { fontFamily: "Roboto_700Bold" },
              ]}
            >
              {title}
            </Text>
          </View>
        );
      }}
      renderItem={({ item }) => {
        return item.destinationUser.id === currentUser.id ? (
          <ConnectionThumbnail
            key={item.id}
            connectionId={item.id}
            userId={item.sourceUser.id}
            firstName={item.sourceUser.firstName}
            lastName={item.sourceUser.lastName}
            profileUrl={item.sourceUser.profileUrl}
            status={item.status}
            userType={item.sourceUser.type.id}
            isSourceUser={false}
            blockerUserId={(item.blockerUser && item.blockerUser.id) || null}
            handleStatusChange={handleStatusChange}
            roomId={item.roomId}
          />
        ) : (
          <ConnectionThumbnail
            key={item.id}
            connectionId={item.id}
            userId={item.destinationUser.id}
            firstName={item.destinationUser.firstName}
            lastName={item.destinationUser.lastName}
            profileUrl={item.destinationUser.profileUrl}
            status={item.status}
            userType={item.destinationUser.type.id}
            isSourceUser={true}
            blockerUserId={(item.blockerUser && item.blockerUser.id) || null}
            handleStatusChange={handleStatusChange}
            roomId={item.roomId}
            isProviding={item.destinationUser.isProvidingPersonalTraining}
          />
        );
      }}
    />
  );
};

Connections.propTypes = {
  handleStatusChange: PropTypes.func.isRequired,
};

export default ConnectionsPanel;
