import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { Text, View } from "react-native";
import tailwind from "twrnc";
import Button from "../components/base/Button";
import axios from "axios";
import { API_URL } from "../components/base/Constants";
import { CheckoutWebView } from "../components/checkout/CheckoutWebView";
import {
  CurrentUserContext,
  ThemeContext,
} from "../components/base/ApplicationContext";
import HorizontalRule from "../components/base/HorizontalRule";

const OrderSummaryScreen = (props) => {
  const { bundleId, bundles } = props.route.params;
  const { currentUser } = useContext(CurrentUserContext);
  const { theme } = useContext(ThemeContext);
  const [order, setOrder] = useState();

  const selectedBundle = bundles.filter((bundle) => bundle.id === bundleId)[0];
  const credits =
    selectedBundle.credits === -1 ? "Unlimited" : selectedBundle.credits;
  const currencySymbol = currentUser.currencySymbol;

  const price = Number(selectedBundle.price);
  const tax = (20 / 100) * price; //temp
  const total = price + tax;
  const priceDisplay = price.toFixed(2);
  const taxDisplay = tax.toFixed(2);
  const totalDisplay = total.toFixed(2);

  return (
    <View style={tailwind`pt-10 w-full h-full bg-[${theme.backgroundColor}]`}>
      <View style={tailwind`max-w-[800px] min-w-[300px] self-center`}>
        {!order ? (
          <>
            <View style={tailwind`mb-4`}>
              <Text
                style={tailwind`self-center font-semibold text-[${theme.textColor}]`}
              >
                Order Summary
              </Text>
            </View>
            <View style={tailwind`px-4 pb-8`}>
              <View style={tailwind`flex-row pb-4`}>
                <View style={tailwind`grow`}>
                  <Text
                    style={tailwind`text-[${theme.textColor}] font-semibold`}
                  >
                    Bundle
                  </Text>
                </View>
                <View>
                  <Text style={tailwind`text-[${theme.textColor}]`}>
                    {credits} Credits
                  </Text>
                </View>
              </View>
              <View style={tailwind`flex-row`}>
                <View style={tailwind`grow`}>
                  <Text
                    style={tailwind`text-[${theme.textColor}] font-semibold`}
                  >
                    Price
                  </Text>
                </View>
                <View>
                  <Text style={tailwind`text-[${theme.textColor}]`}>
                    {currencySymbol}
                    {priceDisplay}
                  </Text>
                </View>
              </View>
              <View style={tailwind`flex-row`}>
                <View style={tailwind`grow`}>
                  <Text
                    style={tailwind`text-[${theme.textColor}] font-semibold`}
                  >
                    Tax
                  </Text>
                </View>
                <View>
                  <Text style={tailwind`text-[${theme.textColor}]`}>
                    {currencySymbol}
                    {taxDisplay}
                  </Text>
                </View>
              </View>
              <HorizontalRule />
              <View style={tailwind`flex-row mt-4 py-4`}>
                <View style={tailwind`grow`}>
                  <Text style={tailwind`text-[${theme.textColor}] font-bold`}>
                    Total
                  </Text>
                </View>
                <View>
                  <Text style={tailwind`text-[${theme.textColor}] font-bold`}>
                    {currencySymbol}
                    {totalDisplay}
                  </Text>
                </View>
              </View>
              <HorizontalRule />
            </View>
            <View style={tailwind`self-center`}>
              <Button
                title="Pay"
                onPress={() => PrepareOrder(setOrder, currentUser, bundleId)}
              />
            </View>
          </>
        ) : (
          <>
            <View style={tailwind`mb-4`}>
              <Text
                style={tailwind`self-center font-semibold text-[${theme.textColor}]`}
              >
                Complete Order
              </Text>
            </View>
            <View style={tailwind`self-center`}>
              <CheckoutWebView order={order} setOrder={setOrder} />
            </View>
          </>
        )}
      </View>
    </View>
  );
};

OrderSummaryScreen.propTypes = {
  route: PropTypes.shape({
    params: PropTypes.shape({
      bundleId: PropTypes.number.isRequired,
      bundles: PropTypes.any.isRequired,
    }),
  }),
};

function PrepareOrder(setOrder, currentUser, bundleId) {
  axios
    .post(
      API_URL + "order/prepare",
      {
        id: bundleId,
      },
      {
        headers: {
          "Content-Type": "application/json",
          authorization: currentUser.token,
        },
      }
    )
    .then((revolutResponse) => {
      setOrder(revolutResponse.data);
    })
    .catch((error) => {
      console.log(error);
    });
}

export { OrderSummaryScreen };
