import React, { useState, useEffect } from "react";
import { StatusBar } from "expo-status-bar";
import { SafeAreaProvider, SafeAreaView } from "react-native-safe-area-context";
import NavigationDrawers from "../navigation/NavigationDrawers";
import NavigationFooterBar from "../navigation/NavigationFooterBar";
import { NavigationContainer } from "@react-navigation/native";
import * as ScreenOrientation from "expo-screen-orientation";
import { Dimensions, Platform, View } from "react-native";
import { SlideInRight } from "react-native-reanimated";
import tailwind from "twrnc";
import { BottomSheet } from "@rneui/themed";
import {
  BottomSheetContext,
  CurrentUserContext,
  FullScreenContext,
  NavigationSyncContext,
  OrientationContext,
  ThemeContext,
} from "./ApplicationContext";
import HeaderLogin from "./HeaderLogin";

const ApplicationContainer = () => {
  const [currentUser, setCurrentUser] = useState({});
  const [searchQuery, setSearchQuery] = useState();
  const [mobileFullScreen, setMobileFullScreen] = useState(null);
  const [bottomSheetContent, setBottomSheetContent] = useState();
  const [bottomSheetVisible, setBottomSheetVisible] = useState(false);
  const [outstandingNotificationCount, setOutstandingNotificationCount] =
    useState(0);
  const [outstandingMessageCount, setOutstandingMessageCount] = useState(0);
  const [outstandingConnectionCount, setOutstandingConnectionCount] =
    useState(0);
  const [liveStreamCredits, setLiveStreamCredits] = useState();
  const [isProfileComplete, setIsProfileComplete] = useState();

  const defaultTheme = {
    //Content
    backgroundColor: "#EEECEA",
    textColor: "#231710",
    primaryButtonColor: "#116D6E",
    iconColor: "#231710",
    redColor: "#EC5E4E",
    greenColor: "#44DF44",

    //Registration Screen
    registrationUserTypeSliderTextColor: "#231710",
    registrationUserTypeSliderActiveColor: "#FFFFFF",
    registrationUserTypeButtonColor: "#EC5E4E",
    registrationUserTypeBackgroundColor: "#FFFFFF",

    //Tag Selector
    tagSelectorBackgroundColor: "#EEECEA",
    tagSelectorTextColor: "#231710",
    tagSelectorCheckedTextColor: "#EC5E4E",

    //Navigation Drawer
    navigationDrawerBackgroundColor: "#321D1C",
    navigationDrawerTextColor: "#FFFFFF",
    navigationDrawerActiveTintColor: "#321D1C",
    navigationDrawerIconColor: "#FFFFFF",

    //Navigation Footer
    footerNavigationIconColor: "#998E8E",
    footerNavigationIconActiveColor: "#FFFFFF",

    //Live thumbnail
    liveDotColor: "#EB6767",
    liveThumbnailBackgroundColor: "#FCA35E",

    //Trainer thumbnail
    trainerThumbnailBackgroundColor: "#FFFFFF",

    //On Demand Thumbnail
    onDemandProfileBorderColor: "#FFFFFF",

    //Common thumbnail
    timePillTextColor: "#FFFFFF",
    timePillBackgoundColor: "#777777",

    //Tags
    tagBackgroundColor: "#11C44C",
    tagTextColor: "#FFFFFF",
    tagBackgroundColorWhite: "#E9E9E9",
    tagTextColorWhite: "#321D1C",
    tagBackgoundColorPurple: "#542CA8",
    tagTextColorPurple: "#FFFFFF",
    tagTextColorOrange: "#D08B05",
    tagBackgroundColorOrange: "#E8E1D7",
    tagTextColorRed: "#EE561E",
    tagBackgroundColorRed: "#FCE6DE",
    tagTextColorBlack: "#FFFFFF",
    tagBackgroundColorBlack: "#321D1C",
    tagTextColorYellow: "#321D1C",
    tagBackgroundColorYellow: "#E2D55B",
    tagTextColorPink: "#321D1C",
    tagBackgroundColorPink: "#E0AADB",
    tagTextColorGreen: "#11C44C",
    tagBackgroundColorGreen: "#D7F9E2",
    tagTextColorBlue: "#1E63EE",
    tagBackgroundColorBlue: "#DEE8FC",
    tagTextColorViolet: "#F3E8F8",
    tagBackgroundColorViolet: "#D087B4",

    //WebScroll
    webScrollArrorBackgroundColor: "#DDDDDD",
    webScrollArrorColor: "#321D1C",

    //Calendar
    calendarDayHeadingBackgroundColor: "#EC5E4E",
    calendarDayHeadingBackgroundInactiveColor: "#CCCCCC",
    calendarDayHeadingBorderColor: "#FFFFFF",
    calendarDayHeadingTextColor: "#FFFFFF",
    calendarDayHeadingTextInactiveColor: "#321D1C",
    calendarEventBackgroundColor: "#FFFFFF",
    calendarEventBorderColor: "#CCCCCC",
    calendarAddIconBorderColor: "#EC5E4E",

    //Calendar Event Types
    calendarEventTextColor: "#231710",
    calendarEventBroadcastColor: "#FCA35E",
    calendarEventBookableColor: "#116D6E",
    calendarEventPrivateColor: "#31D6AF",
    calendarEventOtherColor: "#321D1C",

    //View Event
    viewEventHeadingTextColor: "#231710",

    //Horizontal Rule
    horizontalRuleColor: "#231710",

    //Difficulty
    difficultyBeginnerColor: "#BFFFBF",
    difficultyIntermediateColor: "#EEEE93",
    difficultyAdvancedColor: "#FF7575",
    difficultyAllColor: "#9FEF9F",
    difficultyTextColour: "#231710",

    //Bundle Screen
    bundleItemBackgroundColor: "#FFFFFF",
    bundleItemSelectedBackgroundColor: "#EC5E4E",

    //Tab Bar
    tabBarBackgroundColor: "#321D1C",
    tabBarActiveTintColor: "#EC5E4E",
    tabBarInactiveTintColor: "#FFFFFF",
    tabBarIndicatorColor: "#EC5E4E",

    //Socket Chat
    chatBackgroundColor: "#FFFFFF",

    //Attendee List
    attendeeListBackgroundColor: "#FFFFFF",
    inviteeListBackgroundColor: "#FFFFFF",
    attendeeListSelectedBackgroundColor: "#EC5E4E",
    attendeeListSelectedTextColor: "#FFFFFF",

    //PT Chat
    videoControlsBackgroundColor: "#CCCCCC",

    //Provider Settings Tab
    providerSettingsBackgroundColor: "#FFFFFF",

    //Mobile chat tab background
    mobileChatTabBackgroundColor: "#321D1C",
    mobileChatTabIconColor: "#321D1C",
    mobileChatVideoControlIconColor: "#321D1C",

    //Offline Messaging
    chatConnectionActiveBackgroundColor: "#EC5E4E",
    chatConnectionActiveTextColor: "#FFFFFF",
    chatWindowHeadingBottomBorderColor: "#EC5E4E",

    //Connections Screen
    connectionThumbnailBackgroundColor: "#FFFFFF",
    connectionThumbnailIconBorderColor: "#EC5E4E",
    connectionThumbnailIconBackgroundColor: "#EEECEA",
    connectionSectionTitleBottomBorderColor: "#EC5E4E",
    connectionThumbnailIconColor: "#321D1C",

    //Notifications Screen
    notificationItemBackgroundColor: "#FFFFFF",

    //Profile Selfie Panel
    selfieTakePhotoIconColor: "#FFFFFF",
    selfieCloseIconColor: "#321D1C",

    //Edit Profile Screen
    pTEnabledSwitchThumbColor: "#EC5E4E",
    pTEnabledSwitchTrackColorFalse: "#EC5E4E",
    pTEnabledSwitchTrackColorTrue: "#FFFFFF",

    //Bundles Screen
    bundleAddIconColor: "#321D1C",
    bundleDeleteIconColor: "#321D1C",

    //Search screen
    searchModalTopColor: "#EC5E4E",
    searchModalTextColor: "#FFFFFF",

    //Provider Calendar
    providerCalendarTopColor: "#EC5E4E",
    providerCalendarTextColor: "#FFFFFF",

    //Warning Messages
    warningMessageBackgroundColor: "#FFFFFF",

    //Notification badges
    notificationBadgeTextColor: "#EC5E4E",
    notificationBadgeBackgroundColor: "#FFFFFF",
  };

  const [theme, setTheme] = useState(defaultTheme);

  const defaultDimensions = {
    height: Dimensions.get("window").height,
    width: Dimensions.get("window").width,
  };
  const defaultOrientation =
    defaultDimensions.width < defaultDimensions.height
      ? "portrait"
      : "landscape";
  const getOrientation = (orientation) => {
    if (
      orientation === ScreenOrientation.Orientation.LANDSCAPE_LEFT ||
      orientation === ScreenOrientation.Orientation.LANDSCAPE_RIGHT
    ) {
      return "landscape";
    }
    return "portrait";
  };

  const [orientation, setOrientation] = useState(defaultOrientation);
  const [loggedIn, setLoggedIn] = useState(false);
  const shouldShowHeader =
    (orientation === "portrait" || Platform.OS === "web") && !mobileFullScreen;

  const [focusedScreen, setFocusedScreen] = useState("HomeScreen");

  useEffect(() => {
    ScreenOrientation.addOrientationChangeListener(({ orientationInfo }) => {
      setTimeout(() => {
        setOrientation(getOrientation(orientationInfo.orientation));
      }, 300);
    });
  }, [orientation]);

  useEffect(() => {
    if (currentUser && currentUser.authenticated) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, [currentUser]);

  const shouldShowNavigationFooter =
    Platform.isTV ||
    Platform.OS === "web" ||
    (Platform.OS !== "web" && !Platform.isTV && mobileFullScreen)
      ? null
      : loggedIn;

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <OrientationContext.Provider value={{ orientation, setOrientation }}>
        <FullScreenContext.Provider
          value={{ mobileFullScreen, setMobileFullScreen }}
        >
          <CurrentUserContext.Provider
            value={{
              currentUser,
              setCurrentUser,
              loggedIn,
              setLoggedIn,
              outstandingNotificationCount,
              setOutstandingNotificationCount,
              outstandingMessageCount,
              setOutstandingMessageCount,
              outstandingConnectionCount,
              setOutstandingConnectionCount,
              liveStreamCredits,
              setLiveStreamCredits,
              isProfileComplete,
              setIsProfileComplete,
              searchQuery,
              setSearchQuery,
            }}
          >
            <NavigationSyncContext.Provider
              value={{ focusedScreen, setFocusedScreen }}
            >
              <SafeAreaProvider
                style={tailwind`bg-[${theme.backgroundColor}] overflow-hidden`}
              >
                <BottomSheetContext.Provider
                  value={{
                    bottomSheetContent,
                    setBottomSheetContent,
                    bottomSheetVisible,
                    setBottomSheetVisible,
                  }}
                >
                  <NavigationContainer entering={SlideInRight}>
                    <SafeAreaView forceInset={{ top: "always" }} />
                    {shouldShowHeader ? (
                      !loggedIn ? (
                        <HeaderLogin />
                      ) : null
                    ) : null}
                    <StatusBar barStyle="dark-content" />
                    <View style={tailwind`w-full grow`}>
                      <View style={tailwind`grow`}>
                        <NavigationDrawers />
                      </View>
                      {shouldShowNavigationFooter ? (
                        <View
                          style={tailwind`border-[${theme.footerNavigationIconColor}] border-t-[1px] self-center w-full`}
                        >
                          {orientation === "portrait" ? (
                            <View style={tailwind`bottom-0 h-18`}>
                              {loggedIn ? <NavigationFooterBar /> : null}
                            </View>
                          ) : null}
                        </View>
                      ) : null}
                    </View>
                    <BottomSheet isVisible={bottomSheetVisible}>
                      <View style={tailwind`bg-black`}>
                        {bottomSheetContent}
                      </View>
                    </BottomSheet>
                  </NavigationContainer>
                </BottomSheetContext.Provider>
              </SafeAreaProvider>
            </NavigationSyncContext.Provider>
          </CurrentUserContext.Provider>
        </FullScreenContext.Provider>
      </OrientationContext.Provider>
    </ThemeContext.Provider>
  );
};

export default ApplicationContainer;
