import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useNavigation } from "@react-navigation/native";
import { View, Pressable, ActivityIndicator, Text } from "react-native";
import { Image } from "@rneui/themed";
import tailwind from "twrnc";
import MiniTag from "../base/MiniTag";
import {
  CONNECTION_STATUS_CONNECTED,
  DEFAULT_PROFILE_URL,
  USER_TYPE_AMBASSADOR,
  USER_TYPE_PROVIDER,
} from "../base/Constants";
import {
  NavigationSyncContext,
  ThemeContext,
} from "../base/ApplicationContext";

const TrainerThumbnail = ({ trainer }) => {
  const navigation = useNavigation();
  const { theme } = useContext(ThemeContext);
  const { setFocusedScreen } = useContext(NavigationSyncContext);
  const userId = trainer.trainer ? trainer.trainer.id : trainer.id;
  const tags = trainer.trainer
    ? trainer.trainer.tags.splice(0, 7)
    : trainer.tags.splice(0, 7);
  const trainerName = trainer.trainer
    ? trainer.trainer.firstName + " " + trainer.trainer.lastName
    : trainer.firstName + " " + trainer.lastName;
  const profileUrl = trainer.trainer
    ? trainer.trainer.profileUrl
    : trainer.profileUrl;
  const type = trainer.trainer ? trainer.trainer.type.id : trainer.type.id;
  const followers = trainer.trainer
    ? trainer.trainer.followers && trainer.trainer.followers.length
    : (trainer.followers && trainer.followers.length) || 0;

  const incomingConnections = trainer.trainer
    ? trainer.trainer.incomingConnections
    : trainer.incomingConnections;
  const outgoingConnections = trainer.trainer
    ? trainer.trainer.outgoingConnections
    : trainer.outgoingConnections;

  const filteredIncomingConnections =
    incomingConnections &&
    incomingConnections.filter(
      (connection) => connection.status === CONNECTION_STATUS_CONNECTED
    );

  const filteredOutgoingConnections =
    outgoingConnections &&
    outgoingConnections.filter(
      (connection) => connection.status === CONNECTION_STATUS_CONNECTED
    );

  const connectionsCount =
    Number(
      (filteredIncomingConnections && filteredIncomingConnections.length) +
        (filteredOutgoingConnections && filteredOutgoingConnections.length)
    ) || 0;

  const videoCount =
    Number(
      trainer.trainer
        ? trainer.trainer.videos && trainer.trainer.videos.length
        : trainer.videos && trainer.videos.length
    ) || 0;

  return (
    <Pressable
      style={tailwind`mr-[40px]`}
      onPress={() => {
        setFocusedScreen("ViewProfileScreen");
        navigation.navigate("ViewProfileScreen", {
          id: userId,
          type: USER_TYPE_PROVIDER,
        });
      }}
    >
      <View
        style={tailwind`rounded-lg bg-[${theme.trainerThumbnailBackgroundColor}] w-[365px] h-[142px] p-4`}
      >
        <View style={tailwind`flex-row`}>
          <View style={tailwind`w-2/3`}>
            <View style={tailwind`flex-row mb-2`}>
              <View>
                <Text
                  style={tailwind`text-[${theme.textColor}] pl-1 font-semibold`}
                >
                  {trainerName}
                </Text>
              </View>
              <View style={tailwind`ml-[5px]`}>
                {type === USER_TYPE_AMBASSADOR ? (
                  <Image
                    resizeMode="contain"
                    style={tailwind`h-[16px] w-[13px]`}
                    source={require("../../assets/ambassador-icon.png")}
                  />
                ) : null}
              </View>
            </View>
            <View style={tailwind`flex-row flex-wrap`}>
              {tags.length
                ? tags.map((tag) => (
                    <View key={tag.id} style={tailwind`h-[20px]`}>
                      <MiniTag
                        label={tag.tagId.tagName.toUpperCase()}
                        colorKey={tag.tagId.tagColorKey}
                      />
                    </View>
                  ))
                : null}
            </View>
            <View style={tailwind`grow`} />
            <View style={tailwind`flex-row`}>
              <View style={tailwind`flex-row w-1/3`}>
                <Image
                  resizeMode="contain"
                  style={tailwind`h-[18px] w-[18px] ml-[26px]`}
                  source={require("../../assets/connections-icon.png")}
                />
                <Text
                  style={tailwind`text-[${theme.textColor}] mt-1 text-[12px] ml-2`}
                >
                  {connectionsCount}
                </Text>
              </View>
              <View style={tailwind`flex-row w-1/3`}>
                <Image
                  resizeMode="contain"
                  style={tailwind`h-[18px] w-[18px] ml-[26px]`}
                  source={require("../../assets/followers-icon.png")}
                />
                <Text
                  style={tailwind`text-[${theme.textColor}] mt-1 text-[12px] ml-2`}
                >
                  {followers}
                </Text>
              </View>
              <View style={tailwind`flex-row w-1/3`}>
                <Image
                  resizeMode="contain"
                  style={tailwind`h-[18px] w-[18px] ml-[26px]`}
                  source={require("../../assets/videos-icon.png")}
                />
                <Text
                  style={tailwind`text-[${theme.textColor}] mt-1 text-[12px] ml-2`}
                >
                  {videoCount}
                </Text>
              </View>
            </View>
          </View>
          <View style={tailwind`w-1/3`}>
            <View style={tailwind`rounded-lg`}>
              <Image
                style={tailwind`w-[110px] h-[110px] rounded-lg`}
                source={{
                  uri: profileUrl || DEFAULT_PROFILE_URL,
                }}
                PlaceholderContent={<ActivityIndicator />}
              />
            </View>
          </View>
        </View>
      </View>
    </Pressable>
  );
};

TrainerThumbnail.propTypes = {
  trainer: PropTypes.object.isRequired,
};

export default TrainerThumbnail;
