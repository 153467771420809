import React, { useContext } from "react";
import PropTypes from "prop-types";
import {
  useQuery,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import axios from "axios";
import { ActivityIndicator, Text } from "react-native";
import { API_URL } from "../base/Constants";
import { CurrentUserContext } from "../base/ApplicationContext";
import LiveThumbnail from "../home-screen/LiveThumbnail";

const queryClient = new QueryClient();

const SearchLiveThumbnailStrip = ({ section }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <LiveStrip sectionId={section.id} />
    </QueryClientProvider>
  );
};

SearchLiveThumbnailStrip.propTypes = {
  section: PropTypes.object.isRequired,
};

function LiveStrip({ sectionId }) {
  const { currentUser, searchQuery } = useContext(CurrentUserContext);
  const { isLoading, error, data } = useQuery(
    ["section" + sectionId + searchQuery],
    () =>
      axios
        .get(
          API_URL +
            "search/section/" +
            sectionId +
            "/" +
            currentUser.id +
            "/" +
            searchQuery,
          {
            headers: { authorization: currentUser.token },
          }
        )
        .then((res) => res.data)
  );

  if (isLoading) return <ActivityIndicator />;
  if (error) return <Text>{"An error has occurred:" + error.message}</Text>;

  return (
    <>
      {data.map((liveEvent) => (
        <LiveThumbnail key={liveEvent.viewId} event={liveEvent} />
      ))}
    </>
  );
}

LiveStrip.propTypes = {
  sectionId: PropTypes.number.isRequired,
};

export default SearchLiveThumbnailStrip;
