import React, { useContext } from "react";
import PropTypes from "prop-types";
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";
import { ActivityIndicator, Text } from "react-native";
import tailwind from "twrnc";
import { API_URL } from "../base/Constants";
import { StyledPicker } from "../base/StyledPicker";
import axios from "axios";
import { CurrentUserContext, ThemeContext } from "../base/ApplicationContext";
import { Roboto_400Regular, useFonts } from "@expo-google-fonts/roboto";

const Country = ({ country, setCountry }) => {
  const queryClient = new QueryClient();

  let fonts = useFonts({ Roboto_400Regular });
  if (!fonts) return;

  return (
    <QueryClientProvider client={queryClient}>
      <CountryPanel country={country} setCountry={setCountry} />
    </QueryClientProvider>
  );
};

const CountryPanel = ({ country, setCountry }) => {
  const { currentUser } = useContext(CurrentUserContext);
  const { theme } = useContext(ThemeContext);

  const { isLoading, error, data } = useQuery(["countries"], () =>
    axios
      .get(API_URL + "countries", {
        staleTime: 0,
        headers: { authorization: currentUser.token },
      })
      .then((res) => {
        return res.data;
      })
  );

  if (isLoading) return <ActivityIndicator />;
  if (error) return <Text>An error has occurred: ${error.message}</Text>;

  return (
    <>
      <Text
        style={[
          tailwind`text-[${theme.textColor}] font-semibold mb-1`,
          { fontFamily: "Roboto_400Regular" },
        ]}
      >
        Country
      </Text>
      <StyledPicker
        items={data.map((countryItem) => {
          return {
            label: countryItem.name,
            value: countryItem.id,
          };
        })}
        selectedValue={country}
        onValueChange={(itemValue) => {
          setCountry(Number(itemValue));
        }}
      />
    </>
  );
};

Country.propTypes = {
  country: PropTypes.number.isRequired,
  setCountry: PropTypes.func.isRequired,
};

CountryPanel.propTypes = {
  country: PropTypes.number.isRequired,
  setCountry: PropTypes.func.isRequired,
};

export { Country as default };
