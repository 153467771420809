import * as React from "react";
import PropTypes from "prop-types";
import { Text, Pressable, View } from "react-native";
import tailwind from "twrnc";
import {
  BUTTON_TEXT_COLOUR,
  CALENDAR_EVENT_COLOUR,
  PRIMARY_BUTTON_COLOUR,
} from "../base/Constants";

const TimerButton = ({ title, onPress, selected }) => {
  let buttonStyle = {};

  buttonStyle = selected
    ? tailwind`w-auto h-[40px] bg-[${CALENDAR_EVENT_COLOUR}] rounded-lg p-1 pt-2 ml-1 w-[40px] max-w-[40px]`
    : tailwind`w-auto h-[40px] bg-[${PRIMARY_BUTTON_COLOUR}] rounded-lg p-1 pt-2 ml-1 w-[40px] max-w-[40px]`;

  return (
    <Pressable onPress={onPress} style={buttonStyle}>
      <View style={tailwind`py-1`}>
        <Text
          style={tailwind`text-[${BUTTON_TEXT_COLOUR}] font-semibold text-xs self-center`}
        >
          {title}
        </Text>
      </View>
    </Pressable>
  );
};

TimerButton.propTypes = {
  title: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
  selected: PropTypes.bool,
};

export default TimerButton;
