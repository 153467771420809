//dev
// export const API_URL = "http://192.168.1.5:3000/";
// export const SOCKET_URL = "http://192.168.1.5:9000";
// export const LIVE_SOCKET_URL = "ws://192.168.1.5:8000";
// export const LIVE_STREAM_VIEW_URL = "http://192.168.1.5:3000/live/";
// export const CAST_SOCKET_URL = "ws://192.168.1.5:4000";

//QA
export const API_URL = "https://test.fitcentr.com:3011/";
export const SOCKET_URL = "wss://test.fitcentr.com:9001";
export const LIVE_SOCKET_URL = "wss://test.fitcentr.com:8001";
export const LIVE_STREAM_VIEW_URL = "https://test.fitcentr.com:3011/live/";
export const CAST_SOCKET_URL = "wss://test.fitcentr.com:4001";

//demo
// export const API_URL = "https://demo.fitcentr.com:3010/";
// export const SOCKET_URL = "wss://demo.fitcentr.com:9000";
// export const LIVE_SOCKET_URL = "wss://demo.fitcentr.com:8000";
// export const LIVE_STREAM_VIEW_URL = "https://demo.fitcentr.com:3010/live/";
// export const CAST_SOCKET_URL = "wss://demo.fitcentr.com:4000";

export const PRIMARY_BUTTON_COLOUR = "#b87132";
export const BUTTON_TEXT_COLOUR = "#ffffff";
export const CALENDAR_EVENT_COLOUR = "#b87132";
export const FILL_COLOUR_1 = "#b87132";
export const FILL_COLOUR_2 = "#f4e0cc";
export const FILL_COLOUR_3 = "#f2ebe1";
export const FILL_COLOUR_4 = "#f0f2e5";
export const FILL_COLOUR_5 = "#005c89";
export const FILL_COLOUR_WARNING = "#ffcc00";

export const USER_TYPE_USER = 1;
export const USER_TYPE_PROVIDER = 2;
export const USER_TYPE_BUSINESS = 3;
export const USER_TYPE_AMBASSADOR = 4;

export const TREE_TYPE_USER = 1;
export const TREE_TYPE_BODY = 9;

export const DEFAULT_PROFILE_URL =
  "https://demo.fitcentr.com:3010/images/default-profile.png";

export const EVENT_TYPE_BROADCAST = 1;
export const EVENT_TYPE_PRIVATE = 2;
export const EVENT_TYPE_OTHER = 4;
export const EVENT_TYPE_BOOKABLE = 6;

export const DIFFICULTY_BEGINNER = 1;
export const DIFFICULTY_INTERMEDIATE = 2;
export const DIFFICULTY_ADVANCED = 3;
export const DIFFICULTY_ALL = 4;

export const MESSAGE_TYPE_GENERIC = 1;
export const MESSAGE_TYPE_CONNECTION = 2;
export const MESSAGE_TYPE_NOTIFICATION = 3;

export const CONNECTION_STATUS_REQUESTED = 1;
export const CONNECTION_STATUS_CONNECTED = 2;
export const CONNECTION_STATUS_IGNORED = 3;
export const CONNECTION_STATUS_BLOCKED = 4;

export const REPEAT_TYPE_NONE = 0;
export const REPEAT_TYPE_DAILY = 2;
export const REPEAT_TYPE_WEEKLY = 3;
export const REPEAT_TYPE_MONTHLY = 4;

export const NOTIFICATION_TYPE_MESSAGE = 1;
export const NOTIFICATION_TYPE_EVENT = 2;
export const NOTIFICATION_TYPE_SYSTEM = 3;
export const NOTIFICATION_TYPE_CONNECTION = 4;
