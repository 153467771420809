import React from "react";
import PropTypes from "prop-types";
import { StyledInput } from "../base/StyledInput";

const City = ({ city, setCity }) => {
  return (
    <>
      <StyledInput
        label={"City"}
        onChangeText={(text) => setCity(text)}
        value={city}
        width={"350px"}
      />
    </>
  );
};

City.propTypes = {
  city: PropTypes.string.isRequired,
  setCity: PropTypes.func.isRequired,
};

export { City as default };
