import PropTypes from "prop-types";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ScrollView, Text, View } from "react-native";
import tailwind from "twrnc";
import Button from "../components/base/Button";
import {
  CAST_SOCKET_URL,
  FILL_COLOUR_1,
  FILL_COLOUR_2,
} from "../components/base/Constants";
import { io } from "socket.io-client";
import OverlayControls from "../components/broadcast-screen/OverlayControls";
import dayjs from "dayjs";
import StreamControls from "../components/broadcast-screen/StreamControls";
import WebPlayerPreview from "../components/view-live-screen/WebPlayerPreview";
import SocketChat from "../components/private-room/shared/SocketChat";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import { SocketContext } from "../components/private-room/web/SocketProvider";
import {
  CurrentUserContext,
  ThemeContext,
} from "../components/base/ApplicationContext";

const Broadcast3Screen = (props) => {
  const { event } = props.route.params;
  const { theme } = useContext(ThemeContext);
  const [cameraEnabled, setCameraEnabled] = useState(false);
  const [streaming, setStreaming] = useState(false);
  const [showing, setShowing] = useState(false);
  const [streamStopping, setStreamStopping] = useState(0);
  const streamStopped = useRef(false);

  const startTime = useRef();
  const endTime = useRef();
  const inputStreamRef = useRef();
  const videoRef = useRef();
  const canvasRef = useRef();
  const wsRef = useRef();
  const mediaRecorderRef = useRef();
  const requestAnimationRef = useRef();
  const outputStreamRef = useRef();
  const [overlayPlan, setOverlayPlan] = useState([]);
  const overlayPlanRef = useRef();
  const [planRunning, setPlanRunning] = useState(false);
  const [previewMuted, setPreviewMuted] = useState(true);
  const planShown = useRef(false);
  const planStep = useRef();
  const planStepTime = useRef();
  const planStepInterval = useRef();
  const currentPlanStep = useRef();

  // Chat
  const [messages, setMessages] = useState([]);
  const { socketRef } = useContext(SocketContext);
  const { currentUser } = useContext(CurrentUserContext);
  const roomId = event.eventKey;
  const init = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    // const audioDevice = devices.find((device) => device.kind === "audiooutput");
    console.log(devices);
    await socketRef.connectSocket();
    socketRef.connectToRoom(
      null,
      roomId,
      currentUser,
      null,
      null,
      null,
      messages,
      setMessages
    );
  };

  const userAgent = navigator.userAgent;
  // const isChrome =
  //   /Chrome/.test(userAgent) && /Google Inc/.test(navigator.vendor);
  // const isFirefox = /Firefox/.test(userAgent);
  const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent);

  useEffect(() => {
    init();
    return () => {
      cancelAnimationFrame(requestAnimationRef.current);
      if (inputStreamRef.current) {
        const tracks = inputStreamRef.current.getTracks();
        tracks.forEach((track) => track.stop());
      }
      stopStreaming();
      if (wsRef.current) wsRef.current.close();

      socketRef.disconnectCall();
      setMessages([]);
    };
  }, []);

  useEffect(() => {
    console.log("Broadcast screen useEffect overlayPlan", overlayPlan);
  }, [overlayPlan]);

  useEffect(() => {
    if (planRunning && !planStepInterval.current) {
      //first run
      planShown.current = true;
      overlayPlanRef.current = overlayPlan;
      planStepTime.current = 0;
      planStep.current = 0;

      planStepInterval.current = setInterval(() => {
        if (!planRunning) {
          clearInterval(planStepInterval.current);
          planStepInterval.current = null;
        }
        planStepTime.current++;

        currentPlanStep.current = overlayPlanRef.current.filter(
          (planStepItem) => {
            return planStepItem.key === planStep.current;
          }
        )[0];
        if (
          parseInt(currentPlanStep.current.duration) -
            parseInt(planStepTime.current) <=
          0
        ) {
          planStep.current++;
          console.log("increment plan step", planStep.current);
          currentPlanStep.current = overlayPlanRef.current.filter(
            (planStepItem) => {
              return planStepItem.key === planStep.current;
            }
          )[0];
          planStepTime.current = 0;
          if (!currentPlanStep.current) {
            console.log("end of plan");
            clearInterval(planStepInterval.current);
            planStepInterval.current = null;
            planShown.current = null;
            overlayPlanRef.current = null;
            setPlanRunning(false);
          }
        }
      }, 1000);
    } else {
      clearInterval(planStepInterval.current);
      planStepInterval.current = null;
      planShown.current = null;
      overlayPlanRef.current = null;
    }
  }, [planRunning]);

  const enableCamera = async () => {
    inputStreamRef.current = await navigator.mediaDevices.getUserMedia({
      video: true,
      audio: true,
    });

    videoRef.current.srcObject = inputStreamRef.current;

    await videoRef.current.play();

    // We need to set the canvas height/width to match the video element.
    canvasRef.current.height = videoRef.current.clientHeight;
    canvasRef.current.width = videoRef.current.clientWidth;

    requestAnimationRef.current = requestAnimationFrame(updateCanvas);

    setCameraEnabled(true);
  };

  function toTimeString(totalSeconds) {
    const totalMs = totalSeconds * 1000;
    const result = new Date(totalMs).toISOString().slice(11, 19);

    return result;
  }

  const drawPlanTimer = () => {
    const top = 46;
    const left = 744;
    const radius = 28;
    const backgroundRadius = 30;

    const ctx = canvasRef.current.getContext("2d");
    currentPlanStep.current = overlayPlanRef.current.filter((planStepItem) => {
      return planStepItem.key === planStep.current;
    })[0];

    ctx.fillStyle = "#eeeeee";
    ctx.beginPath();
    ctx.arc(left, top, backgroundRadius, 0, 2 * Math.PI, false);
    ctx.fill();

    ctx.strokeStyle =
      currentPlanStep.current.setType === 1 ? "#00dd00" : "#dd0000";
    ctx.lineWidth = 4;
    ctx.beginPath();
    ctx.arc(
      left,
      top,
      radius,
      0,
      (2 / parseInt(currentPlanStep.current.duration)) *
        (parseInt(currentPlanStep.current.duration) -
          parseInt(planStepTime.current)) *
        Math.PI,
      false
    );
    ctx.stroke();

    ctx.fillStyle =
      currentPlanStep.current.setType === 1 ? "#00dd00" : "#dd0000";
    let planText = (
      "0" +
      (
        parseInt(currentPlanStep.current.duration) -
        parseInt(planStepTime.current)
      ).toString()
    ).slice(-2);
    ctx.font = "36px roboto";
    ctx.fillText(planText, left - 19, top + 12);
  };

  const drawLiveTimer = () => {
    const ctx = canvasRef.current.getContext("2d");
    if (startTime.current && !endTime.current) {
      let timeLive = toTimeString(
        dayjs(Date.now()).diff(startTime.current) / 1000
      );

      ctx.fillStyle = "#fff";

      const width = 80;
      const height = 20;
      const radius = height / 2;
      const left = 15;
      const top = 15;
      const textTop = 29;
      const textLeft = 39;

      ctx.beginPath();
      ctx.moveTo(radius + left, top);
      ctx.lineTo(width - radius + left, top);
      ctx.arc(
        width - radius + left,
        radius + left,
        radius,
        -Math.PI / 2,
        Math.PI / 2
      );
      ctx.lineTo(radius + left, height + top);
      ctx.arc(
        radius + left,
        radius + top,
        radius,
        Math.PI / 2,
        (3 * Math.PI) / 2
      );
      ctx.closePath();
      ctx.fill();

      ctx.beginPath();
      ctx.arc(25, 25, 6, 0, 2 * Math.PI, false);
      ctx.fillStyle = theme.redColor;
      ctx.fill();

      ctx.font = "12px roboto";
      ctx.fillStyle = "#000";
      ctx.fillText(timeLive, textLeft, textTop);
    }
  };

  const updateCanvas = () => {
    if (videoRef.current.ended || videoRef.current.paused) {
      return;
    }

    const ctx = canvasRef.current.getContext("2d");
    if (streamStopped.current) {
      const stoppingImage = document.createElement("img");
      stoppingImage.src = require("../assets/streamending.jpg");
      ctx.drawImage(stoppingImage, 0, 0, 480, 360, 0, 0, 800, 600);
    } else {
      ctx.drawImage(videoRef.current, 0, 0, 800, 600, 0, 0, 1024, 768);
      drawLiveTimer();
      if (planShown.current) drawPlanTimer();
    }
    requestAnimationRef.current = requestAnimationFrame(updateCanvas);
  };

  const audioStream = new MediaStream();
  const stopStreaming = () => {
    const millisecsTillEnd = 7000;
    let remainingSecs = millisecsTillEnd / 1000;

    if (outputStreamRef.current) {
      outputStreamRef.current.getAudioTracks().forEach((track) => {
        console.log(track);
        track.enabled = false;
      });
    }

    setStreamStopping(remainingSecs);
    streamStopped.current = true;
    const stoppingCountdownInterval = setInterval(() => {
      console.log("stoppingCountdownInterval execute");
      remainingSecs--;
      setStreamStopping(remainingSecs);
      if (remainingSecs <= 0) {
        console.log("clearing interval");
        clearInterval(stoppingCountdownInterval);
        setStreamStopping(0);
        if (outputStreamRef.current)
          outputStreamRef.current.getAudioTracks().forEach((track) => {
            track.enabled = true;
          });
      }
    }, 1000);

    const stoppingTimeout = setTimeout(() => {
      console.log("setTimeoout execute");
      if (
        mediaRecorderRef.current &&
        mediaRecorderRef.current.state !== "inactive"
      ) {
        mediaRecorderRef.current.stop();
      }
      setStreaming(false);
      streamStopped.current = false;
      if (wsRef.current)
        wsRef.current.send({ type: "stop_stream", key: event.eventKey });
      endTime.current = Date.now();
      console.log("clearing timeout");
      clearTimeout(stoppingTimeout);
    }, millisecsTillEnd);
  };

  const startStreaming = async () => {
    setStreaming(true);

    if (!wsRef.current) {
      wsRef.current = await io.connect(CAST_SOCKET_URL, { event: { event } });

      wsRef.current.addEventListener("open", function open() {
        console.log("socket connected");
      });

      wsRef.current.addEventListener("close", () => {
        console.log("socket closed");
        stopStreaming();
      });
    }

    const videoOutputStream = canvasRef.current.captureStream(30); // 30 FPS

    const audioTracks = inputStreamRef.current.getAudioTracks();
    audioTracks.forEach(function (track) {
      audioStream.addTrack(track);
    });

    outputStreamRef.current = new MediaStream();
    [audioStream, videoOutputStream].forEach(function (s) {
      s.getTracks().forEach(function (t) {
        outputStreamRef.current.addTrack(t);
      });
    });

    if (isSafari) {
      mediaRecorderRef.current = new MediaRecorder(outputStreamRef.current, {
        mimeType: "video/mp4",
        videoBitsPerSecond: 3000000,
      });
    } else {
      mediaRecorderRef.current = new MediaRecorder(outputStreamRef.current, {
        mimeType: "video/webm",
        videoBitsPerSecond: 3000000,
      });
    }

    mediaRecorderRef.current.addEventListener("dataavailable", (e) => {
      wsRef.current.send({
        type: "stream",
        key: event.eventKey,
        data: e.data,
      });
    });

    mediaRecorderRef.current.addEventListener("stop", () => {
      wsRef.current.send({ type: "stop_stream", key: event.eventKey });
      stopStreaming();
    });

    wsRef.current.send({ type: "init", key: event.eventKey });
    mediaRecorderRef.current.start(100);
    startTime.current = Date.now();
    endTime.current = null;
  };

  return (
    <ScrollView>
      <View style={tailwind`flex-row`}>
        <View style={tailwind`flex-col w-[800px]`}>
          <View>
            <video
              ref={videoRef}
              controls
              width="800"
              height="600"
              muted
              style={tailwind`absolute top-[-600px] left-[-800px]`}
            ></video>
          </View>
          <View>
            <canvas
              style={tailwind`w-[800px] h-[600px]`}
              ref={canvasRef}
            ></canvas>
          </View>
        </View>
        <View style={tailwind`mt-1 w-[350px] pl-2`}>
          <View>
            <Text style={tailwind`text-[${theme.textColor}] font-bold mb-1`}>
              {event.short_description}
            </Text>
            <Text
              style={tailwind`text-[${theme.textColor}] font-semibold mb-2`}
            >
              {event.long_description}
            </Text>
            <Text style={tailwind`text-[${theme.textColor}] text-base mb-3`}>
              Duration: {event.duration} minutes
            </Text>
          </View>
          <ProviderTabs
            event={event}
            messages={messages}
            onSend={(newMessages) => {
              console.log("onSendMessage", newMessages);
              socketRef.sendMessage(currentUser, roomId, newMessages[0]);
            }}
            overlayPlan={overlayPlan}
            setOverlayPlan={setOverlayPlan}
            planRunning={planRunning}
            setPlanRunning={setPlanRunning}
            streaming={streaming}
            showing={showing}
            setShowing={setShowing}
            previewMuted={previewMuted}
            setPreviewMuted={setPreviewMuted}
          />
          <View>
            {!cameraEnabled && (
              <Button title="Camera On" onPress={enableCamera} />
            )}
            {cameraEnabled ? (
              <>
                <StreamControls
                  cameraEnabled={cameraEnabled}
                  streaming={streaming}
                  streamStopping={streamStopping}
                  stopStreaming={stopStreaming}
                  startStreaming={startStreaming}
                />
                {/* <OverlayControls
                  overlayPlan={overlayPlan}
                  setOverlayPlan={setOverlayPlan}
                  planRunning={planRunning}
                  setPlanRunning={setPlanRunning}
                /> */}
                {/* <View style={tailwind`mt-2 ml-2`}>
                  <Button
                    title="Show Stream"
                    disabled={!streaming}
                    onPress={() => setShowing(!showing)}
                  />
                  <View style={tailwind`mt-2`}>
                    {showing ? (
                      <WebPlayerPreview
                        event={{ event_key: event.event_key }}
                      />
                    ) : null}
                  </View>
                </View> */}
              </>
            ) : null}
          </View>
        </View>
      </View>
    </ScrollView>
  );
};

const Tab = createMaterialTopTabNavigator();
function ProviderTabs({
  event,
  messages,
  onSend,
  overlayPlan,
  setOverlayPlan,
  planRunning,
  setPlanRunning,
  streaming,
  showing,
  setShowing,
  previewMuted,
  setPreviewMuted,
}) {
  return (
    <Tab.Navigator
      initialRouteName="consumer.tabs"
      style={tailwind`bg-black mb-2`}
      screenOptions={{
        tabBarActiveTintColor: "white",
        tabBarInactiveTintColor: FILL_COLOUR_2,
        tabBarStyle: { backgroundColor: FILL_COLOUR_1 },
        tabBarIndicatorStyle: {
          backgroundColor: "white",
        },
      }}
    >
      <Tab.Screen
        name="Chat"
        children={() => (
          <SocketChat event={event} messages={messages} onSend={onSend} />
        )}
      />
      <Tab.Screen
        name="Plan"
        children={() => (
          <OverlayControls
            overlayPlan={overlayPlan}
            setOverlayPlan={setOverlayPlan}
            planRunning={planRunning}
            setPlanRunning={setPlanRunning}
          />
        )}
      />
      <Tab.Screen
        name="Stream"
        children={() => (
          <View style={tailwind`mt-2 ml-2`}>
            <View style={tailwind`flex-row`}>
              {showing ? (
                <Button
                  title="Hide Stream"
                  disabled={!streaming}
                  onPress={() => setShowing(!showing)}
                />
              ) : (
                <Button
                  title="Show Stream"
                  disabled={!streaming}
                  onPress={() => setShowing(!showing)}
                />
              )}
              {previewMuted ? (
                <Button
                  title="Unmute"
                  onPress={() => setPreviewMuted(!previewMuted)}
                />
              ) : (
                <Button
                  title="Mute"
                  onPress={() => setPreviewMuted(!previewMuted)}
                />
              )}
            </View>
            <View style={tailwind`mt-2`}>
              {showing ? (
                <WebPlayerPreview
                  event={{ eventKey: event.eventKey }}
                  previewMuted={previewMuted}
                />
              ) : null}
            </View>
          </View>
        )}
      />
    </Tab.Navigator>
  );
}

Broadcast3Screen.propTypes = {
  route: PropTypes.object.isRequired,
};

ProviderTabs.propTypes = {
  event: PropTypes.object.isRequired,
  messages: PropTypes.array.isRequired,
  onSend: PropTypes.func.isRequired,
  overlayPlan: PropTypes.array.isRequired,
  setOverlayPlan: PropTypes.func.isRequired,
  planRunning: PropTypes.bool.isRequired,
  setPlanRunning: PropTypes.func.isRequired,
  streaming: PropTypes.bool.isRequired,
  showing: PropTypes.bool.isRequired,
  setShowing: PropTypes.func.isRequired,
  previewMuted: PropTypes.bool.isRequired,
  setPreviewMuted: PropTypes.func.isRequired,
};

export default Broadcast3Screen;
