import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import {
  ActivityIndicator,
  Platform,
  ScrollView,
  Text,
  View,
} from "react-native";
import {
  useQuery,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import axios from "axios";
import { Image } from "@rneui/themed";
import tailwind from "twrnc";
import Tag from "../base/Tag";
import ConnectModal from "./ConnectModal";
import {
  API_URL,
  DEFAULT_PROFILE_URL,
  FILL_COLOUR_WARNING,
} from "../base/Constants";
import Button from "../base/Button";
import { useNavigation } from "@react-navigation/native";
import {
  CurrentUserContext,
  NavigationSyncContext,
  ThemeContext,
} from "../base/ApplicationContext";

const queryClient = new QueryClient();

const UserProfilePanel = ({ id }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <Panel id={id} />
    </QueryClientProvider>
  );
};

UserProfilePanel.propTypes = {
  id: PropTypes.number.isRequired,
};

function Panel({ id }) {
  const navigation = useNavigation();

  const { currentUser, isProfileComplete } = useContext(CurrentUserContext);
  const { setFocusedScreen } = useContext(NavigationSyncContext);
  const { theme } = useContext(ThemeContext);
  const [modalVisible, setModalVisible] = useState(false);
  // const [connected, setConnected] = useState(false);
  const { isLoading, error, data } = useQuery(["user" + id], () =>
    axios
      .get(API_URL + "users/" + id, {
        cacheTime: 0,
        headers: { authorization: currentUser.token },
      })
      .then((res) => {
        return res.data;
      })
  );

  if (isLoading) return <ActivityIndicator />;
  if (error) return <Text>An error has occurred: ${error.message}</Text>;

  const userId = data.id;
  const userType = data.type.id;
  const firstName = data.firstName;
  const shortName = firstName + " " + data.lastName;
  const userTags = data.tags;
  const profileUrl = data.profileUrl;
  const isProfileOwner = data.id === currentUser.id;

  const profileMessages = [];
  let key = 0;
  if (!isProfileComplete) {
    if (!data.profileUrl) {
      profileMessages.push({
        key: key,
        message: (
          <View
            style={tailwind`flex-row self-center bg-[${theme.warningMessageBackgroundColor}] rounded-full py-2 px-3 mb-1`}
          >
            <Image
              resizeMode="contain"
              style={tailwind`h-[18px] w-[18px]`}
              source={require("../../assets/warning-icon.png")}
              tintColor={theme.redColor}
            />
            <Text
              style={tailwind`text-[${FILL_COLOUR_WARNING}] font-semibold ml-2`}
            >
              You profile picture is not set
            </Text>
          </View>
        ),
      });
      key++;
    }
    if (!data.currency) {
      {
        profileMessages.push({
          key: key,
          message: (
            <View
              style={tailwind`flex-row self-center bg-[${theme.warningMessageBackgroundColor}] rounded-full py-2 px-3 mb-1`}
            >
              <Image
                resizeMode="contain"
                style={tailwind`h-[18px] w-[18px]`}
                source={require("../../assets/warning-icon.png")}
                tintColor={theme.redColor}
              />
              <Text
                style={tailwind`text-[${theme.redColor}] font-semibold ml-2`}
              >
                You haven&apos;t set your currency. You won&apos;t be able to
                purchase credits until you do so.
              </Text>
            </View>
          ),
        });
        key++;
      }
    }
  }

  return (
    <View style={tailwind`flex-col w-full h-full`}>
      {profileMessages.length && isProfileOwner
        ? profileMessages.map((message) => (
            <View key={message.id}>{message.message}</View>
          ))
        : null}
      <View>
        <View style={tailwind`flex-row px-4 pt-4`}>
          <View style={tailwind`w-1/3`}>
            <View style={tailwind`w-32`}>
              <Image
                style={
                  Platform.OS === "web"
                    ? tailwind`w-[118px] h-[164px] rounded-lg`
                    : tailwind`w-[59px] h-[82px] rounded-lg`
                }
                source={{ uri: profileUrl || DEFAULT_PROFILE_URL }}
                PlaceholderContent={<ActivityIndicator />}
              />
            </View>
            <View style={tailwind`flex-col pr-4`}>
              <View>
                <View style={tailwind`self-start`}>
                  <Text
                    style={[
                      tailwind`capitalize text-[${theme.textColor}] font-bold text-[18px] mt-3`,
                      { fontFamily: "Roboto_700Bold" },
                    ]}
                  >
                    {shortName}
                  </Text>
                </View>
                <View style={tailwind`flex-row max-w-[118px]`}>
                  <View style={tailwind`w-1/2`}>
                    <View style={tailwind`self-end`}>
                      <Image
                        resizeMode="contain"
                        style={tailwind`h-[18px] w-[18px]`}
                        source={require("../../assets/connections-icon.png")}
                      />
                      <Text
                        style={[
                          tailwind`font-bold text-[${theme.textColor}] self-end`,
                          { fontFamily: "Roboto_400Regular" },
                        ]}
                      >
                        0
                      </Text>
                    </View>
                  </View>
                  <View style={tailwind`w-1/2`}>
                    <View style={tailwind`self-end`}>
                      <Image
                        resizeMode="contain"
                        style={tailwind`h-[18px] w-[18px]`}
                        source={require("../../assets/followers-icon.png")}
                      />
                      <Text
                        style={[
                          tailwind`font-bold text-[${theme.textColor}] self-end`,
                          { fontFamily: "Roboto_400Regular" },
                        ]}
                      >
                        0
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>

      <ScrollView>
        <View style={tailwind`px-4`}>
          <Text
            style={[
              tailwind`text-[${theme.textColor}] font-semibold py-2`,
              { fontFamily: "Inter_400Regular" },
            ]}
          >
            {data.profileText}
          </Text>
        </View>
        <View style={tailwind`px-4 my-2`}>
          <View style={tailwind`flex flex-row mt-2 flex-wrap`}>
            {userTags.map((userTag) => (
              <Tag
                key={userTag.id}
                label={userTag.tagId.tagName}
                colorKey={userTag.tagId.tagColorKey}
              />
            ))}
          </View>
        </View>
      </ScrollView>
      {isProfileOwner ? (
        <View style={tailwind`self-center mt-2`}>
          <Button
            title="Edit"
            onPress={() => {
              setFocusedScreen("EditProfileScreen");
              navigation.navigate("EditProfileScreen", {
                userId: userId,
                userType: userType,
              });
            }}
          />
        </View>
      ) : null}
      <ConnectModal
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        id={id}
        firstName={firstName}
        profileUrl={data.profileUrl || DEFAULT_PROFILE_URL}
      />
    </View>
  );
}

Panel.propTypes = {
  id: PropTypes.number.isRequired,
};

export default UserProfilePanel;
