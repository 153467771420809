export const analyzeFirstName = (firstName, firstNameStatus) => {
  if (firstName.length) {
    if (firstName.length > 20) {
      firstNameStatus.current = "Max. 20 characters";
    } else {
      firstNameStatus.current = "";
    }
  } else {
    firstNameStatus.current = "First name is required";
  }
};

export const analyzeLastName = (lastName, lastNameStatus) => {
  if (lastName.length) {
    if (lastName.length > 20) {
      lastNameStatus.current = "Max. 20 characters";
    } else {
      lastNameStatus.current = "";
    }
  } else {
    lastNameStatus.current = "Last name is required";
  }
};

export const analyzeLoginPassword = (typedPassword, passwordStatus) => {
  passwordStatus.current = !typedPassword.length ? "Password is required" : "";
};

export const analyzePassword = (typedPassword, passwordStatus) => {
  const strongRegex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
  );

  if (typedPassword) {
    if (typedPassword.length < 8) {
      passwordStatus.current =
        "Minimum of 8 characters, mix letters, numbers and symbols";
    } else if (!strongRegex.test(typedPassword)) {
      passwordStatus.current =
        "Your password is too weak, mix letters, numbers and one of these symbols ! @ # $ % ^ & or *";
    } else passwordStatus.current = "";
  }
};

export const analyzeConfirmPassword = (
  confirmPassword,
  password,
  confirmPasswordStatus
) => {
  if (confirmPassword && password) {
    if (password !== confirmPassword) {
      confirmPasswordStatus.current = "Passwords don't match";
    } else {
      confirmPasswordStatus.current = "";
    }
  }
};

export const analyzeEmailAddress = (emailAddress, emailAddressStatus) => {
  const validEmailRegex = new RegExp(
    `[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}`
  );
  if (!validEmailRegex.test(emailAddress)) {
    emailAddressStatus.current = "Invalid email address";
  } else {
    emailAddressStatus.current = "";
  }
};

export const analyzePhoneNumber = (phoneNumber, phoneNumberStatus) => {
  phoneNumberStatus.current = "";
};

export const analyzeShortDescription = (
  shortDescription,
  shortDescriptionStatus
) => {
  if (shortDescription && shortDescription.length) {
    shortDescriptionStatus.current = "";
  } else {
    shortDescriptionStatus.current = "Title is required";
  }
};

export const analyzeTime = (time, timeStatus) => {
  if (time && time.length) {
    timeStatus.current = "";
  } else {
    timeStatus.current = "Time is required";
  }
};
