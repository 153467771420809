import { Alert } from "react-native";
import { RTCPeerConnection } from "react-native-webrtc-web-shim";

export const connectToPeer = (
  userId,
  localStream,
  remoteStreams,
  processRemoteStream,
  setTotalRemoteStreams,
  testPeersRef,
  socketRef,
  fromUserId,
  roomId
) => {
  // console.log("connectToPeer " + userId);
  let peerConstraints = {
    iceServers: [
      {
        urls: "stun:stun.l.google.com:19302",
      },
      {
        urls: "stun:stun1.l.google.com:19302",
      },
      {
        urls: "stun:stun2.l.google.com:19302",
      },
      {
        urls: "stun:openrelay.metered.ca:80",
      },
      {
        urls: "turn:openrelay.metered.ca:80",
        username: "openrelayproject",
        credential: "openrelayproject",
      },
      {
        urls: "turn:openrelay.metered.ca:443",
        username: "openrelayproject",
        credential: "openrelayproject",
      },
      {
        urls: "turn:openrelay.metered.ca:443?transport=tcp",
        username: "openrelayproject",
        credential: "openrelayproject",
      },
    ],
  };

  // console.log("creating new RTCPeerConnection");
  let peerConnection = new RTCPeerConnection(peerConstraints);
  // console.log("after RTCPeerConnection");

  peerConnection.userId = userId;

  // Add our stream to the peer connection.
  // console.log("add local stream");
  if (localStream) {
    peerConnection.addStream(localStream);
  } else console.log("No local stream found, skipping");

  //Add to peerRefs here
  // console.log("search for existing peerRef");
  // console.log(testPeersRef.current);

  let peerRef = testPeersRef.current.filter((peer) => {
    return peer && peer.userId === userId;
  })[0];

  if (!peerRef) {
    // console.log("(connectToPeer) pushing " + userId + " to peersRef");
    testPeersRef.current = [
      ...testPeersRef.current,
      { userId: userId, connection: peerConnection },
    ];
    // console.log(testPeersRef.current);
  } else {
    peerRef.connection = peerConnection;
  }

  peerConnection.addEventListener("connectionstatechange", () => {
    switch (peerConnection.connectionState) {
      case "closed":
        // You can handle the call being disconnected here.
        // console.log("connectionstate is now closed");
        break;
    }
  });

  peerConnection.addEventListener("icecandidate", (event) => {
    // When you find a null candidate then there are no more candidates.
    // Gathering of candidates has finished.
    // console.log(
    //   "icecandidate (not ice-candidate) from user " + peerConnection.userId
    // );
    // console.log(event);

    if (!event.candidate) return;

    if (event.currentTarget.userId === fromUserId) {
      // console.log("ignoring my own icecandidate event");
      return;
    }

    socketRef.current.emit("ice-candidate", {
      fromUserId: fromUserId,
      toUserId: peerConnection.userId,
      roomId: roomId,
      candidate: event.candidate,
    });
  });

  peerConnection.addEventListener("icecandidateerror", () => {
    // You can ignore some candidate errors.
    // Connections can still be made even when errors occur.
    // console.log("ice candidate error");
    // console.log(event.errorCode + " " + event.errorText + " " + event.url);
  });

  peerConnection.addEventListener("iceconnectionstatechange", () => {
    switch (peerConnection.iceConnectionState) {
      case "connected":
      case "completed":
        // You can handle the call being connected here.
        // Like setting the video streams to visible.
        // console.log(
        //   "iceconnectionstate is now " + peerConnection.iceConnectionState
        // );
        Alert.alert(
          "iceconnectionstate is now " + peerConnection.iceConnectionState
        );
        break;
    }
  });

  peerConnection.addEventListener("negotiationneeded", (event) => {
    console.log("negotiationneeded from " + event.target.userId);
    // console.log(event);
    const toUserId = event.target.userId;

    // You can start the offer stages here.
    // Be careful as this event can be called multiple times.
    let sessionConstraints = {
      mandatory: {
        OfferToReceiveAudio: true,
        OfferToReceiveVideo: true,
        VoiceActivityDetection: true,
      },
    };

    // console.log("creating offer");
    peerConnection
      .createOffer(sessionConstraints)
      .then((offer) => {
        // console.log("setting offer against localDescription");
        return peerConnection.setLocalDescription(offer);
      })
      .then(() => {
        // console.log("localDescription");
        // console.log(peerConnection.localDescription);
        const payload = {
          fromUserId: fromUserId,
          toUserId: toUserId,
          roomId: roomId,
          sdp: peerConnection.localDescription,
        };
        // console.log("emitting 'offer' on socketRef");
        socketRef.current.emit("offer", payload);
      })
      .catch((err) =>
        console.log("Error handling negotiation needed event: " + err)
      );
  });

  peerConnection.addEventListener("signalingstatechange", () => {
    // console.log("signalingstatechange", peerConnection.signalingState);
    switch (peerConnection.signalingState) {
      case "closed":
        // You can handle the call being disconnected here.
        // console.log("signalingstate is now closed");

        break;
    }
  });

  peerConnection.ontrack = async (mediaTrack) => {
    let newStream = {
      userId: peerConnection.userId,
      stream: mediaTrack.streams[0],
    };

    processRemoteStream(newStream);
    // console.log(
    //   "web-rtc-functions peerConnection.onTrack setTotalStreams",
    //   remoteStreams.length,
    //   [...remoteStreams, newStream].filter((stream) => !stream.deleted).length
    // );
    setTotalRemoteStreams(
      [...remoteStreams, newStream].filter((stream) => !stream.deleted).length
    );
  };

  return peerConnection;
};

export const answerPeer = (
  userId,
  fromUserId,
  localStream,
  remoteStreams,
  processRemoteStream,
  setTotalRemoteStreams,
  testPeersRef,
  socketRef,
  roomId
) => {
  // console.log("answerPeer");
  // console.log(localStream);
  let peerConstraints = {
    iceServers: [
      {
        urls: "stun:openrelay.metered.ca:80",
      },
      {
        urls: "turn:openrelay.metered.ca:80",
        username: "openrelayproject",
        credential: "openrelayproject",
      },
    ],
  };

  let peerConnection = new RTCPeerConnection(peerConstraints);
  peerConnection.userId = userId;
  // Add our stream to the peer connection.
  // console.log("(answerPeer) add local stream");
  peerConnection.addStream(localStream);

  //Add to peerRefs here
  // console.log("search for existing peerRef");
  // console.log(testPeersRef.current);

  let peerRef = testPeersRef.current.filter((peer) => {
    return peer && peer.userId === userId;
  })[0];

  if (!peerRef) {
    // console.log("(answerPeer) pushing " + userId + " to peersRef");
    testPeersRef.current = [
      ...testPeersRef.current,
      { userId: userId, connection: peerConnection },
    ];
    // console.log(testPeersRef.current);
  } else {
    // console.log("found an existing peer, updating connection");
    peerRef.connection = peerConnection;
  }

  peerConnection.addEventListener("connectionstatechange", () => {
    switch (peerConnection.connectionState) {
      case "closed":
        // You can handle the call being disconnected here.
        // console.log("connectionstate is now closed");
        break;
    }
  });

  peerConnection.addEventListener("icecandidate", (event) => {
    // When you find a null candidate then there are no more candidates.
    // Gathering of candidates has finished.
    if (!event.candidate) return;

    if (event.currentTarget.userId === fromUserId) {
      // console.log("ignoring my own icecandidate event");
      return;
    }

    socketRef.current.emit("ice-candidate", {
      fromUserId: fromUserId,
      toUserId: peerConnection.userId,
      roomId: roomId,
      candidate: event.candidate,
    });
  });
  peerConnection.addEventListener("icecandidateerror", () => {
    // You can ignore some candidate errors.
    // Connections can still be made even when errors occur.
    // console.log("ice candidate error");
    // console.log(event.errorCode + " " + event.errorText + " " + event.url);
  });

  peerConnection.addEventListener("iceconnectionstatechange", () => {
    switch (peerConnection.iceConnectionState) {
      case "connected":
      case "completed":
        // You can handle the call being connected here.
        // Like setting the video streams to visible.
        // console.log(
        //   "iceconnectionstate is now " + peerConnection.iceConnectionState
        // );
        break;
    }
  });

  peerConnection.addEventListener("negotiationneeded", (event) => {
    // console.log("negotiationneeded from " + event.target.userId);
    // console.log(event);

    // You can start the offer stages here.
    // Be careful as this event can be called multiple times.
    let sessionConstraints = {
      mandatory: {
        OfferToReceiveAudio: true,
        OfferToReceiveVideo: true,
        VoiceActivityDetection: true,
      },
    };

    // console.log("creating offer");
    peerConnection
      .createOffer(sessionConstraints)
      .then((offer) => {
        // console.log("setting offer against localDescription");
        return peerConnection.setLocalDescription(offer);
      })
      .then(() => {
        const payload = {
          fromUserId: fromUserId,
          toUserId: event.target.userId,
          roomId: roomId,
          sdp: peerConnection.localDescription,
        };
        // console.log("emitting 'offer' on socketRef");
        // console.log(payload);
        socketRef.current.emit("offer", payload);
      })
      .catch((err) =>
        console.log("Error handling negotiation needed event: " + err)
      );
  });

  peerConnection.addEventListener("signalingstatechange", () => {
    // console.log("signalingstatechange", peerConnection.signalingState);
    switch (peerConnection.signalingState) {
      case "closed":
        // You can handle the call being disconnected here.
        // console.log("signalingstate is now closed");

        break;
    }
  });

  peerConnection.addEventListener("track", () => {
    console.log("track eventListener in answerPeer");
  });

  peerConnection.ontrack = async (mediaTrack) => {
    // console.log("ontrack (answer)");
    // console.log(mediaTrack.streams[0]);
    let newStream = {
      userId: peerConnection.userId,
      stream: mediaTrack.streams[0],
    };

    processRemoteStream(newStream);
    // console.log(
    //   "web-rtc-functions peerConnection.onTrack (answer) setTotalStreams",
    //   remoteStreams.length,
    //   [...remoteStreams, newStream].filter((stream) => !stream.deleted).length
    // );
    setTotalRemoteStreams(
      [...remoteStreams, newStream].filter((stream) => !stream.deleted).length
    );
  };

  return peerConnection;
};
