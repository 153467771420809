import React, { useContext, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  useFonts,
  Roboto_700Bold,
  Roboto_400Regular,
} from "@expo-google-fonts/roboto";
import { Inter_400Regular } from "@expo-google-fonts/inter";
import {
  ActivityIndicator,
  Text,
  View,
  ScrollView,
  Pressable,
  Platform,
} from "react-native";
import {
  useQuery,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import axios from "axios";
import { Image } from "@rneui/themed";
import tailwind from "twrnc";
import Tag from "../base/Tag";
import ConnectModal from "./ConnectModal";
import {
  API_URL,
  DEFAULT_PROFILE_URL,
  FILL_COLOUR_5,
  FILL_COLOUR_WARNING,
  USER_TYPE_AMBASSADOR,
  USER_TYPE_PROVIDER,
} from "../base/Constants";
import Button from "../base/Button";
import { useNavigation } from "@react-navigation/native";
import { TrainerVideos } from "./TrainerVideos";
import {
  CurrentUserContext,
  NavigationSyncContext,
  ThemeContext,
} from "../base/ApplicationContext";
import PersonalTrainingCostsPanel from "./PersonalTrainingCostsPanel";

const queryClient = new QueryClient();

const TrainerProfilePanel = ({ id }) => {
  let [fontsLoaded] = useFonts({
    Roboto_700Bold,
    Roboto_400Regular,
    Inter_400Regular,
  });

  if (!fontsLoaded) {
    return null;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Panel id={id} />
    </QueryClientProvider>
  );
};

TrainerProfilePanel.propTypes = {
  id: PropTypes.number.isRequired,
};

function Panel({ id }) {
  const navigation = useNavigation();
  const { currentUser, isProfileComplete } = useContext(CurrentUserContext);
  const { theme } = useContext(ThemeContext);
  const { setFocusedScreen } = useContext(NavigationSyncContext);
  const [modalVisible, setModalVisible] = useState(false);
  const [isFollowing, setIsFollowing] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [isProviding, setIsProviding] = useState(false);
  const [followers, setFollowers] = useState(0);
  const connections = useRef(0);
  const videoCount = useRef(0);
  const { isLoading, error, data } = useQuery(["trainer" + id], () =>
    axios
      .get(API_URL + "trainers/" + id, {
        staleTime: 0,
        headers: { authorization: currentUser.token },
      })
      .then((res) => {
        const connectedUsers = [
          ...res.data.incomingConnections,
          ...res.data.outgoingConnections,
        ].map(
          (connection) =>
            (connection.sourceUser && connection.sourceUser.id) ||
            (connection.destinationUser && connection.destinationUser.id)
        );
        connections.current = connectedUsers.length;
        videoCount.current = res.data.videos.length;
        const isConnected = connectedUsers.find(
          (userId) => userId === currentUser.id
        );
        setIsConnected(isConnected);
        setIsFollowing(res.data.isFollowing);
        setIsProviding(res.data.isProvidingPersonalTraining);
        setFollowers(res.data.followers.length);
        return res.data;
      })
  );

  const handleFollowing = () => {
    setIsFollowing(!isFollowing);
    if (!isFollowing) {
      setFollowers(followers + 1);
      axios
        .post(
          API_URL + "userfollowing/follow",
          {
            sourceUserId: currentUser.id,
            destinationUserId: data.id,
          },
          {
            headers: {
              "Content-Type": "application/json",
              authorization: currentUser.token,
            },
          }
        )
        .catch((err) => {
          console.log(err);
        });
    } else {
      setFollowers(followers - 1);
      axios
        .post(
          API_URL + "userfollowing/unfollow",
          {
            sourceUserId: currentUser.id,
            destinationUserId: data.id,
          },
          {
            headers: {
              "Content-Type": "application/json",
              authorization: currentUser.token,
            },
          }
        )
        .catch((err) => {
          console.log(err);
        });
    }
  };

  if (isLoading) return <ActivityIndicator />;
  if (error) return <Text>An error has occurred: ${error.message}</Text>;

  const userId = currentUser.id;
  const userType = data.type.id;
  const firstName = data.firstName;
  const shortName = firstName + " " + data.lastName;
  const userTags = data.tags;
  const isProfileOwner = data.id === currentUser.id;

  const profileMessages = [];
  let key = 0;
  if (!isProfileComplete) {
    if (!data.profileUrl) {
      profileMessages.push({
        key: key,
        message: (
          <View
            style={tailwind`flex-row self-center bg-[${FILL_COLOUR_5}] rounded-full py-2 px-3 mb-1`}
          >
            <Image
              resizeMode="contain"
              style={tailwind`h-[18px] w-[18px]`}
              source={require("../../assets/warning-icon.png")}
            />
            <Text
              style={tailwind`text-[${FILL_COLOUR_WARNING}] font-semibold ml-2`}
            >
              You profile picture is not set
            </Text>
          </View>
        ),
      });
      key++;
    }
    if (!data.currency) {
      {
        profileMessages.push({
          key: key,
          message: (
            <View
              style={tailwind`flex-row self-center bg-[${theme.warningMessageBackgroundColor}] rounded-full py-2 px-3 mb-1`}
            >
              <Image
                resizeMode="contain"
                style={tailwind`h-[18px] w-[18px]`}
                source={require("../../assets/warning-icon.png")}
                tintColor={theme.redColor}
              />
              <Text
                style={tailwind`text-[${theme.redColor}] font-semibold ml-2`}
              >
                You haven&apos;t set your currency. You won&apos;t be able to
                host personal training until you do so.
              </Text>
            </View>
          ),
        });
        key++;
      }
    }
  }

  return (
    <View style={tailwind`flex-col w-full h-full`}>
      {profileMessages.length && isProfileOwner
        ? profileMessages.map((message) => (
            <View key={message.id}>{message.message}</View>
          ))
        : null}
      <View>
        <View style={tailwind`flex-row px-4 pt-4`}>
          <View style={tailwind`w-1/3`}>
            <View style={tailwind`w-32`}>
              <Image
                style={
                  Platform.OS === "web"
                    ? tailwind`w-[118px] h-[164px] rounded-lg`
                    : tailwind`w-[59px] h-[82px] rounded-lg`
                }
                source={{ uri: data.profileUrl || DEFAULT_PROFILE_URL }}
                PlaceholderContent={<ActivityIndicator />}
              />
            </View>
            <View style={tailwind`flex-col pr-4`}>
              <View>
                <View style={tailwind`self-start`}>
                  <Text
                    style={[
                      tailwind`capitalize text-[${theme.textColor}] font-bold text-[18px] mt-3`,
                      { fontFamily: "Roboto_700Bold" },
                    ]}
                  >
                    {shortName}
                  </Text>
                </View>
                {userType === USER_TYPE_AMBASSADOR ? (
                  <View style={tailwind`w-full flex-row my-2`}>
                    <Image
                      resizeMode="contain"
                      style={tailwind`h-[16px] w-[13px] mr-2`}
                      source={require("../../assets/ambassador-icon.png")}
                    />
                    <Text
                      style={[
                        tailwind`text-[${theme.textColor}] self-center font-semibold`,
                        { fontFamily: "Roboto_400Regular" },
                      ]}
                    >
                      Ambassador
                    </Text>
                  </View>
                ) : null}
              </View>
              <View style={tailwind`flex-row max-w-[118px]`}>
                <View style={tailwind`w-1/3`}>
                  <View style={tailwind`self-end`}>
                    <Image
                      resizeMode="contain"
                      style={tailwind`h-[18px] w-[18px]`}
                      source={require("../../assets/connections-icon.png")}
                    />
                    <Text
                      style={[
                        tailwind`font-bold text-[${theme.textColor}] self-end`,
                        { fontFamily: "Roboto_400Regular" },
                      ]}
                    >
                      {Math.abs(connections.current)}
                    </Text>
                  </View>
                </View>
                <View style={tailwind`w-1/3`}>
                  <View style={tailwind`self-end`}>
                    <Image
                      resizeMode="contain"
                      style={tailwind`h-[18px] w-[18px]`}
                      source={require("../../assets/followers-icon.png")}
                    />
                    <Text
                      style={[
                        tailwind`font-bold text-[${theme.textColor}] self-end`,
                        { fontFamily: "Roboto_400Regular" },
                      ]}
                    >
                      {Math.abs(followers)}
                    </Text>
                  </View>
                </View>
                <View style={tailwind`w-1/3`}>
                  <View style={tailwind`self-end`}>
                    <Image
                      resizeMode="contain"
                      style={tailwind`h-[18px] w-[18px]`}
                      source={require("../../assets/videos-icon.png")}
                    />
                    <Text
                      style={[
                        tailwind`font-bold text-[${theme.textColor}] self-end`,
                        { fontFamily: "Roboto_400Regular" },
                      ]}
                    >
                      {videoCount.current}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={tailwind`w-2/3`}>
            {isProviding ? (
              <View style={tailwind`w-full mb-2`}>
                {!isConnected ? (
                  <Text
                    style={[
                      tailwind`text-[${theme.textColor}]`,
                      { fontFamily: "Roboto_400Regular" },
                    ]}
                  >
                    {firstName} welcomes connections from those interested in
                    online personal training.
                  </Text>
                ) : null}
                {userType === USER_TYPE_AMBASSADOR ||
                (userType === USER_TYPE_PROVIDER &&
                  isProviding &&
                  isConnected) ? (
                  <PersonalTrainingCostsPanel
                    id={id}
                    firstName={firstName}
                    shortName={shortName}
                    data={data}
                    isProfileOwner={isProfileOwner}
                    navigation={navigation}
                  />
                ) : null}
              </View>
            ) : (
              <View style={tailwind`mb-2 mx-2 w-full`}>
                <Text
                  style={[
                    tailwind`self-center text-[${theme.textColor}] capitalize`,
                    { fontFamily: "Roboto_700Bold" },
                  ]}
                >
                  {firstName.toUpperCase()} IS NOT CURRENTLY ACCEPTING NEW
                  CONNECTIONS FOR ONLINE PERSONAL TRAINING
                </Text>
              </View>
            )}
            <View style={tailwind`flex-row`}>
              <View style={tailwind`grow`} />
              <View style={tailwind`self-end mr-4`}>
                <Pressable
                  onPress={() => {
                    setModalVisible(!modalVisible);
                  }}
                  style={tailwind`flex-row`}
                >
                  {isConnected || isProfileOwner || !isProviding ? null : (
                    <>
                      <Image
                        resizeMode="contain"
                        style={tailwind`h-[18px] w-[18px]`}
                        source={require("../../assets/connect-icon.png")}
                      />
                      <Text
                        style={[
                          tailwind`text-[${theme.textColor}] font-semibold ml-2`,
                          { fontFamily: "Roboto_400Regular" },
                        ]}
                      >
                        Connect
                      </Text>
                    </>
                  )}
                </Pressable>
              </View>
              {!isProfileOwner ? (
                <View style={tailwind`mr-4 self-end`}>
                  <Pressable
                    onPress={() => handleFollowing()}
                    style={tailwind`flex-row`}
                  >
                    {isFollowing ? (
                      <>
                        <Image
                          resizeMode="contain"
                          style={tailwind`h-[18px] w-[18px]`}
                          source={require("../../assets/following-icon.png")}
                        />
                        <Text
                          style={[
                            tailwind`text-[${theme.textColor}] font-semibold m-auto ml-2`,
                            { fontFamily: "Roboto_400Regular" },
                          ]}
                        >
                          Following
                        </Text>
                      </>
                    ) : (
                      <>
                        <Image
                          resizeMode="contain"
                          style={tailwind`h-[18px] w-[18px]`}
                          source={require("../../assets/follow-icon.png")}
                        />
                        <Text
                          style={[
                            tailwind`text-[${theme.textColor}] font-semibold m-auto ml-2`,
                            { fontFamily: "Roboto_400Regular" },
                          ]}
                        >
                          Follow
                        </Text>
                      </>
                    )}
                  </Pressable>
                </View>
              ) : null}
            </View>
          </View>
        </View>
      </View>

      <ScrollView>
        <View style={tailwind`px-4`}>
          <Text
            style={[
              tailwind`text-[${theme.textColor}] font-semibold py-2`,
              { fontFamily: "Inter_400Regular" },
            ]}
          >
            {data.profileText}
          </Text>
        </View>
        <View style={tailwind`px-4 my-2`}>
          <View style={tailwind`flex flex-row mt-2 flex-wrap`}>
            {userTags.map((userTag) => (
              <Tag
                key={userTag.id}
                label={userTag.tagId.tagName}
                colorKey={userTag.tagId.tagColorKey}
              />
            ))}
          </View>
        </View>
        <TrainerVideos id={id} firstName={firstName} />
      </ScrollView>
      {isProfileOwner ? (
        <View style={tailwind`self-center mt-2`}>
          <Button
            title="Edit"
            onPress={() => {
              setFocusedScreen("EditProfileScreen");
              navigation.navigate("EditProfileScreen", {
                userId: userId,
                userType: userType,
              });
            }}
          />
        </View>
      ) : null}
      <ConnectModal
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        id={id}
        firstName={firstName}
        profileUrl={data.profileUrl || DEFAULT_PROFILE_URL}
      />
    </View>
  );
}

Panel.propTypes = {
  id: PropTypes.number.isRequired,
};

export { TrainerProfilePanel as default };
