import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { View, Text, Dimensions } from "react-native";
import tailwind from "twrnc";
import $ from "jquery";
import PeerVideoControls from "../shared/PeerVideoControls";
import { CurrentUserContext } from "../../base/ApplicationContext";

const PeerVideo = ({
  peerStream,
  thisParticipant,
  isHost,
  providerMuted,
  totalStreams,
}) => {
  const { currentUser } = useContext(CurrentUserContext);
  const [isMuted, setIsMuted] = useState(false);
  const [isMicMuted, setIsMicMuted] = useState(false);
  const [videoShown, setVideoShown] = useState(true);
  const [myVideoContainerStyle, setMyVideoContainerStyle] = useState();
  const [myVideoStyle, setMyVideoStyle] = useState();
  const [videoHovered, setVideoHovered] = useState(false);
  const videoRef = useRef();

  //Local stream / first render
  useEffect(() => {
    let videoStream = $("#" + thisParticipant.id)[0];
    if (peerStream && peerStream.stream && videoStream)
      videoStream.srcObject = peerStream.stream;
    if (thisParticipant.id === currentUser.id) {
      setIsMuted(true);
    } else {
      setIsMuted(providerMuted);
    }

    let attendeeWidth;
    let attendeeHeight;

    attendeeWidth =
      (Dimensions.get("window").width - 160 - 350) /
      (totalStreams <= 3 ? totalStreams : 3);
    attendeeHeight = Dimensions.get("window").height - 550;

    videoStyleBuilder(
      isHost,
      currentUser.id,
      thisParticipant,
      "150px",
      "114px",
      (Dimensions.get("window").height - 350) * 0.94, //w
      Dimensions.get("window").height - 350, //h
      attendeeWidth,
      attendeeHeight,
      videoShown,
      setMyVideoStyle,
      setMyVideoContainerStyle
    );

    if (videoStream) videoStream.play();
  }, []);

  //Remote streams
  useEffect(() => {
    let attendeeWidth;
    let attendeeHeight;

    attendeeWidth =
      (Dimensions.get("window").width - 160 - 350) /
      (totalStreams <= 3 ? totalStreams : 3);
    attendeeHeight = attendeeWidth * 0.95;

    videoStyleBuilder(
      isHost,
      currentUser.id,
      thisParticipant,
      "150px",
      "114px",
      (Dimensions.get("window").height - 350) * 0.94, //w
      Dimensions.get("window").height - 350, //h
      attendeeWidth,
      attendeeHeight,
      videoShown,
      setMyVideoStyle,
      setMyVideoContainerStyle
    );
  }, [totalStreams]);

  useEffect(() => {
    let videoStream = $("#" + thisParticipant.id)[0];
    if (videoStream) videoStream.muted = isMuted;
  }, [isMuted]);

  useEffect(() => {
    let videoStream = $("#" + thisParticipant.id)[0];
    if (thisParticipant.id === currentUser.id) {
      setIsMuted(true);
      videoStream.muted = true;
    } else {
      setIsMuted(providerMuted);
      if (videoStream) videoStream.muted = providerMuted;
    }
  }, [providerMuted]);

  useEffect(() => {
    if (peerStream && peerStream.stream)
      if (isMicMuted) {
        peerStream.stream.getAudioTracks()[0].enabled = false;
      } else {
        peerStream.stream.getAudioTracks()[0].enabled = true;
      }
  }, [isMicMuted]);

  useEffect(() => {
    if (
      peerStream &&
      peerStream.stream &&
      peerStream.stream.getVideoTracks().length
    )
      peerStream.stream.getVideoTracks()[0].enabled = videoShown;
  }, [videoShown]);

  const handleMouseOver = () => {
    setVideoHovered(true);
    setTimeout(() => setVideoHovered(false), 4000);
  };

  return (
    <View style={myVideoContainerStyle}>
      <video
        ref={videoRef}
        id={thisParticipant.id}
        playsInline
        style={myVideoStyle}
        onMouseOver={handleMouseOver}
      />
      <View style={[tailwind`absolute bottom-3 left-3`]}>
        {videoHovered || isMicMuted || !videoShown ? (
          <>
            <Text style={tailwind`ml-2 mb-1 text-[#FFFFFF] font-semibold`}>
              {thisParticipant.name}
            </Text>

            <PeerVideoControls
              videoRef={videoRef}
              thisParticipant={thisParticipant}
              isMicMuted={isMicMuted}
              setIsMicMuted={setIsMicMuted}
              isMuted={isMuted}
              setIsMuted={setIsMuted}
              providerMuted={providerMuted}
              videoShown={videoShown}
              setVideoShown={setVideoShown}
              isHost={isHost}
            />
          </>
        ) : null}
      </View>
    </View>
  );
};

const videoStyleBuilder = (
  isHost,
  currentUserId,
  thisParticipant,
  myVideoWidth,
  myVideoHeight,
  providerVideoWidth,
  providerVideoHeight,
  attendeeWidth,
  attendeeHeight,
  videoShown,
  setMyVideoStyle,
  setMyVideoContainerStyle
) => {
  let videoStyle = null;
  if (!isHost && currentUserId == thisParticipant.id) {
    //My Video
    videoStyle = tailwind`w-[${myVideoWidth}] h-[${myVideoHeight}] p-1`;
  } else if (isHost) {
    //Hosts Video
    videoStyle = tailwind`h-[${providerVideoHeight}px] p-1 self-center`; //w-[${providerVideoWidth}px]
  } else {
    //Other Callers Video
    videoStyle = tailwind`w-[${attendeeWidth}px] max-w-[${attendeeWidth}px] max-h-[${attendeeHeight}px] p-1`;
  }

  if (!videoShown) {
    videoStyle = tailwind`w-[0px] h-[0px] p-0`;
  }

  setMyVideoStyle(videoStyle);

  let videoContainerStyle;
  videoContainerStyle = isHost
    ? tailwind`self-center w-[${providerVideoWidth + 20}px]`
    : tailwind`mx-1 p-1 my-1`;

  setMyVideoContainerStyle(videoContainerStyle);
};

PeerVideo.propTypes = {
  peerStream: PropTypes.object,
  thisParticipant: PropTypes.object.isRequired,
  isHost: PropTypes.bool,
  providerMuted: PropTypes.bool,
  isFullScreen: PropTypes.number,
  totalStreams: PropTypes.number,
};

export default PeerVideo;
