import React, { createContext } from "react";
import PropTypes from "prop-types";
import {
  RTCIceCandidate,
  RTCSessionDescription,
} from "react-native-webrtc-web-shim";
import { io } from "socket.io-client";
import { SOCKET_URL } from "../../base/Constants";
import { answerPeer, connectToPeer } from "./web-rtc-functions";

let socketRef = {
  current: null,
  connectSocket: async () => {
    socketRef.current = await io.connect(SOCKET_URL);
  },
};

socketRef.sendMessage = (currentUser, roomId, message) => {
  if (socketRef.current) {
    socketRef.current.emit(
      "add-message",
      currentUser.id,
      currentUser.firstName,
      currentUser.profileUrl,
      roomId,
      message,
      currentUser.token
    );
  }
};

socketRef.connectToPrivateChatRoom = (
  currentUser,
  roomId,
  newMessageCallback,
  userJoinedCallback,
  messageHistoryCallback,
  errorCallback
) => {
  if (socketRef.current) {
    socketRef.current.emit(
      "join room private",
      roomId,
      currentUser.id,
      currentUser.token
    );

    socketRef.current.on("authentication error", () => {
      console.log("Authentication error from socket server");
      errorCallback();
    });

    socketRef.current.on("message-history", (messageHistory) =>
      messageHistoryCallback(messageHistory)
    );

    socketRef.current.on("new-message", (newMessage) => {
      if (newMessage.user._id !== currentUser.id) {
        newMessageCallback(newMessage);
      }
    });
  }

  socketRef.current.on("user joined", (userId, socketId, messageHistory) => {
    userJoinedCallback(userId, messageHistory);
  });

  return socketRef;
};

socketRef.disconnectFromPrivateChat = () => {
  if (socketRef.current) {
    socketRef.current.close();
  }
  socketRef.current = null;
};

socketRef.connectToRoom = (
  localStream,
  roomId,
  currentUser,
  remoteStreams,
  processRemoteStream,
  setTotalRemoteStreams,
  peersRef,
  newMessageCallback,
  userJoinedCallback
) => {
  socketRef.current.emit(
    "join room",
    roomId,
    currentUser.id,
    currentUser.token
  );

  socketRef.current.on("authentication error", () => {
    console.log("Authentication error from socket server");
  });

  socketRef.current.on("user left", (userId) =>
    userLeftCall(
      userId,
      peersRef,
      remoteStreams,
      processRemoteStream,
      setTotalRemoteStreams
    )
  );

  socketRef.current.on("new-message", (newMessage) => {
    if (newMessage.user._id !== currentUser.id) {
      newMessageCallback(newMessage);
    }
  });

  socketRef.current.on("user joined", (userId, socketId, messageHistory) => {
    if (userId !== currentUser.id) {
      let otherPeer =
        peersRef &&
        peersRef.current &&
        peersRef.current.filter((peer) => {
          return peer.userId === userId;
        })[0];
      if (otherPeer) {
        otherPeer.connection = answerPeer(
          userId,
          currentUser.id,
          localStream,
          remoteStreams,
          processRemoteStream,
          setTotalRemoteStreams,
          peersRef,
          socketRef,
          roomId
        );
      } else {
        if (peersRef && peersRef.current)
          peersRef.current.push({
            userId: userId,
          });
        if (peersRef && peersRef.current) {
          let peer = peersRef.current.filter((peer) => {
            return peer.userId === userId;
          })[0];
          peer.connection = connectToPeer(
            userId,
            localStream,
            remoteStreams,
            processRemoteStream,
            setTotalRemoteStreams,
            peersRef,
            socketRef,
            currentUser.id,
            roomId
          );
        }
      }
    } else {
      userJoinedCallback(userId, messageHistory);
    }
  });

  socketRef.current.on("offer", (incoming) => {
    if (incoming.fromUserId === currentUser.id) {
      return;
    }

    let testPeer = peersRef.current.filter((peer) => {
      return peer.userId === incoming.fromUserId;
    })[0];

    let peerConnection = null;
    if (!testPeer) {
      peersRef.current = [...peersRef.current, { userId: incoming.fromUserId }];

      peerConnection = answerPeer(
        incoming.fromUserId,
        currentUser.id,
        localStream,
        remoteStreams,
        processRemoteStream,
        setTotalRemoteStreams,
        peersRef,
        socketRef,
        roomId
      );
    }

    testPeer = peersRef.current.filter((peer) => {
      return peer.userId === incoming.fromUserId;
    })[0];

    if (!testPeer) {
      console.log("should have had a peer at this point and didnt");
      return;
    }

    const desc = new RTCSessionDescription(incoming.sdp);

    if (peerConnection || testPeer.connection) {
      testPeer.connection
        .setRemoteDescription(desc)
        .then(() => {})
        .then(() => {
          return testPeer.connection.createAnswer();
        })
        .then((answer) => {
          return testPeer.connection.setLocalDescription(answer);
        })
        .then(() => {
          const payload = {
            fromUserId: currentUser.id,
            toUserId: incoming.fromUserId,
            roomId: roomId,
            sdp: testPeer.connection.localDescription,
          };
          console.log("emitting answer");
          socketRef.current.emit("answer", payload);
        });
      // .catch((e) => console.log(e));
    } else {
      console.log(
        "peer has no connection against which to setRemoteDescription"
      );
    }
  });

  socketRef.current.on("answer", (message) => {
    const desc = new RTCSessionDescription(message.sdp);

    let peer = peersRef.current.filter((peer) => {
      return peer.userId === message.fromUserId;
    })[0];

    if (peer) {
      peer.connection.setRemoteDescription(desc);
      // .catch((e) => console.log("Error handle answer", e));
    } else {
      console.log("no peer to which to add remote description");
    }
  });

  socketRef.current.on("ice-candidate", (incoming) => {
    const candidate = new RTCIceCandidate(incoming.candidate);

    let peer = peersRef.current.filter((peer) => {
      return peer.userId === incoming.fromUserId;
    })[0];

    if (peer && peer.connection) {
      peer.connection
        .addIceCandidate(candidate)
        .catch((e) =>
          console.log("ice-candidate error " + e.code + " " + e.message)
        );
    } else {
      console.log("peer has no connection yet :(");
    }
  });
};

socketRef.disconnectCall = (
  localStream,
  setLocalStream,
  remoteStreams,
  processRemoteStream,
  setTotalRemoteStreams,
  peersRef
) => {
  if (remoteStreams) {
    remoteStreams.forEach((remoteStream) => {
      processRemoteStream({ userId: remoteStream.userId, deleted: true });
      if (remoteStream.stream) {
        remoteStream.stream.getTracks().forEach((track) => {
          track.stop();
        });
      }
      setTotalRemoteStreams(remoteStreams.filter((stream) => !stream.deleted));
    });
  }

  if (peersRef) {
    peersRef.current.forEach((peerRef) => {
      peerRef.connection && peerRef.connection.close();
    });
  }

  if (peersRef) peersRef.current = [];
  if (localStream) {
    localStream.getTracks().forEach((track) => {
      track.stop();
    });
  }
  if (setLocalStream) setLocalStream(null);
  if (socketRef.current) {
    socketRef.current.close();
  }
  socketRef.current = null;
};

const userLeftCall = (
  userId,
  peersRef,
  remoteStreams,
  processRemoteStream,
  setTotalRemoteStreams
) => {
  let leavingPeer =
    peersRef &&
    peersRef.current &&
    peersRef.current.filter((peer) => {
      return peer.userId === userId;
    })[0];

  if (leavingPeer) {
    processRemoteStream({ userId: leavingPeer.userId, deleted: true });

    let remainingPeers = peersRef.current.filter((peer) => {
      return peer.userId !== leavingPeer.userId;
    });

    peersRef.current = remainingPeers;
    setTotalRemoteStreams(remoteStreams.filter((stream) => !stream.deleted));
  }
};

const SocketProvider = ({ children }) => {
  return (
    <SocketContext.Provider value={{ socketRef }}>
      {children}
    </SocketContext.Provider>
  );
};

SocketProvider.propTypes = {
  children: PropTypes.object.isRequired,
};

export const SocketContext = createContext({
  socketRef: socketRef,
});

export default SocketProvider;
