import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { SafeAreaView, View } from "react-native";
import tailwind from "twrnc";
import SocketProvider, {
  SocketContext,
} from "../private-room/web/SocketProvider";
import { GiftedChat } from "react-native-gifted-chat";
import SocketChat from "../private-room/shared/SocketChat";
import ErrorMessage from "../base/ErrorMessage";
import {
  BottomSheetContext,
  CurrentUserContext,
  ThemeContext,
} from "../base/ApplicationContext";

const ChatWindow = ({ params }) => {
  const { firstName, lastName, userType, profileUrl, roomId } = params;

  const { socketRef } = useContext(SocketContext);

  const { currentUser } = useContext(CurrentUserContext);
  const { setBottomSheetContent, setBottomSheetVisible } =
    useContext(BottomSheetContext);
  const { theme } = useContext(ThemeContext);
  const [messages, setMessages] = useState([]);
  const messagesRef = useRef([]);

  useEffect(() => {
    init();
    return () => {
      socketRef.disconnectFromPrivateChat();
    };
  }, [roomId]);

  const addNewMessage = (newMessage) => {
    GiftedChat.append(messages, newMessage, true);
    setMessages([...messagesRef.current, newMessage]);
    messagesRef.current.push(newMessage);
  };

  const init = async () => {
    socketRef.disconnectFromPrivateChat();
    await socketRef.connectSocket();
    socketRef.connectToPrivateChatRoom(
      currentUser,
      roomId,
      (newMessage) => {
        addNewMessage(newMessage);
      },
      (userId, messageHistory) => {
        if (userId === currentUser.id) {
          messagesRef.current = messageHistory;
          setMessages(messagesRef.current);
        }
      },
      (messageHistory) => {
        messagesRef.current = messageHistory;
      },
      () => {
        setBottomSheetContent(
          <ErrorMessage
            errorMessage={
              "There was a problem connecting to the chat server, please try again."
            }
          />
        );
        setBottomSheetVisible(true);
      }
    );
  };

  const onSend = (newMessages) => {
    newMessages.forEach(
      (message) => (message.createdAt = message.createdAt.toISOString())
    );
    socketRef.sendMessage(currentUser, roomId, newMessages[0]);
    messagesRef.current.push(newMessages[0]);
    setMessages(messagesRef.current);
  };

  return (
    <SocketProvider>
      <SafeAreaView style={tailwind`bg-[${theme.backgroundColor}] h-full`}>
        <View style={tailwind`max-w-[800px]`}>
          <SocketChat event={{}} messages={messages} onSend={onSend} />
        </View>
      </SafeAreaView>
    </SocketProvider>
  );
};

ChatWindow.propTypes = {
  params: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    userType: PropTypes.number,
    profileUrl: PropTypes.string,
    roomId: PropTypes.string,
  }),
};

export { ChatWindow };
