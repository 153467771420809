import * as React from "react";
import PropTypes from "prop-types";
import { View, Text } from "react-native";
import tailwind from "twrnc";
import OnDemandDetails from "./OnDemandDetails";
import ExpoPlayer from "../video-players/vod/ExpoPlayer";
import { ThemeContext } from "../base/ApplicationContext";
const LandscapeLayout = ({ video }) => {
  const { theme } = React.useContext(ThemeContext);
  return (
    <View style={tailwind`h-full w-full flex-row pr-4`}>
      <ExpoPlayer video={video} />
      <View style={tailwind`w-[40%] pl-2`}>
        <Text>LandscapeLayout</Text>
        <View
          style={tailwind`w-full border-t-2 border-t-[${theme.textColor}] mt-2`}
        >
          <OnDemandDetails video={video} />
        </View>
      </View>
    </View>
  );
};

LandscapeLayout.propTypes = {
  video: PropTypes.object.isRequired,
};

export default LandscapeLayout;
