import React, { useContext } from "react";
import PropTypes from "prop-types";
import {
  useQuery,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import axios from "axios";
import { ActivityIndicator, Text } from "react-native";
import { API_URL } from "../base/Constants";
import { CurrentUserContext } from "../base/ApplicationContext";
import VideoThumbnail from "../home-screen/OnDemandThumbnail";
import YoutubeThumbnail from "../home-screen/YoutubeThumbnail";

const queryClient = new QueryClient();

const SearchOnDemandThumbnailStrip = ({ section, isDynamic }) => {
  const { currentUser } = useContext(CurrentUserContext);
  return (
    <QueryClientProvider client={queryClient}>
      <VideoStrip
        sectionId={section.id}
        userId={currentUser.id}
        isDynamic={isDynamic}
      />
    </QueryClientProvider>
  );
};

SearchOnDemandThumbnailStrip.propTypes = {
  section: PropTypes.object.isRequired,
  isDynamic: PropTypes.bool,
};

function VideoStrip({ sectionId, userId, isDynamic }) {
  const { currentUser, searchQuery } = useContext(CurrentUserContext);
  const { isLoading, error, data } = useQuery(
    ["ondemand" + sectionId + searchQuery],
    () =>
      axios
        .get(
          API_URL +
            "search/section/" +
            sectionId +
            "/" +
            userId +
            "/" +
            searchQuery,
          {
            headers: { authorization: currentUser.token },
          }
        )
        .then((res) => res.data),
    {
      refetchOnWindowFocus: 60000,
    }
  );

  if (isLoading) return <ActivityIndicator />;
  if (error) return <Text>{"An error has occurred: " + error.message}</Text>;

  return (
    <>
      {data.map((video) => {
        if (isDynamic) {
          return video.youtubeId ? (
            <YoutubeThumbnail
              key={video.id}
              video={{
                id: video.id,
                video: video,
              }}
            />
          ) : (
            <VideoThumbnail
              key={video.id}
              id={video.id}
              video={{ id: video.id, video: video }}
            />
          );
        } else {
          if (video.video)
            return video.video.youtubeId ? (
              <YoutubeThumbnail key={video.id} video={video} />
            ) : (
              <VideoThumbnail key={video.id} id={video.id} video={video} />
            );
        }
      })}
    </>
  );
}

VideoStrip.propTypes = {
  sectionId: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  isDynamic: PropTypes.bool,
};

export default SearchOnDemandThumbnailStrip;
