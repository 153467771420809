import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { ActivityIndicator, Text, Pressable, View } from "react-native";
import tailwind from "twrnc";
import { StyledInput } from "../base/StyledInput";
import { API_URL } from "../base/Constants";
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";
import axios from "axios";
import { StyledPicker } from "../base/StyledPicker";
import { Image } from "@rneui/themed";
import { CurrentUserContext, ThemeContext } from "../base/ApplicationContext";
import {
  Roboto_400Regular,
  Roboto_700Bold,
  useFonts,
} from "@expo-google-fonts/roboto";

const PersonalTrainingBundles = ({
  singleSessionCost,
  currency,
  currencies,
}) => {
  const { currentUser } = useContext(CurrentUserContext);
  const queryClient = new QueryClient();
  const queryKey = ["bundles" + currentUser.id + Date.now()];

  let fonts = useFonts({ Roboto_400Regular, Roboto_700Bold });
  if (!fonts) return;

  return (
    <View style={tailwind`mt-4`}>
      <QueryClientProvider client={queryClient}>
        <ExistingBundles
          singleSessionCost={singleSessionCost}
          currency={currency}
          currencies={currencies}
          queryKey={queryKey}
          queryClient={queryClient}
        />
      </QueryClientProvider>
      <AddBundleForm
        userId={currentUser.id}
        queryKey={queryKey}
        queryClient={queryClient}
      />
    </View>
  );
};

const ExistingBundles = ({
  singleSessionCost,
  currency,
  currencies,
  queryKey,
  queryClient,
}) => {
  const { currentUser } = useContext(CurrentUserContext);
  const { theme } = useContext(ThemeContext);
  const { isLoading, error, data } = useQuery(queryKey, () =>
    axios
      .get(API_URL + "sessionbundle/" + currentUser.id, {
        staleTime: 0,
        headers: { authorization: currentUser.token },
      })
      .then((res) => {
        return res.data;
      })
  );

  if (isLoading) return <ActivityIndicator />;
  if (error) return <Text>An error has occurred: ${error.message}</Text>;

  const deleteSubmit = async (bundleId) => {
    axios
      .delete(API_URL + "sessionbundle/" + bundleId, {
        headers: { authorization: currentUser.token },
      })
      .then(() => {
        queryClient.invalidateQueries(queryKey);
      });
  };

  let fullCurrency = currencies.filter(
    (currencyItem) => currencyItem.id === currency
  );

  let currencySymbol = "£";
  if (fullCurrency.length) {
    currencySymbol = fullCurrency[0].symbol;
  }

  return (
    <View style={tailwind`w-full mt-2`}>
      <Text
        style={[
          tailwind`text-[${theme.textColor}] font-semibold mb-2 text-[20px]`,
          { fontFamily: "Roboto_700Bold" },
        ]}
      >
        Your Discount Bundles
      </Text>
      <View style={tailwind`flex-row mb-1 w-full`}>
        <View style={tailwind`w-1/9`}>
          <Text
            style={[
              tailwind`text-[${theme.textColor}] font-semibold`,
              { fontFamily: "Roboto_400Regular" },
            ]}
          >
            Sessions
          </Text>
        </View>
        <View style={tailwind`w-2/9 mr-2`}>
          <Text
            style={[
              tailwind`text-[${theme.textColor}] font-semibold`,
              { fontFamily: "Roboto_400Regular" },
            ]}
          >
            Type
          </Text>
        </View>
        <View style={tailwind`w-1/9`}>
          <Text
            style={[
              tailwind`text-[${theme.textColor}] font-semibold`,
              { fontFamily: "Roboto_400Regular" },
            ]}
          >
            Fee
          </Text>
        </View>
        <View style={tailwind`w-1/9`}>
          <Text
            style={[
              tailwind`text-[${theme.textColor}] font-semibold`,
              { fontFamily: "Roboto_400Regular" },
            ]}
          >
            Net
          </Text>
        </View>
        <View style={tailwind`w-1/9`}>
          <Text
            style={[
              tailwind`text-[${theme.textColor}] font-semibold`,
              { fontFamily: "Roboto_400Regular" },
            ]}
          >
            VAT
          </Text>
        </View>
        <View style={tailwind`w-1/9`}>
          <Text
            style={[
              tailwind`text-[${theme.textColor}] font-semibold`,
              { fontFamily: "Roboto_400Regular" },
            ]}
          >
            Total
          </Text>
        </View>
        <View style={tailwind`w-1/9`} />
      </View>
      {data &&
        data.map((bundleItem) => {
          let calculatedNetTotal = 0;
          let calculatedNetTotalWithDiscount = 0;

          switch (bundleItem.discountType.id) {
            case 1:
              calculatedNetTotal = singleSessionCost * bundleItem.sessions;
              calculatedNetTotalWithDiscount =
                calculatedNetTotal -
                (bundleItem.discountAmount / 100) * calculatedNetTotal;
              break;
            case 2:
            default:
              calculatedNetTotal = singleSessionCost * bundleItem.sessions;
              calculatedNetTotalWithDiscount =
                calculatedNetTotal - bundleItem.discountAmount;
              break;
          }

          let serviceFee = 1.5 * bundleItem.sessions;
          let calculatedNetTotalWithDiscountAndFee =
            calculatedNetTotalWithDiscount + serviceFee;
          let vatTotal = (20 / 100) * calculatedNetTotalWithDiscountAndFee;
          let grandTotal = Number(
            calculatedNetTotalWithDiscountAndFee + vatTotal
          );

          calculatedNetTotalWithDiscountAndFee =
            calculatedNetTotalWithDiscountAndFee.toFixed(2);
          serviceFee = serviceFee.toFixed(2);
          vatTotal = vatTotal.toFixed(2);
          grandTotal = grandTotal.toFixed(2);

          return (
            <View key={bundleItem.id} style={tailwind`w-full`}>
              <View style={tailwind`flex-row mb-2`}>
                <View style={tailwind`w-1/9`}>
                  <Text
                    style={[
                      tailwind`text-[${theme.textColor}]`,
                      { fontFamily: "Roboto_400Regular" },
                    ]}
                  >
                    {bundleItem.sessions}
                  </Text>
                </View>
                <View style={tailwind`w-2/9 flex-row mr-2`}>
                  <Text
                    style={[
                      tailwind`text-[${theme.textColor}] min-w-[80px]`,
                      { fontFamily: "Roboto_400Regular" },
                    ]}
                  >
                    {bundleItem.discountType.name}
                  </Text>
                  <Text
                    style={[
                      tailwind`text-[${theme.textColor}]`,
                      { fontFamily: "Roboto_400Regular" },
                    ]}
                  >
                    {bundleItem.discountType.id === 2 && currencySymbol}
                    {bundleItem.discountAmount.toFixed(2)}
                    {bundleItem.discountType.id === 1 && "%"}
                  </Text>
                </View>
                <View style={tailwind`w-1/9`}>
                  <Text
                    style={[
                      tailwind`text-[${theme.textColor}]`,
                      { fontFamily: "Roboto_400Regular" },
                    ]}
                  >
                    {currencySymbol}
                    {serviceFee}
                  </Text>
                </View>
                <View style={tailwind`w-1/9`}>
                  <Text
                    style={[
                      tailwind`text-[${theme.textColor}]`,
                      { fontFamily: "Roboto_400Regular" },
                    ]}
                  >
                    {currencySymbol}
                    {calculatedNetTotalWithDiscountAndFee}
                  </Text>
                </View>
                <View style={tailwind`w-1/9`}>
                  <Text
                    style={[
                      tailwind`text-[${theme.textColor}]`,
                      { fontFamily: "Roboto_400Regular" },
                    ]}
                  >
                    {currencySymbol}
                    {vatTotal}
                  </Text>
                </View>
                <View style={tailwind`w-1/9`}>
                  <Text
                    style={[
                      tailwind`text-[${theme.textColor}]`,
                      { fontFamily: "Roboto_400Regular" },
                    ]}
                  >
                    {currencySymbol}
                    {grandTotal}
                  </Text>
                </View>
                <View style={tailwind`w-1/9 pl-4`}>
                  <Pressable onPress={() => deleteSubmit(bundleItem.id)}>
                    <Image
                      style={tailwind`w-[20px] h-[20px]`}
                      source={{
                        uri: require("../../assets/delete-icon.png"),
                      }}
                      PlaceholderContent={<ActivityIndicator />}
                      tintColor={theme.bundleDeleteIconColor}
                    />
                  </Pressable>
                </View>
              </View>
            </View>
          );
        })}
    </View>
  );
};

AddBundleForm.propTypes = {
  userId: PropTypes.number,
  queryKey: PropTypes.array.isRequired,
  queryClient: PropTypes.instanceOf(QueryClient).isRequired,
};

function AddBundleForm(props) {
  const { userId, queryKey, queryClient } = props;
  const { currentUser } = useContext(CurrentUserContext);
  const { theme } = useContext(ThemeContext);
  const [sessions, setSessions] = useState();
  const [discountType, setDiscountType] = useState();
  const [discountAmount, setDiscountAmount] = useState();

  const addSubmit = async () => {
    const submitData = {
      sessions: sessions,
      sessionDiscountTypeId: discountType,
      discountAmount: discountAmount,
      userId: userId,
    };

    try {
      await axios
        .post(API_URL + "sessionbundle", submitData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: currentUser.token,
          },
        })
        .then(() => {
          queryClient.invalidateQueries(queryKey);
        });
    } catch (error) {
      const { response } = error;
      console.log(response);
    }
  };

  return (
    <View style={tailwind`mt-4 mb-2 py-2 self-center`}>
      <form>
        <Text
          style={[
            tailwind`text-[${theme.textColor}] font-semibold mb-2 text-[18px]`,
            { fontFamily: "Roboto_700Bold" },
          ]}
        >
          Add discount bundle
        </Text>
        <View style={tailwind`flex-row`}>
          <View style={tailwind`mr-2`}>
            <StyledInput
              label="Sessions"
              width={"80px"}
              onChangeText={(itemValue) => setSessions(itemValue)}
            />
          </View>
          <View style={tailwind`mr-2`}>
            <Text
              style={[
                tailwind`text-[${theme.textColor}] font-semibold mb-1`,
                { fontFamily: "Roboto_400Regular" },
              ]}
            >
              Type
            </Text>
            <StyledPicker
              width={"40"}
              items={[
                {
                  label: "Percentage",
                  value: 1,
                },
                {
                  label: "Fixed",
                  value: 2,
                },
              ]}
              //   selectedValue={""}
              onValueChange={(itemValue) => setDiscountType(itemValue)}
            />
          </View>
          <View style={tailwind`mr-2`}>
            <StyledInput
              label="Amount"
              width={"80px"}
              onChangeText={(itemValue) => setDiscountAmount(itemValue)}
            />
          </View>
          <View style={tailwind`mt-5`}>
            <Pressable onPress={addSubmit}>
              <Image
                style={tailwind`w-[32px] h-[32px]`}
                source={require("../../assets/add-icon.png")}
                PlaceholderContent={<ActivityIndicator />}
                tintColor={theme.bundleAddIconColor}
              />
            </Pressable>
          </View>
        </View>
      </form>
    </View>
  );
}

PersonalTrainingBundles.propTypes = {
  singleSessionCost: PropTypes.any,
  currency: PropTypes.number.isRequired,
  currencies: PropTypes.any.isRequired,
};

ExistingBundles.propTypes = {
  singleSessionCost: PropTypes.any,
  currency: PropTypes.number.isRequired,
  currencies: PropTypes.any.isRequired,
  queryKey: PropTypes.array.isRequired,
  queryClient: PropTypes.instanceOf(QueryClient).isRequired,
};

export { PersonalTrainingBundles as default };
