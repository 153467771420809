import React, { createContext } from "react";
import PropTypes from "prop-types";

const peersRef = {
  current: [],
};

const PeerConnectionProvider = ({ event, children }) => {
  return (
    <PeerConnectionContext.Provider value={{ peersRef }}>
      {children}
    </PeerConnectionContext.Provider>
  );
};

PeerConnectionProvider.propTypes = {
  event: PropTypes.object,
  children: PropTypes.object,
};

export const PeerConnectionContext = createContext({
  peersRef: peersRef,
});

export default PeerConnectionProvider;
