import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Text, View } from "react-native";
import tailwind from "twrnc";
import Currency from "../form/Currency";
import AddressLine from "../form/AddressLine";
import City from "../form/City";
import Country from "../form/Country";
import State from "../form/State";
import PostalCode from "../form/PostalCode";
import { ThemeContext } from "../base/ApplicationContext";
import { Roboto_700Bold, useFonts } from "@expo-google-fonts/roboto";

const BillingSettings = ({
  currency,
  setCurrency,
  addressLine1,
  setAddressLine1,
  addressLine2,
  setAddressLine2,
  city,
  setCity,
  country,
  setCountry,
  unitedState,
  setUnitedState,
  postalCode,
  setPostalCode,
}) => {
  let fonts = useFonts({ Roboto_700Bold });
  if (!fonts) return;

  return (
    <BillingSettingsPanel
      currency={currency}
      setCurrency={setCurrency}
      addressLine1={addressLine1}
      setAddressLine1={setAddressLine1}
      addressLine2={addressLine2}
      setAddressLine2={setAddressLine2}
      city={city}
      setCity={setCity}
      country={country}
      setCountry={setCountry}
      unitedState={unitedState}
      setUnitedState={setUnitedState}
      postalCode={postalCode}
      setPostalCode={setPostalCode}
    />
  );
};

const BillingSettingsPanel = ({
  currency,
  setCurrency,
  addressLine1,
  setAddressLine1,
  addressLine2,
  setAddressLine2,
  city,
  setCity,
  country,
  setCountry,
  unitedState,
  setUnitedState,
  postalCode,
  setPostalCode,
}) => {
  const COUNTRY_UNITED_STATES = 2;
  const { theme } = useContext(ThemeContext);
  return (
    <View style={tailwind`w-full pb-4 mb-4`}>
      <View>
        <View style={tailwind`py-3`}>
          <Text
            style={[
              tailwind`text-[20px] text-[${theme.textColor}] ml-2 grow`,
              { fontFamily: "Roboto_700Bold" },
            ]}
          >
            Billing Settings
          </Text>
        </View>
        <View style={tailwind`ml-2 mb-4`}>
          <Currency currency={currency} setCurrency={setCurrency} />
        </View>
        <View style={tailwind`ml-2 mb-4`}>
          <AddressLine
            label={"Address"}
            addressLine={addressLine1}
            setAddressLine={setAddressLine1}
          />
        </View>
        <View style={tailwind`ml-2 mb-4`}>
          <AddressLine
            label={""}
            addressLine={addressLine2}
            setAddressLine={setAddressLine2}
          />
        </View>
        <View style={tailwind`ml-2 mb-4`}>
          <City city={city} setCity={setCity} />
        </View>
        <View style={tailwind`ml-2 mb-4`}>
          <Country country={country} setCountry={setCountry} />
        </View>
        {country === COUNTRY_UNITED_STATES ? (
          <View style={tailwind`ml-2 mb-4`}>
            <State state={unitedState} setState={setUnitedState} />
          </View>
        ) : null}
        <View style={tailwind`ml-2 mb-4`}>
          <PostalCode
            country={country}
            postalCode={postalCode}
            setPostalCode={setPostalCode}
          />
        </View>
      </View>
    </View>
  );
};

BillingSettings.propTypes = {
  currency: PropTypes.number.isRequired,
  setCurrency: PropTypes.func.isRequired,
  addressLine1: PropTypes.string,
  setAddressLine1: PropTypes.func,
  addressLine2: PropTypes.string,
  setAddressLine2: PropTypes.func,
  city: PropTypes.string,
  setCity: PropTypes.func,
  country: PropTypes.number,
  setCountry: PropTypes.func,
  unitedState: PropTypes.number,
  setUnitedState: PropTypes.func,
  postalCode: PropTypes.string,
  setPostalCode: PropTypes.func,
};

BillingSettingsPanel.propTypes = {
  currency: PropTypes.number.isRequired,
  setCurrency: PropTypes.func.isRequired,
  addressLine1: PropTypes.string,
  setAddressLine1: PropTypes.func,
  addressLine2: PropTypes.string,
  setAddressLine2: PropTypes.func,
  city: PropTypes.string,
  setCity: PropTypes.func,
  country: PropTypes.number,
  setCountry: PropTypes.func,
  unitedState: PropTypes.number,
  setUnitedState: PropTypes.func,
  postalCode: PropTypes.string,
  setPostalCode: PropTypes.func,
};

export { BillingSettings };
