import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Platform, Text, TextInput, View } from "react-native";
import tailwind from "twrnc";
import { ThemeContext } from "./ApplicationContext";
import { Roboto_400Regular, useFonts } from "@expo-google-fonts/roboto";

const StyledInput = ({
  label,
  onChangeText,
  multiline,
  numberOfLines,
  placeholder,
  secure,
  autoFocus,
  value,
  height,
  width,
  disabled,
  autoComplete,
  keyboardType,
  fieldStatus,
}) => {
  let inputStyle = [tailwind`text-base rounded-md pl-1 w-full bg-[#ffffff]`];
  let containerStyle = [tailwind`rounded-lg flex-row p-[1px]`];
  let errorStyle = [];

  const { theme } = useContext(ThemeContext);

  if (Platform.OS === "web") {
    if (multiline) {
      if (width) {
        containerStyle.push(tailwind`w-[${width}] mx-1`);
        errorStyle.push(tailwind`w-[${width}] flex-wrap`);
      } else {
        containerStyle.push(tailwind`w-[780px] mx-1`);
        errorStyle.push(tailwind`w-[780px] flex-wrap`);
      }
      if (height) inputStyle.push(tailwind`h-[${height}]`);
    } else {
      if (width) {
        containerStyle.push(tailwind`w-[${width}] mx-1`);
        errorStyle.push(tailwind`w-[${width}] flex-wrap`);
      } else {
        containerStyle.push(tailwind`w-[200px] mx-1`);
        errorStyle.push(tailwind`w-[200px] flex-wrap`);
      }
      inputStyle.push(tailwind`h-[${height || "40px"}]`);
    }
  } else {
    if (multiline) {
      if (width) {
        containerStyle.push(tailwind`w-[${width}]`);
        errorStyle.push(tailwind`w-[${width}] flex-wrap`);
      } else {
        containerStyle.push(tailwind`w-[390px]`);
        errorStyle.push(tailwind`w-[390px] flex-wrap`);
      }
      if (height) inputStyle.push(tailwind`h-[${height}]`);
    } else {
      inputStyle.push(tailwind`h-[${height || "40px"}]`);
      if (width) {
        containerStyle.push(tailwind`w-[${width}]`);
        errorStyle.push(tailwind`w-[${width}] flex-wrap`);
      } else {
        containerStyle.push(tailwind`w-[176px]`);
        errorStyle.push(tailwind`w-[176px] flex-wrap`);
      }
    }
  }

  let fonts = useFonts({ Roboto_400Regular });
  if (!fonts) return;

  return (
    <>
      <View>
        <Text
          style={[
            tailwind`text-[${theme.textColor}] font-semibold mb-1`,
            { fontFamily: "Roboto_400Regular" },
          ]}
        >
          {label}
        </Text>
      </View>
      <View style={containerStyle}>
        <TextInput
          multiline={multiline || false}
          inputMode={keyboardType || "text"}
          editable={!disabled || false}
          rows={numberOfLines || 1}
          style={[inputStyle, { fontFamily: "Roboto_400Regular" }]}
          autoComplete={autoComplete}
          placeholder={placeholder || ""}
          defaultValue={value}
          textContentType={"none"}
          placeholderTextColor={"#ccc"}
          secureTextEntry={secure || false}
          autoFocus={autoFocus || false}
          autoCorrect={false}
          onChangeText={onChangeText}
        />
      </View>
      {fieldStatus ? (
        <View style={tailwind`mb-2`}>
          <Text
            style={[
              tailwind`text-xs ml-2 text-[${theme.redColor}]`,
              errorStyle,
              { fontFamily: "Roboto_400Regular" },
            ]}
          >
            {fieldStatus.current}
          </Text>
        </View>
      ) : null}
    </>
  );
};

StyledInput.propTypes = {
  label: PropTypes.string.isRequired,
  onChangeText: PropTypes.func.isRequired,
  multiline: PropTypes.bool,
  numberOfLines: PropTypes.number,
  placeholder: PropTypes.string,
  secure: PropTypes.bool,
  autoFocus: PropTypes.bool,
  value: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  disabled: PropTypes.bool,
  autoComplete: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  keyboardType: PropTypes.string,
  fieldStatus: PropTypes.object,
};

export { StyledInput };
