import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ScrollView, Text, View, Dimensions } from "react-native";
import {
  Roboto_400Regular,
  Roboto_700Bold,
  useFonts,
} from "@expo-google-fonts/roboto";
import { Inter_400Regular } from "@expo-google-fonts/inter";
import tailwind from "twrnc";
import Participants from "../shared/ProviderParticipants";
import PeerVideo from "./PeerVideo";
import SocketChat from "../shared/SocketChat";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import ProviderRoomControls from "../shared/ProviderRoomControls";
import {
  CurrentUserContext,
  ThemeContext,
} from "../../base/ApplicationContext";

const Tab = createMaterialTopTabNavigator();

const ProviderLayout = ({
  remoteStreams,
  participants,
  localStream,
  allMuted,
  setAllMuted,
  event,
  onSend,
  chatMessages,
  totalRemoteStreams,
}) => {
  const { currentUser } = useContext(CurrentUserContext);
  const { theme } = useContext(ThemeContext);
  const scrollViewContainerHeight = Dimensions.get("window").height - 200;
  let fonts = useFonts({ Roboto_700Bold, Inter_400Regular });

  if (!fonts) return;

  return (
    <View style={tailwind`flex-row`}>
      <View style={tailwind`flex-col grow`}>
        <View>
          <ScrollView
            contentContainerStyle={tailwind`h-[${scrollViewContainerHeight}px]`}
          >
            <View
              style={tailwind`flex-row flex-wrap max-w-[${
                Dimensions.get("window").width - 300 - 116
              }px]`}
            >
              {remoteStreams.map((remoteStream) => {
                let thisParticipant = participants.current.filter(
                  (participant) => {
                    return participant.id === remoteStream.userId;
                  }
                )[0];

                if (!remoteStream.deleted && remoteStream.stream.active)
                  return (
                    <PeerVideo
                      key={thisParticipant.id}
                      peerStream={remoteStream}
                      thisParticipant={thisParticipant}
                      providerMuted={allMuted}
                      totalStreams={totalRemoteStreams}
                    />
                  );
              })}
            </View>
          </ScrollView>
          <View style={tailwind`mt-2 ml-2`}>
            {localStream ? (
              <PeerVideo
                peerStream={{ stream: localStream }}
                thisParticipant={{ id: currentUser.id, name: "" }}
              />
            ) : null}
          </View>
        </View>
      </View>
      <View style={tailwind`w-[300px] h-full mr-2`}>
        <View>
          <Text
            style={[
              tailwind`self-center text-[${theme.textColor}] font-semibold text-xl`,
              { fontFamily: "Roboto_700Bold" },
            ]}
          >
            {event.shortDescription}
          </Text>
          <Text
            style={[
              tailwind`self-center mb-2`,
              { fontFamily: "Inter_400Regular" },
            ]}
          >
            {event.longDescription}
          </Text>
        </View>
        <View
          style={tailwind`w-[300px] h-[${
            Dimensions.get("window").height - 170
          }px] self-center p-2 mt-2 mx-2`}
        >
          <ProviderTabs
            participants={participants}
            event={event}
            messages={chatMessages}
            onSend={onSend}
            allMuted={allMuted}
            setAllMuted={setAllMuted}
          />
        </View>
      </View>
    </View>
  );
};

function ProviderTabs({
  participants,
  event,
  messages,
  onSend,
  allMuted,
  setAllMuted,
}) {
  const { theme } = useContext(ThemeContext);

  let fonts = useFonts({ Roboto_400Regular });

  if (!fonts) return;

  return (
    <Tab.Navigator
      initialRouteName="provider.tabs"
      screenOptions={{
        tabBarActiveTintColor: theme.tabBarActiveTintColor,
        tabBarInactiveTintColor: theme.tabBarInactiveTintColor,
        tabBarStyle: { backgroundColor: theme.tabBarBackgroundColor },
        tabBarIndicatorStyle: {
          backgroundColor: theme.tabBarIndicatorColor,
        },
        tabBarLabelStyle: { fontFamily: "Roboto_400Regular" },
      }}
    >
      <Tab.Screen
        name="Chat"
        children={() => (
          <SocketChat event={event} messages={messages} onSend={onSend} />
        )}
      />
      <Tab.Screen
        name="Attendees"
        children={() => <Participants participants={participants} />}
      />
      <Tab.Screen
        name="Settings"
        children={() => (
          <ProviderRoomControls allMuted={allMuted} setAllMuted={setAllMuted} />
        )}
      />
    </Tab.Navigator>
  );
}

ProviderLayout.propTypes = {
  // peersRef: PropTypes.object.isRequired,
  remoteStreams: PropTypes.array.isRequired,
  // eventOwnerId: PropTypes.number.isRequired,
  participants: PropTypes.object.isRequired,
  // connectOnPress: PropTypes.func.isRequired,
  // disconnectOnPress: PropTypes.func.isRequired,
  localStream: PropTypes.object,
  allMuted: PropTypes.bool.isRequired,
  setAllMuted: PropTypes.func.isRequired,
  event: PropTypes.object.isRequired,
  onSend: PropTypes.func.isRequired,
  chatMessages: PropTypes.array.isRequired,
  // inRoom: PropTypes.bool,
  totalRemoteStreams: PropTypes.number.isRequired,
};

ProviderTabs.propTypes = {
  participants: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  messages: PropTypes.array.isRequired,
  onSend: PropTypes.func.isRequired,
  allMuted: PropTypes.bool.isRequired,
  setAllMuted: PropTypes.func.isRequired,
  isAutoScrolling: PropTypes.bool,
  setIsAutoScrolling: PropTypes.func,
};

export default ProviderLayout;
