import React, {
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Text, View, SafeAreaView, ScrollView } from "react-native";
import tailwind from "twrnc";
import Button from "../components/base/Button";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import DatePicker from "react-native-neat-date-picker";
import Attendees from "../components/calendar-screen/Attendees";
import {
  API_URL,
  DIFFICULTY_BEGINNER,
  DIFFICULTY_INTERMEDIATE,
  DIFFICULTY_ADVANCED,
  EVENT_TYPE_BROADCAST,
  EVENT_TYPE_OTHER,
  EVENT_TYPE_PRIVATE,
  EVENT_TYPE_BOOKABLE,
  TREE_TYPE_BODY,
  TREE_TYPE_USER,
  USER_TYPE_AMBASSADOR,
  DIFFICULTY_ALL,
} from "../components/base/Constants";
import { StyledInput } from "../components/base/StyledInput";
import { StyledPicker } from "../components/base/StyledPicker";
import TagSelector from "../components/tags/TagSelector";
import { TagReducer } from "../components/tags/TagReducer";
import {
  analyzeShortDescription,
  analyzeTime,
} from "../components/base/Validation";
import { RecurrencePanel } from "../components/edit-event-screen/RecurrencePanel";
import { QueryClient } from "@tanstack/react-query";
import {
  BottomSheetContext,
  CurrentUserContext,
  ThemeContext,
} from "../components/base/ApplicationContext";
import HorizontalRule from "../components/base/HorizontalRule";

const NewEventScreen = (props) => {
  dayjs.extend(utc);
  const { dateTime, queryClient } = props.route.params;
  const { currentUser } = useContext(CurrentUserContext);
  const { theme } = useContext(ThemeContext);
  const { setBottomSheetVisible } = useContext(BottomSheetContext);
  const userId = currentUser.id;
  const [startDate, setStartDate] = useState(
    dateTime
      ? new Date(dateTime)
      : new Date(dayjs.utc().format("YYYY-MM-DDTHH:mm:[00]Z"))
  );
  const timeStatus = useRef("");

  const [duration, setDuration] = useState(30);
  const [type, setType] = useState(
    currentUser.type === USER_TYPE_AMBASSADOR
      ? EVENT_TYPE_BROADCAST
      : EVENT_TYPE_BOOKABLE
  );

  const [shortDescription, setShortDescription] = useState("");
  const shortDescriptionStatus = useRef("");

  const [longDescription, setLongDescription] = useState("");
  const longDescriptionStatus = useRef("");

  const [repeatType, setRepeatType] = useState(0);
  const [repeatStartDate, setRepeatStartDate] = useState(new Date());
  const [repeatEndDate, setRepeatEndDate] = useState(new Date());
  const [showRepeatStartDatePicker, setShowRepeatStartDatePicker] =
    useState(false);
  const [showRepeatEndDatePicker, setShowRepeatEndDatePicker] = useState(false);

  const [dayOfMonth, setDayOfMonth] = useState(
    (event.rule && event.rule.dayOfMonth) || 1
  );

  const [attendees, setAttendees] = useState([]);

  const [showDatePicker, setShowDatePicker] = useState(false);
  const [mode, setMode] = useState("date");
  const [userTagSelection, processUserTagSelection] = useReducer(
    TagReducer,
    []
  );
  const [bodyTagSelection, processBodyTagSelection] = useReducer(
    TagReducer,
    []
  );

  const [difficulty, setDifficulty] = useState(DIFFICULTY_ALL);
  let mondaySetting = true,
    tuesdaySetting = true,
    wednesdaySetting = true,
    thursdaySetting = true,
    fridaySetting = true,
    saturdaySetting = true,
    sundaySetting = true;
  const [isMonday, setIsMonday] = useState(mondaySetting);
  const [isTuesday, setIsTuesday] = useState(tuesdaySetting);
  const [isWednesday, setIsWednesday] = useState(wednesdaySetting);
  const [isThursday, setIsThursday] = useState(thursdaySetting);
  const [isFriday, setIsFriday] = useState(fridaySetting);
  const [isSaturday, setIsSaturday] = useState(saturdaySetting);
  const [isSunday, setIsSunday] = useState(sundaySetting);

  const setTime = (time) => {
    let newStartDateTime =
      dayjs(startDate).format("YYYY-MM-DD") + "T" + time + ":00Z";
    let newDate = new Date(newStartDateTime);
    if (newDate instanceof Date && !isNaN(newDate)) {
      setStartDate(newDate);
    }
  };

  const openRepeatStartDatePicker = () => {
    setShowRepeatStartDatePicker(true);
  };

  const openRepeatEndDatePicker = () => {
    setShowRepeatEndDatePicker(true);
  };

  const onRepeatStartDateConfirm = (date) => {
    setShowRepeatStartDatePicker(false);

    if (typeof date.dateString !== "undefined") {
      let currentTime = dayjs(startDate).format("THH:mm:ssZ");
      setRepeatStartDate(
        new Date(dayjs(date.dateString).format("YYYY-MM-DD") + currentTime)
      );
    } else {
      let currentTime = dayjs(startDate).format("THH:mm:ssZ");
      setRepeatStartDate(
        new Date(dayjs(date).format("YYYY-MM-DD") + currentTime)
      );
    }
  };

  const onRepeatEndDateConfirm = (date) => {
    setShowRepeatEndDatePicker(false);

    if (typeof date.dateString !== "undefined") {
      let currentTime = dayjs(startDate).format("THH:mm:ssZ");
      setRepeatEndDate(
        new Date(dayjs(date.dateString).format("YYYY-MM-DD") + currentTime)
      );
    } else {
      let currentTime = dayjs(startDate).format("THH:mm:ssZ");
      setRepeatEndDate(
        new Date(dayjs(date).format("YYYY-MM-DD") + currentTime)
      );
    }
  };

  const openDatePicker = () => {
    setMode("date");
    setShowDatePicker(true);
  };

  const onCancel = () => {
    setShowDatePicker(false);
    setShowRepeatStartDatePicker(false);
    setShowRepeatEndDatePicker(false);
  };

  const onConfirm = (date) => {
    setShowDatePicker(false);

    if (typeof date.dateString !== "undefined") {
      if (mode === "date") {
        let currentTime = dayjs(startDate).format("THH:mm:ssZ");
        setStartDate(
          new Date(dayjs(date.dateString).format("YYYY-MM-DD") + currentTime)
        );
      } else {
        //time
        let newTime = dayjs(date.dateString).format("THH:mm:ssZ");
        let newStartDateTime = dayjs(startDate).format("YYYY-MM-DD") + newTime;
        setStartDate(new Date(newStartDateTime));
      }
    } else {
      if (mode === "date") {
        let currentTime = dayjs(startDate).format("THH:mm:ssZ");
        setStartDate(new Date(dayjs(date).format("YYYY-MM-DD") + currentTime));
      } else {
        let newStartDateTime =
          dayjs(startDate).format("YYYY-MM-DD") +
          dayjs(date).format("THH:mm:ssZ");
        setStartDate(new Date(newStartDateTime));
      }
    }
  };

  let currentTime = dayjs(startDate).format("HH:mm");

  let typeId;

  useEffect(() => {
    typeId = type.id;
  }, [type]);

  let headerColour;
  switch (typeId) {
    case EVENT_TYPE_BOOKABLE:
      headerColour = theme.calendarEventBookableColor;
      break;
    case EVENT_TYPE_PRIVATE:
      headerColour = theme.calendarEventPrivateColor;
      break;
    case EVENT_TYPE_BROADCAST:
      headerColour = theme.calendarEventBroadcastColor;
      break;
    default:
      headerColour = theme.calendarEventOtherColor;
      break;
  }

  return (
    <SafeAreaView style={tailwind`flex w-full bg-[${theme.backgroundColor}]`}>
      <View
        style={tailwind`border-t-[${headerColour}] border-t-[4px] p-1 min-h-[15px]`}
      />
      <ScrollView style={tailwind`max-h-[650px]`}>
        <View style={tailwind`p-2`}>
          <View style={tailwind`w-full flex-row`}>
            <View style={tailwind`w-3/4`}>
              <View
                style={tailwind`w-full flex-row mr-2 rounded-lg p-2 mb-2 ml-1`}
              >
                <View>
                  <Text
                    style={tailwind`text-[${theme.textColor}] font-semibold mb-1`}
                  >
                    Type
                  </Text>
                  <StyledPicker
                    width={"200px"}
                    selectedValue={type}
                    onValueChange={(itemValue) => {
                      setType(parseInt(itemValue));
                    }}
                    items={
                      currentUser.type === USER_TYPE_AMBASSADOR
                        ? [
                            {
                              label: "Live Stream",
                              value: EVENT_TYPE_BROADCAST,
                            },
                            {
                              label: "Personal Training",
                              value: EVENT_TYPE_PRIVATE,
                            },
                            {
                              label: "Open Personal Training",
                              value: EVENT_TYPE_BOOKABLE,
                            },
                            {
                              label: "General",
                              value: EVENT_TYPE_OTHER,
                            },
                          ]
                        : [
                            {
                              label: "Personal Training",
                              value: EVENT_TYPE_PRIVATE,
                            },
                            {
                              label: "Open Personal Training",
                              value: EVENT_TYPE_BOOKABLE,
                            },
                            {
                              label: "General",
                              value: EVENT_TYPE_OTHER,
                            },
                          ]
                    }
                  />
                </View>
              </View>
              <View style={tailwind`ml-1 flex-row p-2 rounded-lg mr-2`}>
                <View style={tailwind`w-1/2`}>
                  <Text
                    style={tailwind`text-[${theme.textColor}] font-semibold mb-1`}
                  >
                    Date
                  </Text>
                  <Button
                    title={dayjs(startDate).format("ddd MMM DD")}
                    onPress={openDatePicker}
                  />
                </View>
                <View style={tailwind`w-1/2`}>
                  <StyledInput
                    label={"Time"}
                    placeholder={currentTime}
                    onChangeText={(text) => {
                      setTime(text);
                      analyzeTime(text, timeStatus);
                    }}
                    fieldStatus={timeStatus}
                  />
                </View>
              </View>
              <View style={tailwind`mb-2 mt-2 ml-1 flex-row`}>
                <View style={tailwind`w-1/2`}>
                  <Text
                    style={tailwind`text-[${theme.textColor}] font-semibold mb-1`}
                  >
                    Duration
                  </Text>
                  <StyledPicker
                    selectedValue={duration}
                    onValueChange={(itemValue) => setDuration(itemValue)}
                    items={[
                      {
                        label: "30 Minutes",
                        value: "30",
                      },
                      {
                        label: "45 Minutes",
                        value: "45",
                      },
                      {
                        label: "1 Hour",
                        value: "60",
                      },
                      {
                        label: "1 Hour 30 Minutes",
                        value: "90",
                      },
                      {
                        label: "2 Hours",
                        value: "120",
                      },
                    ]}
                  />
                </View>
                {type === EVENT_TYPE_BROADCAST ? (
                  <View style={tailwind`mb-2`}>
                    <Text
                      style={tailwind`text-[${theme.textColor}] font-semibold mb-1`}
                    >
                      Difficulty
                    </Text>
                    <StyledPicker
                      selectedValue={difficulty}
                      onValueChange={(itemValue) => setDifficulty(itemValue)}
                      items={[
                        {
                          label: "All",
                          value: DIFFICULTY_ALL,
                        },
                        {
                          label: "Beginner",
                          value: DIFFICULTY_BEGINNER,
                        },
                        {
                          label: "Intermediate",
                          value: DIFFICULTY_INTERMEDIATE,
                        },
                        {
                          label: "Advanced",
                          value: DIFFICULTY_ADVANCED,
                        },
                      ]}
                    />
                  </View>
                ) : null}
              </View>
            </View>
            <RecurrencePanel
              startDate={new Date(startDate)}
              repeatType={repeatType}
              repeatStartDate={repeatStartDate}
              openRepeatStartDatePicker={openRepeatStartDatePicker}
              repeatEndDate={repeatEndDate}
              openRepeatEndDatePicker={openRepeatEndDatePicker}
              setRepeatType={setRepeatType}
              isMonday={isMonday}
              setIsMonday={setIsMonday}
              isTuesday={isTuesday}
              setIsTuesday={setIsTuesday}
              isWednesday={isWednesday}
              setIsWednesday={setIsWednesday}
              isThursday={isThursday}
              setIsThursday={setIsThursday}
              isFriday={isFriday}
              setIsFriday={setIsFriday}
              isSaturday={isSaturday}
              setIsSaturday={setIsSaturday}
              isSunday={isSunday}
              setIsSunday={setIsSunday}
              dayOfMonth={dayOfMonth}
              setDayOfMonth={setDayOfMonth}
            />
          </View>
          <HorizontalRule />
          {type !== EVENT_TYPE_BOOKABLE ? (
            <View style={tailwind`self-center`}>
              <View style={tailwind`my-2 ml-1`}>
                <StyledInput
                  width={"555px"}
                  label={"Title"}
                  placeholder={"Title"}
                  autoCorrect={true}
                  onChangeText={(text) => {
                    setShortDescription(text);
                    analyzeShortDescription(text, shortDescriptionStatus);
                  }}
                  fieldStatus={shortDescriptionStatus}
                />
              </View>
              {type !== EVENT_TYPE_OTHER ? (
                <View style={tailwind`self-center my-2`}>
                  <StyledInput
                    label={"Description"}
                    multiline={true}
                    numberOfLines={3}
                    placeholder={"Description"}
                    autoCorrect={true}
                    onChangeText={(text) => {
                      setLongDescription(text);
                    }}
                    width={"555px"}
                    fieldStatus={longDescriptionStatus}
                    height={"100px"}
                  />
                </View>
              ) : null}
            </View>
          ) : null}
        </View>
        {type === EVENT_TYPE_PRIVATE ? (
          <View>
            <Attendees attendees={attendees} setAttendees={setAttendees} />
          </View>
        ) : null}

        {type === EVENT_TYPE_BROADCAST ? (
          <View style={tailwind`mr-2 self-center`}>
            <View style={tailwind`mb-2`}>
              <TagSelector
                tagType={TREE_TYPE_USER}
                tagSelection={userTagSelection}
                processTagSelection={processUserTagSelection}
              />
            </View>
            <View style={tailwind`mb-2`}>
              <TagSelector
                tagType={TREE_TYPE_BODY}
                tagSelection={bodyTagSelection}
                processTagSelection={processBodyTagSelection}
              />
            </View>
          </View>
        ) : null}
        <View style={tailwind`self-center w-full pb-6 mt-2`}>
          <View style={tailwind`self-center flex-row`}>
            <Button
              title="Close"
              onPress={() => {
                setBottomSheetVisible(false);
              }}
            />
            <Button
              disabled={
                !(
                  startDate &&
                  timeStatus.current === "" &&
                  (type !== EVENT_TYPE_BOOKABLE
                    ? shortDescription && shortDescriptionStatus.current === ""
                    : true) &&
                  (type === EVENT_TYPE_PRIVATE ? attendees.length : true)
                )
              }
              title="Save"
              onPress={() => {
                let daysOfWeek = [
                  isMonday,
                  isTuesday,
                  isWednesday,
                  isThursday,
                  isFriday,
                  isSaturday,
                  isSunday,
                ];
                daysOfWeek = daysOfWeek.map((day) => (day ? 1 : 0));
                const daysOfWeekString = daysOfWeek.join("|");
                const ruleSettings = {
                  id: null,
                  daysOfWeek: daysOfWeekString,
                  dayOfMonth: dayOfMonth,
                  startDate: repeatStartDate,
                  endDate: repeatEndDate,
                  repeatType: { id: repeatType },
                };
                axios
                  .post(
                    API_URL + "schedule",
                    {
                      userId: userId,
                      startDate: dayjs(startDate).format(
                        "YYYY-MM-DDTHH:mm:ssZ"
                      ),
                      duration: duration,
                      type: type,
                      shortDescription: shortDescription,
                      longDescription: longDescription,
                      participants: attendees,
                      scheduleTags: [bodyTagSelection, userTagSelection],
                      difficulty: difficulty,
                      rule: ruleSettings,
                    },
                    {
                      headers: {
                        "Content-Type": "application/json",
                        authorization: currentUser.token,
                      },
                    }
                  )
                  .then((res) => {
                    queryClient.invalidateQueries(
                      "schedule" + currentUser.id + dayjs().format("YYYY-MM-DD")
                    );
                    setBottomSheetVisible(false);
                    return res.data;
                  });
              }}
            />
          </View>
        </View>
        {mode === "date" ? (
          <DatePicker
            isVisible={showDatePicker}
            mode={"single"}
            initialDate={new Date(startDate)}
            onCancel={onCancel}
            onConfirm={onConfirm}
            colorOptions={{
              headerColor: theme.backgroundColor,
              selectedDateBackgroundColor: theme.textColor,
              confirmButtonColor: theme.textColor,
              weekDaysColor: theme.backgroundColor,
              changeYearModalColor: theme.textColor,
              dateTextColor: theme.textColor,
              headerTextColor: theme.textColor,
            }}
          />
        ) : null}
        <DatePicker
          isVisible={showRepeatStartDatePicker}
          mode={"single"}
          initialDate={new Date(repeatStartDate)}
          onCancel={onCancel}
          onConfirm={onRepeatStartDateConfirm}
          colorOptions={{
            headerColor: theme.backgroundColor,
            selectedDateBackgroundColor: theme.textColor,
            confirmButtonColor: theme.textColor,
            weekDaysColor: theme.backgroundColor,
            changeYearModalColor: theme.textColor,
            dateTextColor: theme.textColor,
            headerTextColor: theme.textColor,
          }}
        />
        <DatePicker
          isVisible={showRepeatEndDatePicker}
          mode={"single"}
          initialDate={new Date(repeatEndDate)}
          onCancel={onCancel}
          onConfirm={onRepeatEndDateConfirm}
          colorOptions={{
            headerColor: theme.backgroundColor,
            selectedDateBackgroundColor: theme.textColor,
            confirmButtonColor: theme.textColor,
            weekDaysColor: theme.backgroundColor,
            changeYearModalColor: theme.textColor,
            dateTextColor: theme.textColor,
            headerTextColor: theme.textColor,
          }}
        />
      </ScrollView>
    </SafeAreaView>
  );
};

NewEventScreen.propTypes = {
  route: PropTypes.shape({
    params: PropTypes.shape({
      dateTime: PropTypes.string,
      queryClient: PropTypes.instanceOf(QueryClient),
    }).isRequired,
  }).isRequired,
};

export default NewEventScreen;
