import React, { useContext, useReducer, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  Dimensions,
  Platform,
  SafeAreaView,
  ScrollView,
  Text,
  View,
} from "react-native";
import tailwind from "twrnc";
import {
  API_URL,
  DEFAULT_PROFILE_URL,
  TREE_TYPE_USER,
  USER_TYPE_PROVIDER,
  USER_TYPE_USER,
} from "../components/base/Constants";
import Button from "../components/base/Button";
import TagSelector from "../components/tags/TagSelector";
import { useNavigation } from "@react-navigation/native";
import PersonalDetailsForm from "../components/register-screen/PersonalDetailsForm";
import axios from "axios";
import SwitchSelector from "react-native-switch-selector";
import { TagReducer } from "../components/tags/TagReducer";
import {
  CurrentUserContext,
  ThemeContext,
} from "../components/base/ApplicationContext";
import { Roboto_700Bold, useFonts } from "@expo-google-fonts/roboto";

const RegisterUserScreen = () => {
  const navigation = useNavigation();
  const { theme } = useContext(ThemeContext);

  const [emailAddress, setEmailAddress] = useState();
  const emailAddressStatus = useRef();

  const [password, setPassword] = useState("");
  const passwordStatus = useRef();

  const [confirmPassword, setConfirmPassword] = useState("");
  const confirmPasswordStatus = useRef();

  const [firstName, setFirstName] = useState("");
  const firstNameStatus = useRef();

  const [lastName, setLastName] = useState("");
  const lastNameStatus = useRef();

  const [tagSelection, processTagSelection] = useReducer(TagReducer, []);
  const { setCurrentUser } = useContext(CurrentUserContext);
  const [isProvider, setIsProvider] = useState(0);
  const [registerResponse, setRegisterResponse] = useState("");

  const options = [
    {
      label: "Fitness User",
      value: "0",
      accessibilityLabel: "fitness-user",
    },
    {
      label: "Fitness Provider",
      value: "1",
      accessibilityLabel: "fitness-provider",
    },
  ];

  let fonts = useFonts({ Roboto_700Bold });
  if (!fonts) return;

  return (
    <SafeAreaView style={tailwind`flex h-full bg-[${theme.backgroundColor}]`}>
      <ScrollView>
        <View>
          <View style={tailwind`self-center flex-row mt-[40px] mb-[60px]`}>
            <SwitchSelector
              options={options}
              initial={0}
              textColor={theme.registrationUserTypeSliderTextColor}
              selectedColor={theme.registrationUserTypeSliderActiveColor}
              buttonColor={theme.registrationUserTypeButtonColor}
              borderColor={theme.registrationUserTypeBackgroundColor}
              hasPadding
              valuePadding={1}
              fontSize={20}
              onPress={(value) => setIsProvider(value === "1")}
              style={[
                tailwind`w-[350px] h-[50px] font-bold`,
                { FontFace: "Robot_700Bold" },
              ]}
            />
          </View>
          <View style={tailwind`max-w-[800px] self-center`}>
            <PersonalDetailsForm
              setEmailAddress={setEmailAddress}
              emailAddressStatus={emailAddressStatus}
              password={password}
              setPassword={setPassword}
              setConfirmPassword={setConfirmPassword}
              passwordStatus={passwordStatus}
              confirmPasswordStatus={confirmPasswordStatus}
              setFirstName={setFirstName}
              firstNameStatus={firstNameStatus}
              setLastName={setLastName}
              lastNameStatus={lastNameStatus}
            />
          </View>
          <View style={tailwind`mx-2 self-center max-h-[400px]`}>
            <TagSelector
              tagType={TREE_TYPE_USER}
              processTagSelection={processTagSelection}
            />
          </View>
          <View style={tailwind`self-center flex-row my-4`}>
            <Button
              disabled={
                !(
                  emailAddress &&
                  emailAddressStatus.current === "" &&
                  password &&
                  confirmPassword &&
                  password === confirmPassword &&
                  passwordStatus.current === "" &&
                  confirmPasswordStatus.current === "" &&
                  firstName &&
                  firstNameStatus.current === "" &&
                  lastName &&
                  lastNameStatus.current === ""
                )
              }
              title="Submit"
              onPress={() =>
                submitNewUser(
                  emailAddress,
                  password,
                  firstName,
                  lastName,
                  tagSelection,
                  isProvider,
                  setRegisterResponse,
                  () => {
                    handleLogin(
                      emailAddress,
                      password,
                      setCurrentUser,
                      () => {}
                    );
                  }
                )
              }
            />
            <Button
              title="Cancel"
              onPress={() => navigation.navigate("LoginScreen")}
            />
          </View>
          {registerResponse ? (
            <View style={tailwind`w-full`}>
              <Text
                style={tailwind`text-[${theme.textColor}] self-center font-semibold`}
              >
                {registerResponse}
              </Text>
            </View>
          ) : null}
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const handleLogin = async (email, password, setCurrentUser, callback) => {
  try {
    const response = await axios.post(
      API_URL + "auth/",
      { email, password },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const { id, firstName, lastName, emailAddress, type, profileUrl, token } =
      response.data;
    setCurrentUser({
      id: id,
      firstName: firstName,
      lastName: lastName,
      emailAddress: emailAddress,
      type: type.id,
      profileUrl: profileUrl || DEFAULT_PROFILE_URL,
      authenticated: true,
      token: token,
    });
    callback();
  } catch (error) {
    console.log(error);
  }
};

handleLogin.propTypes = {
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  setCurrentUser: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
};

const submitNewUser = async (
  emailAddress,
  password,
  firstName,
  lastName,
  tags,
  isProvider,
  setRegisterResponse,
  callback
) => {
  let deviceInfo = {};

  switch (Platform.OS) {
    case "android":
      deviceInfo = {
        model: Platform.Model,
        release: Platform.Release,
        serial: Platform.Serial,
        fingerprint: Platform.FingerPrint,
        brand: Platform.Brand,
        manufacturer: Platform.Manufacturer,
        uiMode: Platform.uiMode,
        isTv: Platform.isTV,
        isPad: Platform.isPad,
        dimensions: {
          width: Dimensions.get("window").width,
          height: Dimensions.get("window").height,
          scale: Dimensions.get("window").scale,
          fontScale: Dimensions.get("window").fontScale,
        },
        operatingSystem: Platform.OS,
        version: Platform.Version,
      };
      break;
    case "ios":
      deviceInfo = {
        osVersion: Platform.osVersion,
        systemName: Platform.systemName,
        isPad: Platform.isPad,
        dimensions: {
          width: Dimensions.get("window").width,
          height: Dimensions.get("window").height,
          scale: Dimensions.get("window").scale,
          fontScale: Dimensions.get("window").fontScale,
        },
        operatingSystem: Platform.OS,
        version: Platform.Version,
      };
      break;
    default: //web
      deviceInfo = {
        operatingSystem: Platform.OS,
        version: Platform.Version,
        dimensions: {
          width: Dimensions.get("window").width,
          height: Dimensions.get("window").height,
          scale: Dimensions.get("window").scale,
          fontScale: Dimensions.get("window").fontScale,
        },
      };
      break;
  }

  const data = {
    emailAddress: emailAddress,
    password: password,
    type: isProvider ? USER_TYPE_PROVIDER : USER_TYPE_USER,
    firstName: firstName,
    lastName: lastName,
    tags: tags.map((tag) => {
      return {
        id: tag.id,
        title: tag.title,
      };
    }),
    deviceInfo: deviceInfo,
  };
  try {
    const response = await axios.post(API_URL + "register", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    callback(response.data);
  } catch (error) {
    const { response } = error;
    setRegisterResponse(response.data.message);
  }
};

submitNewUser.propTypes = {
  emailAddress: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  tags: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
};

export default RegisterUserScreen;
