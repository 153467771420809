import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Picker } from "@react-native-picker/picker";
import tailwind from "twrnc";
import { Platform } from "react-native";
import { ThemeContext } from "./ApplicationContext";
import { Roboto_400Regular, useFonts } from "@expo-google-fonts/roboto";

const PickerItems = ({ items }) => {
  return items.map((item) => {
    return (
      <Picker.Item key={item.value} label={item.label} value={item.value} />
    );
  });
};

PickerItems.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
};

const StyledPicker = ({ selectedValue, onValueChange, items, width }) => {
  const { theme } = useContext(ThemeContext);
  let fonts = useFonts({ Roboto_400Regular });
  if (!fonts) return;

  return (
    <Picker
      style={[
        Platform.OS === "ios"
          ? tailwind`bg-white w-[240px] ml-2 px-1 self-center border-0 text-[${theme.textColor}]`
          : tailwind`text-base bg-white rounded-lg ml-1 px-1 w-[${
              width ? width : "175px"
            }] h-[40px] border-0 text-[${theme.textColor}]`,
        { fontFamily: "Roboto_400Regular" },
      ]}
      itemStyle={tailwind`text-base h-40`}
      selectedValue={selectedValue}
      onValueChange={onValueChange}
    >
      <PickerItems items={items} />
    </Picker>
  );
};

StyledPicker.propTypes = {
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onValueChange: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.object),
  width: PropTypes.string,
};

export { StyledPicker };
