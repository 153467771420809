import React, { useContext } from "react";
import PropTypes from "prop-types";
import { SectionList, Text, View } from "react-native";
import TagItem from "./TagItem";
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";
import axios from "axios";
import { API_URL } from "../base/Constants";
import { ActivityIndicator } from "react-native";
import tailwind from "twrnc";
import { CurrentUserContext } from "../base/ApplicationContext";

const queryClient = new QueryClient();

const TagSection = ({ item, processTagSelection, tagSelection }) => {
  return (
    <View style={tailwind`pb-4`}>
      <QueryClientProvider client={queryClient}>
        <TagItems
          item={item}
          processTagSelection={processTagSelection}
          tagSelection={tagSelection}
        />
      </QueryClientProvider>
    </View>
  );
};

const TagItems = ({ item, processTagSelection, tagSelection }) => {
  const { currentUser } = useContext(CurrentUserContext);
  const parentTagId = item.id;
  const { isLoading, error, data } = useQuery(["tagitems" + parentTagId], () =>
    axios
      .get(API_URL + "tags/descendants/" + parentTagId, {
        headers: { authorization: currentUser.token },
      })
      .then((res) => res.data)
  );

  if (isLoading) return <ActivityIndicator />;
  if (error) return <Text>{"An error has occurred: " + error.message}</Text>;

  const formattedData = data.map((item) => ({
    data: [item],
  }));

  return (
    <SectionList
      scrollEnabled={true}
      nestedScrollEnabled={true}
      sections={formattedData}
      keyExtractor={(item) => item.id.toString()}
      renderItem={({ item }) => {
        return (
          <TagItem
            item={item}
            processTagSelection={processTagSelection}
            tagSelection={tagSelection}
          />
        );
      }}
      style={tailwind`flex-row flex-wrap`}
    />
  );
};

TagItems.propTypes = {
  item: PropTypes.object.isRequired,
  processTagSelection: PropTypes.func.isRequired,
  tagSelection: PropTypes.array,
};

TagSection.propTypes = {
  item: PropTypes.object.isRequired,
  processTagSelection: PropTypes.func.isRequired,
  tagSelection: PropTypes.array,
};

export default TagSection;
