import React, { useContext } from "react";
import PropTypes from "prop-types";
import { CheckBox } from "@rneui/themed";
import tailwind from "twrnc";
import { ThemeContext } from "../base/ApplicationContext";
import { Roboto_400Regular, useFonts } from "@expo-google-fonts/roboto";

const StyledCheckBox = ({ title, checked, onPress }) => {
  const { theme } = useContext(ThemeContext);
  let fonts = useFonts({ Roboto_400Regular });
  if (!fonts) return;

  return (
    <CheckBox
      center
      title={title}
      checked={checked}
      containerStyle={tailwind`w-[25px] bg-[${theme.backgroundColor}] mx-0`}
      textStyle={[
        tailwind`text-[${theme.tagSelectorTextColor}] text-[10px] ml-1`,
        { fontFamily: "Roboto_400Regular" },
      ]}
      style={tailwind`self-center`}
      wrapperStyle={tailwind`flex-col`}
      checkedColor={theme.tagSelectorCheckedTextColor}
      onPress={onPress}
    />
  );
};

StyledCheckBox.propTypes = {
  title: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onPress: PropTypes.func.isRequired,
  textColour: PropTypes.string,
};

export { StyledCheckBox };
