import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Text, View } from "react-native";
import tailwind from "twrnc";
import Tag from "../base/Tag";
import { ThemeContext } from "../base/ApplicationContext";

const OnDemandDetails = ({ video }) => {
  const { theme } = useContext(ThemeContext);
  const tags = video.video.onDemandVideoTags;
  const description = video.video.description;
  const title = video.video.title;
  return (
    <View style={tailwind`mx-2`}>
      <View>
        <Text style={tailwind`text-[${theme.textColor}] font-bold`}>
          {title}
        </Text>
      </View>
      <View>
        <Text style={tailwind`text-[${theme.textColor}] font-bold`}>
          {description}
        </Text>
      </View>
      <View style={tailwind`px-4 my-2`}>
        <View style={tailwind`flex flex-row mt-2 flex-wrap`}>
          {tags.map((userTag) => (
            <Tag
              key={userTag.id}
              label={userTag.tagId.tagName}
              colorKey={userTag.tagId.tagColorKey}
            />
          ))}
        </View>
      </View>
    </View>
  );
};

OnDemandDetails.propTypes = {
  video: PropTypes.object.isRequired,
  event: PropTypes.object,
};

export default OnDemandDetails;
