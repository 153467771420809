import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useFonts, Roboto_700Bold } from "@expo-google-fonts/roboto";

import { API_URL } from "../base/Constants";
import YoutubeThumbnail from "../home-screen/YoutubeThumbnail";
import VideoThumbnail from "../home-screen/OnDemandThumbnail";
import tailwind from "twrnc";
import { ActivityIndicator, Text, View } from "react-native";
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";
import axios from "axios";
import { CurrentUserContext, ThemeContext } from "../base/ApplicationContext";

const queryClient = new QueryClient();

const TrainerVideos = ({ id, firstName }) => {
  let [fontsLoaded] = useFonts({ Roboto_700Bold });

  if (!fontsLoaded) {
    return null;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Panel id={id} firstName={firstName} />
    </QueryClientProvider>
  );
};

TrainerVideos.propTypes = {
  id: PropTypes.number.isRequired,
  firstName: PropTypes.string.isRequired,
};

const Panel = ({ id, firstName }) => {
  const { currentUser } = useContext(CurrentUserContext);
  const { theme } = useContext(ThemeContext);

  const { isLoading, error, data } = useQuery(["trainer" + id], () =>
    axios
      .get(API_URL + "videos/" + id, {
        staleTime: 0,
        headers: { authorization: currentUser.token },
      })
      .then((res) => {
        return res.data;
      })
  );

  if (isLoading) return <ActivityIndicator />;
  if (error) return <Text>An error has occurred: ${error.message}</Text>;

  const userVideos = data;

  return userVideos && userVideos.length ? (
    <View style={tailwind`ml-2 my-2`}>
      <Text
        style={[
          tailwind`text-[${theme.textColor}] font-semibold mt-1 text-[16px]`,
          { fontFamily: "Roboto_700Bold" },
        ]}
      >
        Featuring {firstName}
      </Text>
      <View style={tailwind`mt-2 flex-row flex-wrap`}>
        {userVideos.map((userVideo) => {
          return userVideo.youtubeId ? (
            <YoutubeThumbnail key={userVideo.id} video={{ video: userVideo }} />
          ) : (
            <VideoThumbnail key={userVideo.id} video={{ video: userVideo }} />
          );
        })}
      </View>
    </View>
  ) : null;
};

Panel.propTypes = {
  id: PropTypes.number.isRequired,
  firstName: PropTypes.string.isRequired,
};

export { TrainerVideos };
