import React, { useContext } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { ActivityIndicator, Platform, Text, View } from "react-native";
import { Image } from "@rneui/themed";
import Modal from "react-native-modal";
import tailwind from "twrnc";
import Button from "../base/Button";
import { API_URL, FILL_COLOUR_3 } from "../base/Constants";
import { CurrentUserContext, ThemeContext } from "../base/ApplicationContext";

const ConnectModal = ({
  modalVisible,
  setModalVisible,
  id,
  firstName,
  profileUrl,
}) => {
  const { currentUser } = useContext(CurrentUserContext);
  const { theme } = useContext(ThemeContext);
  const userId = currentUser.id;

  const modalWidth = Platform.OS === "web" ? "50%" : "100%";

  return (
    <View style={tailwind`justify-center mt-[22px]`}>
      <Modal
        animationIn="slideInUp"
        animationOut="slideOutDown"
        isVisible={modalVisible}
        hasBackdrop={true}
        backdropOpacity={0.6}
        backdropColor="#000000"
        hideModalContentWhileAnimating={true}
        useNativeDriverForBackdrop={true}
        useNativeDriver={true}
        animationInTiming={400}
        animationOutTiming={400}
        backdropTransitionInTiming={300}
        backdropTransitionOutTiming={300}
        onBackdropPress={() => {
          setModalVisible(!modalVisible);
        }}
        onRequestClose={() => {
          setModalVisible(!modalVisible);
        }}
      >
        <View
          style={tailwind`rounded-lg h-auto p-6 self-center bg-[${FILL_COLOUR_3}] border-[0.5px] border-[${theme.textColor}] flex-col w-[${modalWidth}]`}
        >
          <View style={tailwind`flex-row self-center`}>
            <View style={tailwind`px-2`}>
              <Image
                style={tailwind`w-[30px] h-[41px] rounded-lg`}
                source={{ uri: profileUrl }}
                PlaceholderContent={<ActivityIndicator />}
              />
            </View>
            <View>
              <Text
                style={tailwind`text-[${theme.textColor}] font-bold text-sm mt-3`}
              >
                Request a connection with {firstName} ?
              </Text>
            </View>
          </View>
          <View style={tailwind`w-full self-center mt-4`}>
            <View style={tailwind`flex-row self-center`}>
              <View>
                <Button
                  title="Request"
                  onPress={() => {
                    axios
                      .post(
                        API_URL + "userconnection/request",
                        {
                          sourceUserId: userId,
                          destinationUserId: id,
                        },
                        {
                          headers: {
                            "Content-Type": "application/json",
                            authorization: currentUser.token,
                          },
                        }
                      )
                      .then((res) => {
                        setModalVisible(!modalVisible);
                        return res.data;
                      });
                  }}
                />
              </View>
              <View>
                <Button
                  title="Cancel"
                  onPress={() => {
                    setModalVisible(!modalVisible);
                  }}
                />
              </View>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
};

ConnectModal.propTypes = {
  modalVisible: PropTypes.bool.isRequired,
  setModalVisible: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  firstName: PropTypes.string.isRequired,
  profileUrl: PropTypes.string.isRequired,
};

export default ConnectModal;
